import React from 'react';
import PropType from 'prop-types';

export default class HandleCustomRouteEvent extends React.Component {
  static propTypes = {
    history: PropType.shape({
      push: PropType.func.isRequired,
    }).isRequired,
    children: PropType.node.isRequired,
  };

  constructor() {
    super();
    this.handleRouteEvent = this.handleRouteEvent.bind(this);
    window.addEventListener('route', this.handleRouteEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('route', this.handleRouteEvent);
  }

  handleRouteEvent(ev) {
    if (ev && ev.detail && ev.detail.url) {
      this.props.history.push(ev.detail.url);
    }
  }

  render() {
    return this.props.children;
  }
}
