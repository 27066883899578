import { createNewState } from '../ravenReduxHelpers';

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
const SET_PRODUCT_IMAGE_GROUP = 'product/SET_PRODUCT_IMAGE_GROUP';
const SET_PDP_LAZY_LOADING = 'product/SET_PDP_LAZY_LOADING';

// Initial State
// -------------
const initialState = {
  productImageGroup: 'All',
  overwritePdpLazyLoading: true,
};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  const { type, ...filteredAction } = action;

  switch (action.type) {
    case SET_PRODUCT_IMAGE_GROUP:
      return createNewState(state, { ...filteredAction });
    case SET_PDP_LAZY_LOADING:
      return createNewState(state, { ...filteredAction });
    default:
      return state;
  }
}

// Action Creators
// ---------------
export const setProductImageGroup = (groupName) => ({
  type: SET_PRODUCT_IMAGE_GROUP,
  productImageGroup: groupName,
});

export const setPdpLazyLoading = (overwritePdpLazyLoading) => ({
  type: SET_PDP_LAZY_LOADING,
  overwritePdpLazyLoading,
});
