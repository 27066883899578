/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import sagasManager from './ravenSagaManager';

// import rootSaga from './sagas';

export default (rootReducer, preloadedState = {}) => {
  let store;
  const sagaMiddleware = createSagaMiddleware();

  // PRODUCTION is a dynamically set environment variable set in the webpack config in the file
  // webpack.config.js/tasks/common.js.  It can be either Truthy or Falsey.  The default is to
  // assume local dev.
  if (PRODUCTION) {
    store = createStore(
      rootReducer,
      preloadedState,
      applyMiddleware(sagaMiddleware)
    );
  } else {
    store = createStore(
      rootReducer,
      preloadedState,
      composeWithDevTools(applyMiddleware(sagaMiddleware))
    );
  }

  // const sagas = sagasManager.getRootSaga();
  // console.log('sagas', sagas);
  sagaMiddleware.run(sagasManager.getRootSaga());

  // https://github.com/rt2zz/redux-persist
  // persistStore() has lots of available options like whitelist, blacklist, transforms, callbacks
  //
  // persistStore() is not autoRehydrate(). That's another method of redux-persist. autoRehydrate()
  // is not intended for large apps, like ours, or apps with complicated state trees.
  //
  // persistStore() will save ALL white-listed reducers to local storage.  However, persistStore()
  // will not rehydrate your persisted reducer unless you add the name of the reducer to the
  // whitelist config option, AND the reducer ALSO includes a switch case for the REHYDRATE constant
  // located in redux-persist/constants. It is a 2 step process to opt in.
  //
  // Please check the product reducer to see how to opt-in a reducer via the REHYDRATE switch case.
  // It is commented out in product, but you can see how it works.
  persistStore(store, {
    debounce: 900,
    // IMPORTANT: set the whitelist option otherwise persistStore() will save all stores to
    // localStorage by default. This potentially could cause issues with using too much local
    // storage, or even pose a threat to the personal information of our shoppers if we store PII
    // information in a reducer.
    whitelist: [
      // 'productReducer', // Using productReducer here as an example only.
      'checkoutReducer',
    ],
  });

  return store;
};
