import gql from 'graphql-tag';

export const schema = `
paymentResponseTransactionDetail {
    requestID
    transactionDTTM
    externalResponseCode
    externalResponseMessage
    transactionType
    transactionExpDate
    transactionDecision
    recordStatus
    requestToken
    processedAmount
  }
`;

export default gql`
query getToken($tokenRequest: TokenRequest!){
  getToken(tokenRequest: $tokenRequest) {
      ${schema}
    }
  }
`;
