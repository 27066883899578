function scrubPromoMessage(message) {
  const scrub = 'discount taken in cart';

  if (message && message.toLowerCase().includes(scrub)) {
    return message.substr(0, message.toLowerCase().indexOf(scrub)).trim();
  }

  return message;
}

export default scrubPromoMessage;
