import gql from 'graphql-tag';

export default gql`
  query BopisHowItWorksMessagingOperation {
    getBopisHowItWorksMessaging {
      aboveTitle
      title
      bulletOneTitle
      bulletOneBodyA
      bulletOneBodyB
      bulletTwoTitle
      bulletTwoBody
      bulletThreeTitle
      bulletThreeBody
      pickupInstructions
      pickupLinkTitle
      faqInstructions
      faqLinkTitle
    }
  }
`;
