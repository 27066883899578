import { REHYDRATE } from 'redux-persist/constants';
import { createNewState, rehydrate } from '../ravenReduxHelpers';

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.
const SET_IS_RETURNING_FROM_PAYPAL = 'checkout/SET_IS_RETURNING_FROM_PAYPAL';
const SET_ORDER_ID = 'checkout/SET_ORDER_ID';
const SET_RESET_CHECKOUT_REDUCER = 'checkout/SET_RESET_CHECKOUT_REDUCER';
const SET_RESET_CHECKOUT_REDUCER_FORM_STEPS =
  'checkout/SET_RESET_CHECKOUT_REDUCER_FORM_STEPS';
const SET_STEP_SKIPPABILITY = 'checkout/SET_STEP_SKIPPABILITY';
const SET_CHARITY_DONATION = 'checkout/SET_CHARITY_DONATION';
const SET_CREDIT_CARD_CONSENT = 'checkout/SET_CREDIT_CARD_CONSENT';

// Initial State
// -------------
const initialState = {
  isStepSkippable: {
    'contact-information': false,
    'delivery-and-pickup': false,
    'pick-up-person': false,
    'shipping-and-billing-address': false,
    payment: false,
  },
  isCharityChecked: false,
  isReturningFromPaypal: false,
  // default value should be true
  isSaveCardChecked: true,
  orderId: null,
};

// Reducer
// NOTE: This reducer is for remembering the progress of a user through the chekout steps.  It is
// only meant to remember information on the steps, NOT PERSONAL INFORMATION.  Do not store PII
// in this reducer as it is stored in localStorage to remember accross page reloads.
// ------------------------------------------------------------------------------------------------
export default function reducer(state = initialState, action = {}) {
  const { type, ...filteredAction } = action;

  switch (action.type) {
    case REHYDRATE:
      return rehydrate(action, state, 'checkoutReducer');

    case SET_CHARITY_DONATION: {
      const { bool } = filteredAction;
      if (bool === true) {
        return createNewState(state, { isCharityChecked: true });
      }
      return createNewState(state, { isCharityChecked: false });
    }

    case SET_CREDIT_CARD_CONSENT: {
      // default value should be true. important!!
      const { bool } = filteredAction;
      if (bool === false) {
        return createNewState(state, { isSaveCardChecked: false });
      }
      return createNewState(state, { isSaveCardChecked: true });
    }

    case SET_STEP_SKIPPABILITY: {
      const { slug, bool } = filteredAction;
      const isStepSkippable = { ...state.isStepSkippable };
      isStepSkippable[slug] = bool;
      return createNewState(state, { isStepSkippable });
    }

    case SET_IS_RETURNING_FROM_PAYPAL: {
      const { bool } = filteredAction;
      const isStepSkippable = { ...state.isStepSkippable };
      if (bool === true) {
        isStepSkippable['contact-information'] = true;
        isStepSkippable['shipping-and-billing-address'] = true;
        return createNewState(state, {
          isStepSkippable,
          isReturningFromPaypal: true,
        });
      }
      return createNewState(state, { isReturningFromPaypal: false });
    }

    case SET_RESET_CHECKOUT_REDUCER:
      // resets EVERYTHING, including the orderId.  Consider the other reset option first.
      return createNewState(state, initialState);

    case SET_RESET_CHECKOUT_REDUCER_FORM_STEPS:
      // resets only keys related to progress through the checkout steps.
      return createNewState(state, {
        isStepSkippable: {
          'contact-information': false,
          'delivery-and-pickup': false,
          'pick-up-person': false,
          'shipping-and-billing-address': false,
        },
        isReturningFromPaypal: false,
      });

    case SET_ORDER_ID: {
      const { orderId } = filteredAction;

      // if the orderId changed, reset the state and set the new orderId.
      if (orderId !== state.orderId) {
        const newState = { ...initialState, orderId };
        return createNewState(state, newState);
      }
      return state;
    }

    default:
      return state;
  }
}

// Action Creators
// ---------------
export const setIsStepSkippable = (slug, bool) => ({
  type: SET_STEP_SKIPPABILITY,
  slug,
  bool: !!bool,
});
export const setResetCheckoutReducer = () => ({
  type: SET_RESET_CHECKOUT_REDUCER,
});
export const setResetCheckoutReducerFormSteps = () => ({
  type: SET_RESET_CHECKOUT_REDUCER_FORM_STEPS,
});
export const setIsReturningFromPaypal = (bool) => ({
  type: SET_IS_RETURNING_FROM_PAYPAL,
  bool: !!bool,
});
export const setCreditCardConsent = (bool) => ({
  type: SET_CREDIT_CARD_CONSENT,
  bool,
});
export const setOrderId = (orderId) => ({
  type: SET_ORDER_ID,
  orderId,
});
