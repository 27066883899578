import React from 'react';
import PropTypes from 'prop-types';
import { doEncryptLocal, loog, getCookie } from '@express-labs/raven-tools';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withApollo } from '@apollo/react-hoc';
import { getCustomer } from '../../queries';
import { setSessionData } from '../../appState/ducks/customer';
import { LOCAL_STORAGE_SECRET } from '../../constants';
import { getPointsUntilNextReward } from '../../pages/MyAccount/myAccountHelpers';

let instanceCounter = 0;

export class User extends React.Component {
  static readCookies() {
    window.Eva.signal('readCookies', document);
  }

  static catchError(error) {
    const message = error?.message || 'getCustomerInfo promise was rejected';
    loog(message);
    return message;
  }

  static testShouldRefreshOnHeartbeat(props) {
    return Boolean(props.shouldRefreshOnHeartbeat && window?.Heartbeat?.on);
  }

  constructor(props) {
    super(props);
    this.state = { isLoading: !props.sessionData };

    this.getCustomerInfo = this.getCustomerInfo.bind(this);
    this.getCustomerInfoSuccess = this.getCustomerInfoSuccess.bind(this);
    this.handleOnHeartbeat = this.handleOnHeartbeat.bind(this);
    this.updateCustomerData = this.updateCustomerData.bind(this);

    this.isComponentMounted = false;
    instanceCounter += 1;
    this.instanceNumber = instanceCounter;
  }

  componentDidMount() {
    const { sessionData, shouldUpdateOnMount } = this.props;

    this.isComponentMounted = true;

    if (!sessionData || shouldUpdateOnMount) {
      const isCacheFirst = !shouldUpdateOnMount;
      this.getCustomerInfo(isCacheFirst)
        .then(this.getCustomerInfoSuccess)
        .catch(User.catchError);
    }

    if (User.testShouldRefreshOnHeartbeat(this.props))
      this.subscribeToHeartbeat();

    // send cookies to script used by analytics team
    if (window.HEARTBEAT_ALWAYS_BEAT) User.readCookies();
  }

  componentDidUpdate(oldProps) {
    if (this.props.isAnonymous !== oldProps.isAnonymous) {
      this.getCustomerInfo()
        .then(this.getCustomerInfoSuccess)
        .catch(User.catchError);
    }

    if (
      !User.testShouldRefreshOnHeartbeat(oldProps) &&
      User.testShouldRefreshOnHeartbeat(this.props)
    ) {
      this.subscribeToHeartbeat();
    }

    if (
      User.testShouldRefreshOnHeartbeat(oldProps) &&
      !User.testShouldRefreshOnHeartbeat(this.props)
    ) {
      this.unsubscribeToHeartbeat();
    }

    // send cookies to script used by analytics team
    if (window.HEARTBEAT_ALWAYS_BEAT) User.readCookies();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    this.unsubscribeToHeartbeat();
  }

  getCustomerInfoSuccess(response) {
    User.setFirstNameToLocalStorage(response);
    User.setLoyaltyInfoToLocalStorage(response);
    this.props.setSessionData(response);
  }

  static setFirstNameToLocalStorage(response) {
    const firstName = response?.customer?.customerInfo?.firstName || false;
    if (firstName) {
      doEncryptLocal.doEncryptLocalStorageSetItem(
        'firstName',
        firstName,
        LOCAL_STORAGE_SECRET
      );
    }
  }

  static setLoyaltyInfoToLocalStorage(response) {
    const customerInfo = response?.customer?.customerInfo || {};
    const {
      aList,
      lastName,
      loginStatus,
      loyaltyNumber,
      nextCreditCardHolder,
      pointsBalance,
      pointsToNextTier,
      rewardsTotal,
      tierName,
      tierExpirationDate,
      tierExpirationDays,
      pointsToRetainCurrentTier,
      memberRewardChoice,
    } = customerInfo;

    const flagNewTiers = window?.FLAGS?.NewTiers;
    const currencyToEarn = memberRewardChoice?.currencyToEarn || false;
    const { pointsUntilNextReward } = getPointsUntilNextReward(
      flagNewTiers,
      pointsBalance,
      currencyToEarn
    );

    if (lastName && loginStatus) {
      const lastNameInitial = lastName.charAt(0);
      const loyaltyWarmStateData = {
        aList,
        lastName: lastNameInitial,
        loyaltyNumber,
        nextCreditCardHolder,
        pointsBalance,
        pointsToNextTier,
        rewardsTotal,
        tierName,
        totalPointsForNextReward: pointsUntilNextReward,
        tierExpirationDate,
        tierExpirationDays,
        pointsToRetainCurrentTier,
      };
      localStorage.setItem(
        'loyaltyWarmStateData',
        JSON.stringify(loyaltyWarmStateData)
      );
    }
  }

  getCustomerInfo(isCacheFirst) {
    const { client } = this.props;
    if (this.isComponentMounted) {
      this.setState({ isLoading: true });
    }
    return new Promise((resolve, reject) => {
      client
        .query({
          query: getCustomer,
          fetchPolicy: isCacheFirst ? 'cache-first' : 'network-only',
        })
        .then((response) => {
          if (this.isComponentMounted) {
            resolve(response.data.customer);
            this.setState({ isLoading: false });
          }
        })
        .catch((error) => {
          if (this.isComponentMounted) {
            reject(error);
            this.setState({ isLoading: false });
          }
        });
    });
  }

  handleOnHeartbeat() {
    /** Read the cookie updateStoreSkipOnce which set from raven-server after
     * set the store successfully in header
     * when the value is 1 the get customer graphql will be called without cachefirst
     * when the value is 0 the get customer graphql will be called with cachefirst
     * Remove the cookie immedidately
     */
    const updateStoreInHeaderCookie = getCookie('updateStoreSkipOnce');
    document.cookie =
      'updateStoreSkipOnce=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    const isCacheFirst = updateStoreInHeaderCookie !== '1';
    return this.updateCustomerData(isCacheFirst);
  }

  subscribeToHeartbeat() {
    if (!window?.Heartbeat?.on || this.isSubscribedToHeartbeat === true) {
      return false;
    }
    window.Heartbeat.on(
      'beat',
      `withUserInstance${this.instanceNumber}`,
      this.handleOnHeartbeat
    );
    this.isSubscribedToHeartbeat = true;
    return true;
  }

  unsubscribeToHeartbeat() {
    if (!window?.Heartbeat?.off || this.isSubscribedToHeartbeat !== true) {
      return false;
    }
    window.Heartbeat.off('beat', `withUserInstance${this.instanceNumber}`);
    this.isSubscribedToHeartbeat = false;
    return true;
  }

  updateCustomerData(isCacheFirst = false) {
    return this.getCustomerInfo(isCacheFirst)
      .then(this.getCustomerInfoSuccess)
      .catch(User.catchError);
  }

  render() {
    const {
      component: Component,
      sessionData,
      shouldRefreshOnHeartbeat,
      shouldUpdateOnMount,
      ...filteredProps
    } = this.props;

    const { isLoading } = this.state;

    const customer = sessionData?.customer || null;
    const customerStore = sessionData?.customerStore || null;
    return (
      <Component
        {...filteredProps}
        user={{
          isLoading,
          customer,
          customerStore,
          updateCustomerData: this.updateCustomerData,
        }}
      />
    );
  }
}

User.propTypes = {
  client: PropTypes.shape({ query: PropTypes.func.isRequired }).isRequired,
  component: PropTypes.elementType,
  isAnonymous: PropTypes.bool.isRequired,
  sessionData: PropTypes.shape({
    customer: PropTypes.shape({}),
    customerStore: PropTypes.shape({}),
  }),
  setSessionData: PropTypes.func.isRequired,
  shouldRefreshOnHeartbeat: PropTypes.bool,
  shouldUpdateOnMount: PropTypes.bool,
};

User.defaultProps = {
  sessionData: null, // session data is async.  will be null until heartbeat fires.
  shouldRefreshOnHeartbeat: false,
  shouldUpdateOnMount: false,
  component: null,
};

export const mapStateToProps = (state) => ({
  isAnonymous: state.customerReducer.anon === 'true', // yes, it's a string.
  sessionData: state.customerReducer.data,
});

/* istanbul ignore next */
const ConnectedUser = compose(
  connect(mapStateToProps, { setSessionData }),
  withApollo // withApollo() HOC adds "client" property to User component.
)(User);

ConnectedUser.displayName = 'ConnectedUser';

export function withUserHoc(WrappedComponent, options = {}) {
  const { shouldUpdateOnMount = false, shouldRefreshOnHeartbeat = false } =
    options;

  const hoc = (props) => (
    <ConnectedUser
      {...props}
      component={WrappedComponent}
      shouldUpdateOnMount={shouldUpdateOnMount}
      shouldRefreshOnHeartbeat={shouldRefreshOnHeartbeat}
    />
  );
  hoc.displayName = 'withUser';
  return hoc;
}

export function withUserFactory() {
  return (options) => (WrappedComponent) =>
    withUserHoc(WrappedComponent, options);
}

export const { setFirstNameToLocalStorage } = User;

export default withUserFactory();
