/**
 * Take the list of all available facets returned by UNBXD and convert it into an object map.
 * @param {Object} facets
 * @param {String} facets.facetId A unique ID for the facet.  Example "color_uFilter" "fit_uFilter"
 * @param {Array}  facets.values A list of strings that are options for each filter facet.
 */
export function getAnalyticsBreakpointName(breakPointValue) {
  let breakpointName = 'Desktop (Expanded)';

  if (breakPointValue <= 767) {
    breakpointName = 'Mobile';
  } else if (breakPointValue >= 768 && breakPointValue <= 991) {
    breakpointName = 'Tablet (Portrait)';
  } else if (breakPointValue >= 992 && breakPointValue <= 1119) {
    breakpointName = 'Desktop (Standard) and Tablet (Landscape)';
  }
  return breakpointName;
}

export function getPointsUntilNextReward(
  flagNewTiers,
  pointsBalance,
  newTierChoice = false
) {
  const newTiersBaseTiersPoints = 1250;
  const oldTiersPoints = 2500;
  const newTiersPoints = newTierChoice || newTiersBaseTiersPoints;
  const tiersPoints = flagNewTiers ? newTiersPoints : oldTiersPoints;
  const pointsUntilNextReward =
    tiersPoints - pointsBalance < 0 ? 0 : tiersPoints - pointsBalance;
  return {
    tiersPoints,
    pointsUntilNextReward,
  };
}

export default {
  getAnalyticsBreakpointName,
  getPointsUntilNextReward,
};
