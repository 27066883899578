import gql from 'graphql-tag';

export const availabilitySchema = `
  stores {
    store {
      addressLine1
      addressLine2
      bopisEligible
      bopisMessage
      city
      country
      name
      phoneNumber
      postalCode
      state
      storeId
      storeNumber
      hoursOfOperation
      weeklyHoursOfOperation {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
    skus {
      sku
      quantity
      bopisMessage
      availabilityMessage
    }
    distance
  }
`;

const query = gql`
  query getAvailabilities($queryString: String!, $sku: String){
    availabilities(queryString: $queryString, sku: $sku) {
      ${availabilitySchema}
    }
  }
`;

export default query;
