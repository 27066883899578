// Express bazaarvoice account ID.
import { getPath } from '@express-labs/raven-tools';
import insiderSignUpCartImage from '../images/Express-Insider-Sign-Up-Background-Image.jpg';
import insiderLoginCartImage from '../images/Express-Insider-Login-Background-Image.jpg';

export const isProductionBuild = () => {
  /* this is set in raven nest, and is undefined locally */
  const env = getPath(window, 'APP_RAVEN.ENV', '');
  return env === 'production';
};

const insiderImageURL =
  'https://www.express.com/content/dam/logos-fonts/express/express-insider-sign-in.jpg';

export const newTiersSignUpImage = insiderImageURL;

export const headerEnv = () => window?.headerLoaderEnv ?? '';

export const isHeaderEnvProduction = () => headerEnv() === 'prod';

export const MODE_404_SEO = 'MODE_SEO';
export const MODE_404_REACT_ROUTER = 'MODE_REACT_ROUTER';
export const get404Mode = () =>
  isProductionBuild() ? MODE_404_SEO : MODE_404_REACT_ROUTER;

// TODO: remove this and all references to BV_URL, BV_ENV, and BVUID.
// BazaarVoice
export const BVUID = '6549';
export const BV_URL = isProductionBuild()
  ? `https://express.ugc.bazaarvoice.com/static/${BVUID}/bvapi.js`
  : `https://express.ugc.bazaarvoice.com/bvstaging/static/${BVUID}/bvapi.js`;
export const BV_ENV = isProductionBuild()
  ? '//express.ugc.bazaarvoice.com'
  : '//express.ugc.bazaarvoice.com/bvstaging';

// BazaarVoiceV2
export const bazaarvoiceScript = isHeaderEnvProduction()
  ? 'https://apps.bazaarvoice.com/deployments/express/main_site/production/en_US/bv.js'
  : 'https://apps.bazaarvoice.com/deployments/express/main_site/staging/en_US/bv.js';

// Aurus Payment Gateway
export const aurusMonitorImageURL = isHeaderEnvProduction()
  ? 'https://ecst1.auruspay.com/storeservices/jsplugins/aurusonline.js'
  : 'https://UAT48.auruspay.com/storeservices/jsplugins/aurusonline.js';

export const bazaarvoiceScriptId = 'bazaarvoice-script';

// Google
export const GOOGLE_MAPS_API_KEY = 'AIzaSyCLLQGqPJtJYWsvDyHjYprs98e7LKFKyfA';
export const GOOGLE_RECAPTCHA_ENTERPRISE_KEY = isHeaderEnvProduction()
  ? '6LeQ9cMcAAAAAFZHO6v3Hmi-3ljppHJA6RqkC97Q'
  : '6LcN2JMcAAAAAGbWVktzqeWfWLNXFOXCuvQ3e6r9';
// Local Storage Secret for First Name Storage
export const LOCAL_STORAGE_SECRET = 'BletchleyPark';

// Acquisition offer discount. The discount should be in this format: 'xx%' (ex: '20%').
export const creditCardDiscount = '20%';

// Captcha Site Key
export const CAPTCHA_KEY = '6LdM9yIUAAAAAO1HFvK9xABFs4miCEvQRi-oklfx';

// LiveChat 💬 Configs
export const LIVE_CHAT = {
  ID1: '572j00000004CHN',
  ID2: '00Dj0000001oVT5',
  URL: 'https://d.la3-c1-phx.salesforceliveagent.com/chat',
};

// loyalty config
export const MAX_MOBILE_WIDTH = 991;
export const SOURCE_AND_DEVICE_TYPE = {
  MOBILE_CHECKOUT: 'MOBILE_CHECKOUT',
  MOBILE_ACCOUNT: 'MOBILE_ACCOUNT',
  MOBILE_PRODUCTPAGE: 'MOBILE_PRODUCTPAGE',
  MOBILE_HOME: 'MOBILE_HOME',
  MOBILE_REWARDS: 'MOBILE_REWARDS',
  MOBILE_BAG: 'MOBILE_BAG',
  WEB_CHECKOUT: 'WEB_CHECKOUT',
  WEB_ACCOUNT: 'WEB_ACCOUNT',
  WEB_PRODUCTPAGE: 'WEB_PRODUCTPAGE',
  WEB_HOME: 'WEB_HOME',
  WEB_REWARDS: 'WEB_REWARDS',
  WEB_BAG: 'WEB_BAG',
};

const insiderCreditCardHolderText = '';

/* TEMPORARY: Restore this back to insiderCreditCardHolderText when we enable credit
card application
`<p>${creditCardDiscount} Off</p>
  <p>
    *Application and approval must occur same day to receive the offer.
    Cannot be combined with other credit offers. Valid one time only.
    The entire transaction amount after discount must be placed on the
    Express Credit Card.
  </p>`;
  */

const insiderMainParagraph = `<p>Points</p>
  <p>
    <sup>1</sup> 2X points equals 20 points per $1 spent.
    Offer is available to all Express Insider members.
    Excludes purchases covered by the redemption of a Reward or program coupon,
    EXPRESS Style Trial transactions, gift boxes and gift wrapping services online,
    redemption of gift cards, sales tax,
    state fees and shipping charges or delivery. This rewards program is provided by
    Express and its terms may change at any time.
    For full Rewards Terms and Conditions, please see
    <a
      href="https://www.express.com/g/terms-conditions"
      target="_blank"
      rel="noreferrer noopener"
      className="linkDark"
    >https://www.express.com/g/terms-conditions</a>.
  </p>
  <p>Birthday</p>
  <p>
    <sup>2</sup> Offer is available to all Express Insider members.
    <a
      href="https://www.express.com/g/terms-conditions"
      target="_blank"
      rel="noreferrer noopener"
      className="linkDark"
    >Click here</a>
    for terms and conditions.
  </p>
  <p>Free shipping</p>
  <p>
    <sup>3</sup> ${
      window?.FLAGS?.FreeShippingAndReturns
        ? `Limited to U.S. Standard Shipping. Offer only available at Express.com and on the
          Express App. Offer not valid on Express Marketplace items and fragrances. If an
          expedited shipping method is chosen, Second Day Delivery or Overnight Delivery,
          the stated shipping and handling fees will apply. If you return a portion of your
          online purchase, shipping charges will be assessed for the return shipping costs.
          Valid on clearance items. Not valid on previous purchases. Offer is exclusive to
          Express Credit Cardmembers enrolled in the Express Insider program.`
        : `Limited to U.S. Standard Shipping. Not valid on previous purchases. No promo
      code required to redeem free shipping, just select Standard Shipping during checkout.
      If an expedited shipping method is chosen, Second Day Delivery or Overnight
      Delivery, the stated shipping and handling fees will apply. If you return a portion
      of your online purchase, shipping charges will be assessed for the return shipping
      costs. Offer is available to Express Insider members that are in the A-List
      Credit Card tier.`
    }
  </p>
  <p>A-list</p>
  <p>
    <sup>4</sup> Spend $375 (or earn 7500 points through a combination of spend and bonus offers)
    within the program year earning period (defined as March 1 to the last day of
    February the following year) on qualifying purchases made with your
    Express Credit Card, minus returns, to upgrade to or maintain A-List status.
    Spend amount excludes Express Marketplace purchases and Style Trial transactions.
  </p>
  <p>
    Credit card offers are subject to credit approval.
    <br />
    Express Credit Card Accounts are issued by Comenity Bank.
  </p>`;

const newTiersLegalCopy = `
  <p>
    <sup>1</sup> 2X points equals 20 points per $1 spent. Offer is available to all Express Insider members. Excludes purchases covered by the redemption of Express Cash or program coupon, EXPRESS Style Trial transactions, gift boxes and gift wrapping services online, redemption of gift cards, sales tax, state fees and shipping charges or delivery. This rewards program is provided by Express and its terms may change at any time. For full Rewards Terms and Conditions, please see
    <a
      href="http://express.com/insiderrules"
      target="_blank"
      rel="noreferrer noopener"
      className="linkDark"
    >http://express.com/insiderrules</a>.
  </p>
  <p>
    <sup>2</sup> 2.5X points equals 25 points per $1 spent. Offer is available to all Express Insider members. Excludes purchases covered by the redemption of Express Cash or program coupon, EXPRESS Style Trial transactions, gift boxes and gift wrapping services online, redemption of gift cards, sales tax, state fees and shipping charges or delivery.
  </p>
  <p>
    <sup>3</sup> Offer is available to Express Insider members. See
    <a
      href="http://express.com/insider"
      target="_blank"
      rel="noreferrer noopener"
      className="linkDark"
    >http://express.com/insider</a>
    for terms and conditions. If the price of a product is less than the value of the Express Cash or Program Coupon, the remaining value of the Express Cash or Program Coupon will be sacrificed.
  </p>
  <p>
    <sup>4</sup> Offer is available to all Express Insider members. See
    <a
      href="http://express.com/insider"
      target="_blank"
      rel="noreferrer noopener"
      className="linkDark"
    >http://express.com/insider</a>
    for terms and conditions.
  </p>
  <p>
    <sup>5</sup> Limited to U.S. Standard Shipping. Not valid on previous purchases. No promo code required to redeem free shipping, just select Standard Shipping during checkout. If an expedited shipping method is chosen, Second Day Delivery or Overnight Delivery, the stated shipping and handling fees will apply. If you return a portion of your online purchase, shipping charges will be assessed for the return shipping costs. Offer is exclusive to Express Credit Card holders enrolled in the Express Insider program.
  </p>
  <p>
     <sup>6</sup> Spend $500 (or earn 10,000+ points through a combination of spend and bonus offers) within the program year earning period (defined as March 1 to the last day of February the following year) on qualifying purchases made with your Express Credit Card, minus returns, to upgrade to or maintain A-List status. Spend amount excludes Express Marketplace purchases and Style Trial transactions.Credit card offers are subject to credit approval.Express Credit Card Accounts are issued by Comenity Bank.
  </p>
  `;

const flagNewTiers = window?.FLAGS?.NewTiers;
const legalCopyParagraph = !flagNewTiers
  ? insiderMainParagraph
  : newTiersLegalCopy;

export const LOYALTY_NEW_TIERS_POINTS = {
  insider: 2500,
  influencer: 10000,
  alist: 0,
  vip: 0,
};

export const LOYALTY_NEW_TIERS_BRANDING_IMAGES = {
  loginPage: {
    loginImage: newTiersSignUpImage,
    a11yLoginCard: 'NewTiersCardV2',
  },
  registerPage: {
    registerImage: newTiersSignUpImage,
    a11yRegisterCard: 'NewTiersCardV2',
  },
};
const LOYALTY_BRANDING_CONFIGS = {
  insiderConfig: {
    loginPage: {
      loginImage: insiderLoginCartImage,
      hasImageText: true,
      hasSubtext: false,
    },
    registerPage: {
      registerImage: insiderSignUpCartImage,
      hasImageText: true,
      hasSubtext: false,
      a11yRegisterCard: 'ExpressInsiderRegisterCard',
    },
    theme: {
      rewardsClass: 'insider',
    },
    bagPage: {
      newENCCBanner: false,
      newInsiderLogo: 'insider',
    },
    perksTable: {
      loyaltyPerksTable: false,
    },
    myAccountProfile: {
      profileCard: 'insider',
    },
    accountPage: {
      textAlertDisclosure: window?.FLAGS?.Attentive
        ? '<p>By checking this box and providing my mobile number, I acknowledge that I have read, understand and agree to the <a href="https://www.express.com/g/terms-conditions.html" target="_blank" rel="noreferrer noopener" class="linkDark">https://www.express.com/g/terms-conditions.html</a> and expressly consent to receive SMS communications from Express. I understand that message and data rates may apply, and my consent is not required to buy goods or services. I understand that I may reply HELP for help, and that I may revoke consent at any time by replying STOP to unsubscribe. If my contact information changes, I should update my profile accordingly.</p >'
        : '<p>This is a mobile number.Send me text alerts & award me a one-time $10 Express Cash. Not eligible if you received previous linking reward. By entering your mobile number, you consent to receiving Express mobile marketing text messages via automated system and agree to text terms <a href="http://express.3c.com/mobilealert" target="_blank" rel="noreferrer noopener" class="linkDark">http://express.3c.com/mobilealert</a>. Consent to text at number provided not required to buy goods or services. Message & data rates may apply. Reply HELP for help, STOP to cancel to 397 - 737.<br/><br/> <a href="http://express.3c.com/mobilealert"target="_blank" rel="noreferrer noopener" class="linkDark">Offer Details</a> and <a href="https://www.express.com/g/terms-conditions" target="_blank" rel="noreferrer noopener" class="linkDark">Terms & Conditions</a></p >',
      aListBenefits: [
        'EARN REWARDS FASTER',
        'MORE FOR YOUR BIRTHDAY',
        'EXCLUSIVE ACCESS',
      ],
      benefits: ['EARN REWARDS', 'BIRTHDAY GIFT', 'EXCLUSIVE ACCESS'],
      listItemClassName: 'insider',
      title: "Here's what you can expect:",
      aListTitle: 'Your A-List benefits include:',
      legalTexts: {
        creditCardHolderText: insiderCreditCardHolderText,
        detailsCopyContainerClassName: 'detailsCopyContainerInsider',
        mainParagraph: legalCopyParagraph,
      },
      bannerENCC: {
        doubleBanner: false,
      },
    },
    myRewardsSubPage: {
      strings: {
        title: 'Insider Bonus',
        subTitle: 'Engage more, earn more. Level up your points and rewards',
        subTitleRight: '',
      },
      css: {
        title: '',
      },
      isInsider: true,
    },
    loyaltyPoints: {
      pointsClass: 'insider',
    },
    orderConfirmationPage: {
      registerContainer: 'insider',
    },
    disclosure: {
      url: 'https://c.comenity.net/express/common/Legal/disclosures.xhtml',
    },
  },
};

export const LOYALTY_BRANDING_CONFIG = LOYALTY_BRANDING_CONFIGS?.insiderConfig;

export const LOYALTY_ENCC_INSTORE_RP = 'Express Insider program';
export const LOYALTY_ENCC_INSTORE_RWP = 'Express Insider Rewards program';
export const LOYALTY_ENCC_INSTORE_EXPRESS = 'Express';
export const LOYALTY_ENCC_INSTORE_URL =
  'https://www.express.com/g/terms-conditions';
export const TIERS_BENEFITS_LIST = {
  earnRewards: { icon: 'earnRewards', name: 'Earn Express Cash' },
  birthdayGift: { icon: 'birthdayGift', name: 'Birthday Gifts' },
  receiptlessReturns: {
    icon: 'receiptlessReturns',
    name: 'Receiptless Returns',
  },
  earlyAccess: { icon: 'earlyAccess', name: 'Early Access' },
  fasterExpressCash: { icon: 'fasterRewardsIcon', name: 'Faster Express Cash' },
  freeShippigAndReturns: {
    icon: 'freeShipping',
    name: 'Free Shipping & Returns',
  },
};
export const EARLY_POINTS_OPTIONS = [
  {
    pointsValue: 1250,
    text: '$5 every 1250 pts.',
    value: 5,
  },
  {
    pointsValue: 2500,
    text: '$10 every 2500 pts.',
    value: 10,
  },
  {
    pointsValue: 3750,
    text: '$15 every 3750 pts.',
    value: 15,
  },
];
export const TIER_STATIC_CONFIGS = {
  alist: {
    heroBannerClassName: 'aListTier',
    nextTier: {
      name: 'A List',
      key: 'alist',
    },
    profileTierText: 'My A List ID',
    tierDesktopIcon: 'loyaltyAListLogoBlack',
    tierMobileIcon: 'loyaltyAListLogoWhite',
    tierModalTitle: 'A List',
    tierBenefits: [
      TIERS_BENEFITS_LIST.earnRewards,
      TIERS_BENEFITS_LIST.birthdayGift,
      TIERS_BENEFITS_LIST.receiptlessReturns,
      TIERS_BENEFITS_LIST.earlyAccess,
    ],
    isECCHolderTierBenefits: [
      TIERS_BENEFITS_LIST.fasterExpressCash,
      TIERS_BENEFITS_LIST.birthdayGift,
      TIERS_BENEFITS_LIST.freeShippigAndReturns,
      TIERS_BENEFITS_LIST.earlyAccess,
    ],
  },
  insider: {
    heroBannerClassName: 'insiderTier',
    nextTier: {
      name: 'Influencer',
      key: 'influencer',
    },
    profileTierText: 'My Insider ID',
    tierDesktopIcon: 'loyaltyInsiderLogoBlack',
    tierMobileIcon: 'loyaltyInsiderLogoWhite',
    tierModalTitle: 'Insider',
    tierBenefits: [
      TIERS_BENEFITS_LIST.earnRewards,
      TIERS_BENEFITS_LIST.birthdayGift,
      TIERS_BENEFITS_LIST.receiptlessReturns,
      TIERS_BENEFITS_LIST.earlyAccess,
    ],
  },
  influencer: {
    heroBannerClassName: 'influencerTier',
    nextTier: {
      name: 'A List',
      key: 'alist',
    },
    profileTierText: 'My Influencer ID',
    tierDesktopIcon: 'loyaltyInfluencerLogoBlack',
    tierMobileIcon: 'loyaltyInfluencerLogoWhite',
    tierModalTitle: 'Influencer',
    tierBenefits: [
      TIERS_BENEFITS_LIST.earnRewards,
      TIERS_BENEFITS_LIST.birthdayGift,
      TIERS_BENEFITS_LIST.receiptlessReturns,
      TIERS_BENEFITS_LIST.earlyAccess,
    ],
    isECCHolderTierBenefits: [
      TIERS_BENEFITS_LIST.fasterExpressCash,
      TIERS_BENEFITS_LIST.birthdayGift,
      TIERS_BENEFITS_LIST.freeShippigAndReturns,
      TIERS_BENEFITS_LIST.earlyAccess,
    ],
  },
  vip: {
    heroBannerClassName: 'vipTier',
    nextTierName: false,
    profileTierText: 'My VIP ID',
    tierDesktopIcon: 'loyaltyVIPLogoBlack',
    tierMobileIcon: 'loyaltyVIPLogoWhite',
    tierModalTitle: 'VIP',
    tierBenefits: [
      TIERS_BENEFITS_LIST.earnRewards,
      TIERS_BENEFITS_LIST.birthdayGift,
      TIERS_BENEFITS_LIST.receiptlessReturns,
      TIERS_BENEFITS_LIST.earlyAccess,
    ],
    isECCHolderTierBenefits: [
      TIERS_BENEFITS_LIST.fasterExpressCash,
      TIERS_BENEFITS_LIST.birthdayGift,
      TIERS_BENEFITS_LIST.freeShippigAndReturns,
      TIERS_BENEFITS_LIST.earlyAccess,
    ],
  },
};

export const daysToTierExpiration = 30;

export const DEFAULT_TIER = 'insider';
// Countries of the 🌏
export const COUNTRIES = [
  {
    value: 'US',
    displayValue: '🇺🇸 United States',
  },
  {
    value: 'AU',
    displayValue: '🇦🇺 Australia',
  },
  {
    value: 'AT',
    displayValue: '🇦🇹 Austria',
  },
  {
    value: 'BB',
    displayValue: '🇧🇧 Barbados',
  },
  {
    value: 'BE',
    displayValue: '🇧🇪 Belgium',
  },
  {
    value: 'BZ',
    displayValue: '🇧🇿 Belize',
  },
  {
    value: 'BG',
    displayValue: '🇧🇬 Bulgaria',
  },
  {
    value: 'CA',
    displayValue: '🇨🇦 Canada',
  },
  {
    value: 'CN',
    displayValue: '🇨🇳 China',
  },
  {
    value: 'CY',
    displayValue: '🇨🇾 Cyprus',
  },
  {
    value: 'DK',
    displayValue: '🇩🇰 Denmark',
  },
  {
    value: 'SV',
    displayValue: '🇸🇻 El Salvador',
  },
  {
    value: 'FI',
    displayValue: '🇫🇮 Finland',
  },
  {
    value: 'FR',
    displayValue: '🇫🇷 France',
  },
  {
    value: 'DE',
    displayValue: '🇩🇪 Germany',
  },
  {
    value: 'GR',
    displayValue: '🇬🇷 Greece',
  },
  {
    value: 'HK',
    displayValue: '🇭🇰 Hong Kong',
  },
  {
    value: 'HU',
    displayValue: '🇭🇺 Hungary',
  },
  {
    value: 'ID',
    displayValue: '🇮🇩 Indonesia',
  },
  {
    value: 'IE',
    displayValue: '🇮🇪 Ireland',
  },
  {
    value: 'IL',
    displayValue: '🇮🇱 Israel',
  },
  {
    value: 'IT',
    displayValue: '🇮🇹 Italy',
  },
  {
    value: 'JM',
    displayValue: '🇯🇲 Jamaica',
  },
  {
    value: 'JP',
    displayValue: '🇯🇵 Japan',
  },
  {
    value: 'LI',
    displayValue: '🇱🇮 Liechtenstein',
  },
  {
    value: 'LU',
    displayValue: '🇱🇺 Luxembourg',
  },
  {
    value: 'MO',
    displayValue: '🇲🇴 Macau',
  },
  {
    value: 'MX',
    displayValue: '🇲🇽 Mexico',
  },
  {
    value: 'MC',
    displayValue: '🇲🇨 Monaco',
  },
  {
    value: 'NL',
    displayValue: '🇳🇱 Netherlands',
  },
  {
    value: 'NO',
    displayValue: '🇳🇴 Norway',
  },
  {
    value: 'PL',
    displayValue: '🇵🇱 Poland',
  },
  {
    value: 'PT',
    displayValue: '🇵🇹 Portugal',
  },
  {
    value: 'SG',
    displayValue: '🇸🇬 Singapore',
  },
  {
    value: 'KR',
    displayValue: '🇰🇷 South Korea',
  },
  {
    value: 'ES',
    displayValue: '🇪🇸 Spain',
  },
  {
    value: 'SE',
    displayValue: '🇸🇪 Sweden',
  },
  {
    value: 'CH',
    displayValue: '🇨🇭 Switzerland',
  },
  {
    value: 'TW',
    displayValue: '🇹🇼 Taiwan',
  },
  {
    value: 'TH',
    displayValue: '🇹🇭 Thailand',
  },
  {
    value: 'TT',
    displayValue: '🇹🇹 Trinidad and Tobago',
  },
  {
    value: 'GB',
    displayValue: '🇬🇧 United Kingdom',
  },
  {
    value: 'APO',
    displayValue: '  APO',
  },
  {
    value: 'DPO',
    displayValue: '  DPO',
  },
  {
    value: 'FPO',
    displayValue: '  FPO',
  },
];

// US States 🇺🇸
export const US_STATES = [
  {
    value: 'AL',
    displayValue: 'AL',
  },
  {
    value: 'AK',
    displayValue: 'AK',
  },
  {
    value: 'AZ',
    displayValue: 'AZ',
  },
  {
    value: 'AR',
    displayValue: 'AR',
  },
  {
    value: 'CA',
    displayValue: 'CA',
  },
  {
    value: 'CO',
    displayValue: 'CO',
  },
  {
    value: 'CT',
    displayValue: 'CT',
  },
  {
    value: 'DE',
    displayValue: 'DE',
  },
  {
    value: 'DC',
    displayValue: 'DC',
  },
  {
    value: 'FL',
    displayValue: 'FL',
  },
  {
    value: 'GA',
    displayValue: 'GA',
  },
  {
    value: 'GU',
    displayValue: 'GU',
  },
  {
    value: 'HI',
    displayValue: 'HI',
  },
  {
    value: 'ID',
    displayValue: 'ID',
  },
  {
    value: 'IL',
    displayValue: 'IL',
  },
  {
    value: 'IN',
    displayValue: 'IN',
  },
  {
    value: 'IA',
    displayValue: 'IA',
  },
  {
    value: 'KS',
    displayValue: 'KS',
  },
  {
    value: 'KY',
    displayValue: 'KY',
  },
  {
    value: 'LA',
    displayValue: 'LA',
  },
  {
    value: 'ME',
    displayValue: 'ME',
  },
  {
    value: 'MD',
    displayValue: 'MD',
  },
  {
    value: 'MA',
    displayValue: 'MA',
  },
  {
    value: 'MI',
    displayValue: 'MI',
  },
  {
    value: 'MN',
    displayValue: 'MN',
  },
  {
    value: 'MS',
    displayValue: 'MS',
  },
  {
    value: 'MO',
    displayValue: 'MO',
  },
  {
    value: 'MT',
    displayValue: 'MT',
  },
  {
    value: 'NE',
    displayValue: 'NE',
  },
  {
    value: 'NV',
    displayValue: 'NV',
  },
  {
    value: 'NH',
    displayValue: 'NH',
  },
  {
    value: 'NJ',
    displayValue: 'NJ',
  },
  {
    value: 'NM',
    displayValue: 'NM',
  },
  {
    value: 'NY',
    displayValue: 'NY',
  },
  {
    value: 'NC',
    displayValue: 'NC',
  },
  {
    value: 'ND',
    displayValue: 'ND',
  },
  {
    value: 'OH',
    displayValue: 'OH',
  },
  {
    value: 'OK',
    displayValue: 'OK',
  },
  {
    value: 'OR',
    displayValue: 'OR',
  },
  {
    value: 'PA',
    displayValue: 'PA',
  },
  {
    value: 'PR',
    displayValue: 'PR',
  },
  {
    value: 'RI',
    displayValue: 'RI',
  },
  {
    value: 'SC',
    displayValue: 'SC',
  },
  {
    value: 'SD',
    displayValue: 'SD',
  },
  {
    value: 'TN',
    displayValue: 'TN',
  },
  {
    value: 'TX',
    displayValue: 'TX',
  },
  {
    value: 'UT',
    displayValue: 'UT',
  },
  {
    value: 'VT',
    displayValue: 'VT',
  },
  {
    value: 'VA',
    displayValue: 'VA',
  },
  {
    value: 'VI',
    displayValue: 'VI',
  },
  {
    value: 'WA',
    displayValue: 'WA',
  },
  {
    value: 'WV',
    displayValue: 'WV',
  },
  {
    value: 'WI',
    displayValue: 'WI',
  },
  {
    value: 'WY',
    displayValue: 'WY',
  },
];

// Double Digit Month Values ௴
export const MONTH_VALUES = [
  {
    value: '01',
    displayValue: '01',
  },
  {
    value: '02',
    displayValue: '02',
  },
  {
    value: '03',
    displayValue: '03',
  },
  {
    value: '04',
    displayValue: '04',
  },
  {
    value: '05',
    displayValue: '05',
  },
  {
    value: '06',
    displayValue: '06',
  },
  {
    value: '07',
    displayValue: '07',
  },
  {
    value: '08',
    displayValue: '08',
  },
  {
    value: '09',
    displayValue: '09',
  },
  {
    value: '10',
    displayValue: '10',
  },
  {
    value: '11',
    displayValue: '11',
  },
  {
    value: '12',
    displayValue: '12',
  },
];

export const HA_GIFT_CARD_RANGE = (() => {
  if (isProductionBuild()) {
    return {
      BIN_NUMBER: 600649610,
      MIN_RANGE: 0,
      MAX_RANGE: 3999899999,
    };
  }

  return {
    BIN_NUMBER: 600649610,
    MIN_RANGE: 3999900000,
    MAX_RANGE: 3999999999,
  };
})();

export const ERROR_CODES = {
  appOnlyPromo: 'PROMO_IS_APP_ONLY',
  expiredPromo: 'EXPIRED_PROMO_CODE',
  invalidPromo: 'INVALID_PROMO_CODE',
  outOfStock: 'INVENTORY_OUT_OF_STOCK',
  promoInUse: 'PROMO_USED_ALREADY',
  quantityReduced: 'INVENTORY_ADJUSTED',
  requiredPromo: 'REQUIRED_PROMO_CODE',
  reservationFailed: 'ORDER_RESERVATION_FAILED',
  restrictedItemsPromo: 'RESTRICTED_ITEMS_PROMO_CODE',
  singlePromoPerOrder: 'SINGLE_PROMO_PER_ORDER',
};

export const STYLING_TOPS = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
export const STYLING_WAISTS = [
  '26',
  '28',
  '29',
  '30',
  '32',
  '33',
  '34',
  '35',
  '36',
  '38',
  '40',
  '42',
];
export const STYLING_LENGTHS = [
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '38',
];
export const STYLING_BOTTOMS = [
  '00',
  '0',
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '16',
  '18',
];

export const MY_ACCOUNT_ROUTES = [
  {
    displayText: 'OVERVIEW',
    link: '/my-account',
    external: false,
    iconName: 'overview',
  },
  {
    displayText: 'EARN REWARDS',
    link: '/my-account/my-rewards',
    external: false,
    iconName: 'rewards',
  },
  {
    displayText: 'ORDERS',
    link: '/my-account/online-orders',
    external: false,
    iconName: 'onlineOrder',
  },
  {
    displayText: 'PROFILE',
    link: '/my-account/settings',
    external: false,
    iconName: 'account',
  },
  {
    displayText: 'EXPRESS CREDIT CARD',
    link: '/my-account/express-next-credit-card',
    external: false,
    iconName: 'insiderCard',
  },
  {
    displayText: 'PAY YOUR BILL',
    link: 'https://c.comenity.net/ac/express/public/home',
    external: true,
    iconName: 'payBill',
  },
  {
    displayText: 'SIGN OUT',
    link: '/logout',
    external: false,
    iconName: '',
  },
];

export const MY_ACCOUNT_ROUTES_NON_US = [
  {
    displayText: 'PROFILE',
    link: '/my-account/settings',
    external: false,
    iconName: 'account',
  },
  {
    displayText: 'ORDERS',
    link: '/my-account/online-orders',
    external: false,
    iconName: 'onlineOrder',
  },
];

export const SOCIAL_ACCOUNTS = [
  { name: 'Twitter', icon: 'twitter', socialMediaId: 'twitter' },
  { name: 'Google', icon: 'googleColorG', socialMediaId: 'googleplus' },
  { name: 'Facebook', icon: 'facebook', socialMediaId: 'facebook' },
  { name: 'Instagram', icon: 'instagram', socialMediaId: 'instagram' },
];

// APO/FPO/DPO states
export const OTHER_STATES = [
  { value: 'AE', displayValue: 'AE - Armed Forces Europe' },
  { value: 'AA', displayValue: 'AA - Armed Forces of the Americas' },
  { value: 'AP', displayValue: 'AP - Armed Forces Pacific' },
];

export const FUNC_ERROR_TEXT = 'Expected a function';

// Product and Banner default images sizes for category pages
export const IMAGE_WIDTH = 319;
export const IMAGE_HEIGHT = 399;
export const BANNER_WIDTH = 324;
export const BANNER_HEIGHT = 404;

export const FULFILLMENTH_METHOD_SHIPPING = 'shipping';
export const FULFILLMENTH_METHOD_BOPIS = 'bopis';

export const MIN_KLARNA_ALLOWED_AMOUNT = 10;
export const MAX_KLARNA_ALLOWED_AMOUNT = 1000;

export const GCP_PREDICTIONS_ENUM = {
  BUY_IT_AGAIN: 'buyItAgain',
  FREQUENTLY_BOUGHT_TOGETHER: 'frequentlyBoughtTogether',
  ON_SALE: 'onSale',
  OTHERS_YOU_MAY_LIKE: 'othersYouMayLike',
  RECENTLY_VIEWED: 'recentlyViewed',
  RECOMMENDED_FOR_YOU: 'recommendedForYou',
  SIMILAR_ITEMS: 'similarItems',
};

export const GCP_PREDICTIONS_CONTAINER_MODES_ENUM = {
  carousel: 'carousel',
  listView: 'listView',
};

export const GENDER_ENUM = {
  MALE: ['men', 'Men', 'MEN'],
  MEN: ['men', 'Men', 'MEN'],
  FEMALE: ['women', 'Women', 'WOMEN'],
  WOMEN: ['women', 'Women', 'WOMEN'],
};

export default {
  BVUID,
  COUNTRIES,
  DEFAULT_TIER,
  ERROR_CODES,
  FUNC_ERROR_TEXT,
  GOOGLE_MAPS_API_KEY,
  GOOGLE_RECAPTCHA_ENTERPRISE_KEY,
  HA_GIFT_CARD_RANGE,
  LIVE_CHAT,
  LOCAL_STORAGE_SECRET,
  MY_ACCOUNT_ROUTES,
  SOCIAL_ACCOUNTS,
  TIER_STATIC_CONFIGS,
  US_STATES,
  GCP_PREDICTIONS_ENUM,
  GCP_PREDICTIONS_CONTAINER_MODES_ENUM,
};
