/**
 * ColorManager
 *
 * The product API groups product SKUs (stock keeping units.  aka items that can be added to cart)
 * by color.  There is an array of colors in the product api response. Each cell in the color
 * array contains sku's in various sizes, inseams, extensions, for that product in that color.
 *
 * This class contains methods to parses the color slices for the SKUs and creates lists of
 * useful information used in the PDP, assigning them to class properties.
 */
export default class ColorManager {
  static standardSizes = [
    'XXXXXS',
    'XXXXXS/XXXXS',
    'XXXXS',
    'XXXXS/XXXS',
    'XXXS',
    'XXXS/XXS',
    'XXS',
    'XXS/XS',
    'XS',
    'XS/S',
    'S',
    'S/M',
    'M',
    'M/L',
    'L',
    'L/XL',
    'XL',
    'XL/XXL',
    'XXL',
    'XXL/XXXL',
    'XXXL',
    'XXXL/XXXXL',
    'XXXXL',
    'XXXXL/XXXXXL',
    'XXXXXL',
  ];

  constructor(colorSlices = []) {
    this.init();
    this.parseColors(colorSlices);

    ['extractSkus', 'getColor', 'getColors', 'getDefaultColor'].forEach((m) => {
      this[m] = this[m].bind(this);
    });
  }

  init() {
    /**
     * an object map where the key is a color name and the value is it's index position in colorSet
     * {
     *  'RED': 1,
     *  'BLUE': 2,
     *  'YELLOW': 3,
     * }
     * @type {Object}
     */
    this.colorIdx = {};

    /**
     * an array of color objects
     * @type {Array}
     */
    this.colorSet = [];

    /**
     * an array of only in-stock colors objects
     * @type {Array}
     */
    this.inStockColorSet = [];

    /**
     * a list of all the skus from all the color objects
     * [{ sku object }, { sku object }, { sku object }, ...]
     * @type {Array}
     */
    this.skuSet = [];

    /**
     * An object map where the key is the skuId and the value is the entire sku object.
     * use this instead of Array.find() to find a sku by id. It's faster.
     * {
     *   skuId: { sku object },
     *   skuId: { sku object },
     *   skuId: { sku object },
     *   ...
     * }
     * @type {Object}
     */
    this.skuMap = {};

    /**
     * An object map that is two levels deep.
     * The first level's key is a color name.
     * The first level's value is an object which is also a map.
     * The second level's key is the skuId
     * The secone level value is the sku.
     * This was created to easily see which sku's belong to which color slice.
     * {
     *  'RED': {
     *    skuId: { sku object },
     *    skuId: { sku object },
     *    skuId: { sku object },
     *  },
     *  'BLUE': {
     *    skuId: { sku object },
     *    skuId: { sku object },
     *    skuId: { sku object },
     *  },
     * }
     * @type {Object}
     */
    this.skuColorMap = {};

    /**
     * a list of all the unique sizes in all the skus for this product
     * [ 'S', 'M', 'L' ...]
     * @type {Array}
     */
    this.skuSizes = [];

    /**
     * a list of all the unique extensions in all the skus for this product
     * ['short, 'tall', 'regular', ... ]
     * @type {Array}
     */
    this.skuExtensions = [];

    /**
     * a list of all the unique inseams in all the skus for this product
     * ['32', '33', '34', ...]
     * @type {Array}
     */
    this.skuInseams = [];

    /**
     * The default color index for this product
     * @type {Number}
     */
    this.defaultColorIndex = 0;
  }

  /**
   * Returns a vendor flag directly from the window object instead of redux store.
   * @param  {String} name The name of a vendor flag. e.g. "BackorderConsideredInStock", "Gigya"
   * @return {[Undefined|Boolean]} undefined if the flag is not found.  Otherwise, boolean value of
   *                               the flag is returned.
   */
  static getFlag(name) {
    return window && window.FLAGS && window.FLAGS[name];
  }

  static sortStandardSizes(a, b) {
    return (
      ColorManager.standardSizes.indexOf(a) -
      ColorManager.standardSizes.indexOf(b)
    );
  }

  static sortNumericSizes(a, b) {
    return parseFloat(a, 10) - parseFloat(b, 10);
  }

  /**
   * Scan through all the color objects and find important information. Store that information as
   * properties in this class.  This needs to run before any other methods in this class.  The
   * constructor calls this method on instantiation.
   * @param  {Array} colorSlices Array of color objects from productData.colorSlices.
   * @return {void}
   */
  parseColors(colorSlices) {
    this.defaultColorIndex = 0;
    this.is4Dimensional = false;

    const isBackorderConsideredInStock = ColorManager.getFlag(
      'BackorderConsideredInStock'
    );

    let isSizesWeRecognize = true;
    let isAllNumericSizes = true;

    colorSlices.forEach((colorSlice, idx) => {
      let hasOnlineSkus = false;

      const { skus, color, onlineSkus, ...filteredProps } = colorSlice;

      const colorName = color;
      const inStoreSkus = [];
      const skusLength = skus.length || 0;

      for (let i = 0; i < skusLength; i += 1) {
        const sku = skus[i];

        if (
          !hasOnlineSkus &&
          (sku.isInStockOnline ||
            (isBackorderConsideredInStock && sku.backOrderable))
        ) {
          hasOnlineSkus = true;
        }

        // create three lists that we can use to look up skus quickly and reduce the number
        // of forEach, find, some, includes, filter loops needed to find things.  This speeds
        // up the code significantly

        // add new skuSet object to the skuSet list
        this.skuSet.push({
          colorName,
          colorIndex: idx,
          ...sku,
        });

        // add a reference to the new skuSet object to the skuMap list
        this.skuMap[sku.skuId] = this.skuSet[this.skuSet.length - 1];

        // add a reference to the new skuSet object to the skuColorMap list
        if (!this.skuColorMap[colorName]) this.skuColorMap[colorName] = {};
        this.skuColorMap[colorName][sku.skuId] =
          this.skuSet[this.skuSet.length - 1];

        // why all the references above?
        // This now means, in memory, the object added to skuSet, skuMap, and skuColorMap
        // are all references to the SAME object.  javascript assigns objects to variable
        // by reference NOT by value. Changes to one will change the other 2 since they all refer
        // to the same memory location.  This is intentional.  This keeps them in sync.

        // inStoreSkus is used by GIV (InStoreContainer).  I have a sneaking suspision this list
        // of skus might be the same as the onlineSkus property.  TODO: spike research if this
        // list of skus is even necessary anymore. If it isn't, update InStoreContainer.  To test
        // this theory see if you can find any product where inStoreSkus !== onlineSkus
        if (
          ['Out of stock'].indexOf(sku.inventoryMessage) === -1 &&
          (sku.isInStockOnline ||
            (isBackorderConsideredInStock && sku.backOrderable))
        ) {
          inStoreSkus.push(sku);
          hasOnlineSkus = true;
        }

        const { ext, inseam, size } = sku;

        if (
          size !== null &&
          typeof size !== 'undefined' &&
          this.skuSizes.indexOf(size) === -1
        ) {
          this.skuSizes.push(size);
          if (isSizesWeRecognize) {
            isSizesWeRecognize = ColorManager.standardSizes.includes(size);
          }
          if (isAllNumericSizes) {
            isAllNumericSizes = !!parseFloat(size, 10);
          }
        }

        if (
          ext !== null &&
          typeof ext !== 'undefined' &&
          this.skuExtensions.indexOf(ext) === -1
        )
          this.skuExtensions.push(ext);

        if (
          inseam !== null &&
          typeof inseam !== 'undefined' &&
          this.skuInseams.indexOf(inseam) === -1
        )
          this.skuInseams.push(inseam);
      }

      const colorSetObject = {
        ...filteredProps,
        onlineSkus: onlineSkus instanceof Array ? [...onlineSkus] : [],
        color,
        hasOnlineSkus,
        inStoreSkus,
        skus,
      };

      this.colorSet.push(colorSetObject);

      if (hasOnlineSkus) this.inStockColorSet.push(colorSetObject);

      if (colorSlice.defaultSlice) this.defaultColorIndex = idx;

      this.colorIdx[colorSlice.color] = idx;
    });

    // sizes are currently sorted in the PIM database -- not always correctly, it turns out.
    // There is no telling how PIM sizes will be entered by merchants in the future.  There are
    // currently many, many variations. We can only auto-sort of two basic types of sizes, letter
    // sizes and numeric sizes.
    //
    // If any of the sizes are not numeric, or letters, we fall back to the order in which they
    // appear in the PIM database.
    if (isSizesWeRecognize) {
      this.skuSizes.sort(ColorManager.sortStandardSizes);
    }

    if (isAllNumericSizes) {
      this.skuSizes.sort(ColorManager.sortNumericSizes);
    }

    // Inseams are created by us for use on the front end so we must sort this list ourselves
    this.skuInseams.sort((a, b) => parseFloat(a, 10) - parseFloat(b, 10));
  }

  /**
   * Get an array of color objects.
   * @return {Array} array of color objects.
   */
  getColors() {
    return this.inStockColorSet;
  }

  /**
   * Gets a single color object from the colorSet array.
   * @param  {string} colorName The name of a color.  For example: BLUE
   * @return {Object}           A color object.
   */
  getColor(colorName) {
    const index = this.colorIdx[colorName];
    return this.colorSet[index] || null;
  }

  /**
   * Get the array of skus for all colors.
   * @return {Array}
   */
  extractSkus() {
    return this.skuSet;
  }

  /**
   * Get the default color object.
   *
   * The API returns color slice objects and one of them should always have
   * defaultColorIndex set to "true"
   *
   * If the colorSet is completely blank (user clicked on a link to a very old out of stock item)
   * this will return undefined because a color set with no colors has no default color.
   *
   * Default behavior (isInStockOnline === true):
   * - this method will first look for a color slice that has defaultColorIndex set to true.
   * - If that color is sold out online, it will find the first in stock color.
   * - If no colors are in stock online, it will return undefined.
   *
   * Optional behavior (isInStockOnline === false):
   * - this method will look for a color slice that has defaultColorIndex set to true
   * - if that color is sold out online, it will find the first in stock color
   * - If no colors are in stock online, it will revert back to the color slice where
   *   defaultColorIndex is set to true
   *
   * @param  {Object}  arg                 A single object argument
   * @param  {Boolean} arg.isInStockOnline Only return in-stock colors
   * @param  {Boolean} arg.isBackorderConsideredInStock If true, consider out of stock items that
   *                                                    are backorderable to be "in stock". They
   *                                                    default is false.
   * @param  {String} arg.overrideColor manually choose a default color
   * @return {[Undefined|Object]}
   */
  getDefaultColor({
    isInStockOnline = true,
    isBackorderConsideredInStock = false,
    overrideColor = null,
  } = {}) {
    // try to find the overrideColor in the color set and check if its in stock
    if (overrideColor) {
      const overrideColorSlice = this.getColor(overrideColor);
      if (overrideColorSlice) {
        // going to have to do a find to get the matching colorSlice
        const isOverrideColorInStock = overrideColorSlice.skus.some(
          (sku) =>
            sku.isInStockOnline ||
            (isBackorderConsideredInStock && sku.backOrderable)
        );

        // if the default color is in stock, return it.
        if (isOverrideColorInStock) {
          return overrideColorSlice;
        }
      }
    }

    // this is the default color, regardless of if it is in stock or not.
    const defaultColorSlice = this.colorSet[this.defaultColorIndex];

    if (typeof defaultColorSlice === 'undefined') return undefined;

    // if the default color is in stock, return it.
    const isDefaultColorInStock = defaultColorSlice.skus.some(
      (sku) =>
        sku.isInStockOnline ||
        (isBackorderConsideredInStock && sku.backOrderable)
    );

    if (isDefaultColorInStock) {
      return defaultColorSlice;
    }

    // if the default color is not in stock, return the first in stock color
    const firstInStockColor = this.colorSet.find((colorSlice) =>
      colorSlice.skus.some(
        (sku) =>
          sku.isInStockOnline ||
          (isBackorderConsideredInStock && sku.backOrderable) ||
          colorSlice.hasOnlineSkus
      )
    );

    if (!isDefaultColorInStock && firstInStockColor) {
      return firstInStockColor;
    }

    // we want to return only inStock colors.
    if (isInStockOnline) {
      // if the default color is not in stock and there is no in stock color return undefine
      return undefined;
    }

    // if default color is not in stock and there is no in stock color return the default color
    return defaultColorSlice;
  }

  hasFourDimensions() {
    return this.is4Dimensional;
  }

  updateColorsAvailability({ add = [], remove = [] } = {}) {
    let commandCount = 0;

    // remove
    for (let i = 0, { length } = remove; i < length; i += 1) {
      const command = remove[i];

      // update skuSet, skuMap, and skuColorMap all at once (they all refer to the same sku object)
      const sku = this.skuMap[command.id];
      if (sku) sku.isInStockOnline = false;

      // update the colorSet
      const color = this.getColor(command.color);
      const skuIdx = color.onlineSkus.indexOf(command.id);
      if (skuIdx !== -1) color.onlineSkus.splice(skuIdx, 1);
      color.hasOnlineSkus = !!color.onlineSkus.length;
      commandCount += 1;
    }

    // add
    for (let i = 0, { length } = add; i < length; i += 1) {
      const command = add[i];

      // update skuSet, skuMap, and skuColorMap all at once (they all refer to the same sku object)
      const sku = this.skuMap[command.id];
      if (sku) sku.isInStockOnline = true;

      // update the colorSet
      const color = this.getColor(command.color);
      const skuIdx = color.onlineSkus.indexOf(command.id);
      if (skuIdx === -1) color.onlineSkus.push(command.id);
      color.hasOnlineSkus = !!color.onlineSkus.length;
      commandCount += 1;
    }

    return commandCount;
  }
}
