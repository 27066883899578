import React from 'react';
import PropTypes from 'prop-types';
import { getPath, loog } from '@express-labs/raven-tools';
import { Skeleton } from '@express-labs/raven-ui';
// named imports for Error400 and Error500 from components/index.js are failing for some reason.
import Error500 from '../../components/Error500';
import Error400 from '../../components/Error400';
import TestResult from './testResult';
import { component } from '../custom-prop-types';
import { MODE_404_SEO } from '../../constants';

/**
 * A higher order component that handles page-level graphql loading and errors.
 *
 * This HOC eliminates the need repeat code used at page level containers to show the skeleton or
 * error messages.
 *
 * The wrapped page container will only render once after all network calls have successfully
 * finished. This means that your wrapped container...
 * - doesn't need to check for undefined props in UNSAFE_componentWillReceiveProps, constructor, etc
 * - does not need to insert Skeleton, Error400, or Error500 components.
 * - does not need to call removeSkeleton()
 * - Reduces the complicated logic needed to notify Adobe target tests that the component is ready.
 *
 * By default, this HOC will scan all incoming Component props and auto-magically determine if a
 * particular prop was inserted via apollo-client's graphql() HOC.
 *
 * You can also provide a list of additional props to examine.  When providing custom props you must
 * provide a function to test for success.  Example:
 *
 * compose(
 *  withPageLoading({
 *    custom: [
 *      {
 *        errorData: ownProps => returns arbitrary error data or something falsey if no error.
 *                               the component wrapped by this HOC will have to decide what
 *                               to do with the error data.
 *        isError: ownProps => some function that returns true or false,
 *        isLoading: ownProps => some function that returns true or false,
 *        isSuccess: ownProps => some function that returns true or false,
 *      }
 *    ]
 *  }),
 *  someOtherHoc,
 * )(HelloWorldPage)
 *
 * The props inserted into the wrapped component have the following shape
 * withPageLoading {
 *  errorData: Array
 *  isError: boolean
 *  isLoading: boolean
 * }
 *
 * @extends React
 */
export class WithPageLoading extends React.Component {
  static defaultState = {
    // Assume something is loading, otherwise you wouldn't be using withPageLoading
    isLoading: true,

    // tracks if any query had an error
    isError: null,

    // stores all error data
    errorData: null,
  };

  static isFunction(x) {
    return x && typeof x === 'function';
  }

  /**
   * A basic test to see if the prop in question is an object with the shape of an apollo graphql
   * query.
   *
   * Explanation:
   * By default, the graphql() hoc adds a new prop named "data" to a component.  We, however, often
   * name our queries to prevent two or more queries from overwriting the "data" prop. That name
   * becomes the name of the prop attached to our component by the graphql higher order component.
   *
   * for more information on the networkStatus prop created by Apollo client, see
   * https://github.com/apollographql/apollo-client/blob/master/packages/apollo-client/src/core/networkStatus.ts
   *
   * @param  {String}  prop The name of the prop to test to see if it was created by the graphql
   *                        HOC.
   * @return {Boolean}      True if we think the prop was created by a graphql HOC.
   */
  static isPropApolloQuery(prop) {
    return (
      prop &&
      // a successful query by graphql() hoc
      ((typeof prop.loading === 'boolean' &&
        typeof prop.networkStatus === 'number') ||
        // a network or graphql error by graphql() hoc
        (typeof prop.error !== 'undefined' && prop.networkStatus === 8))
    );
  }

  /**
   * Scans all of the component's props and returns a list of prop names that we believe were
   * added to this component by an Apollo graphql query.
   *
   * @param  {Object} ownProps The component's props.
   * @return {Array}           A list of component prop names.
   */
  static scanForApolloPropNames(ownProps) {
    return Object.keys(ownProps).filter((key) =>
      WithPageLoading.isPropApolloQuery(ownProps[key])
    );
  }

  /**
   * Run tests on all the props added to this component by Apollo and graphql to determine each
   * query or mutations state.
   *
   * @param  {Array} propNames  An array of prop names that we believe are graphql queries.
   * @param  {Object} ownProps  The current component's props
   * @return {Array}            An array of test result objects
   */
  static createApolloTestResults(propNames, ownProps) {
    const testResults = [];
    propNames.forEach((key) => {
      const ownProp = ownProps[key];
      testResults.push(
        new TestResult({
          errorData: !!ownProp.error && {
            propName: key,
            error: ownProp.error,
            raw: ownProp,
          },
          isError: !!ownProp.error,
          isLoading: ownProp.loading === true,
          isSuccess: ownProp.loading === false && ownProp.networkStatus === 7,
        })
      );
    });
    return testResults;
  }

  /**
   * Run an array of custom tests on props to determine the loading state.
   *
   * Tests should be placed in withPageLoadingOptions.custom.
   *
   * Each test should be an object with the following property names: errorData, isError, isLoading
   * isSuccess. The value for each of these properties should be a function.  The function will be
   * given all the props.
   *
   * example:
   * withPageLoadingOptions.custom = [{
   *  errorData: ownProps => { custom test logic to return error data if there is an error },
   *  isError: ownProps => { custom test logic to determine if there is an error. returns a bool}
   *  isLoading: ownProps => { custom test logic to test if the prop is loading. returns a bool}
   *  isSuccess: ownProps => { custom test logic to see if prop successfully loaded. return bool}
   * }]
   *   *
   *
   * @param  {Object} ownProps The current component's props
   * @return {Array}          An array of test result objects
   */
  static createCustomTestResults(ownProps) {
    const customTests = ownProps.withPageLoadingOptions.custom || [];
    return customTests.map(
      (test) =>
        new TestResult({
          errorData: test.errorData(ownProps),
          isError: test.isError(ownProps),
          isLoading: test.isLoading(ownProps),
          isSuccess: test.isSuccess(ownProps),
        })
    );
  }

  /**
   * Determine if at least one test in an array of tests result objects indicate that they are still
   * loading.  If at least one test is still loading, return true.
   *
   * @param  {Array}  testResults An array of TestResult objects
   * @return {Boolean}            True if at least one test in the array is loading.
   */
  static isLoading(testResults) {
    let count = 0;
    testResults.forEach((testResult) => {
      if (testResult.get().isLoading) count += 1;
    });
    return count !== 0;
  }

  /**
   * Determine if at least one test in an array of tests result objects indicate that they are
   * an error.  If at least one test is an error, return true.
   *
   * @param  {Array}  testResults An array of TestResult objects
   * @return {Boolean}            True if any test is an error.
   */
  static isError(testResults) {
    let count = 0;
    testResults.forEach((testResult) => {
      if (testResult.isError) count += 1;
    });
    return count !== 0;
  }

  /**
   * Parse an array of test result objects and create a new array containing all the errorData
   * property values.
   *
   * @param  {Arrau} testResults  Array of TestResult objects.
   * @return {Array}              Array of errorData values or false if none.
   */
  static makeErrorDataArray(testResults) {
    return testResults
      .filter((testResult) => testResult.isError && testResult.errorData)
      .reduce(
        (accumulator, testResult) => [...accumulator, testResult.errorData],
        []
      );
  }

  /**
   * Detemines if the error message is a 404
   * property values.
   *
   * @param  {String} errorMessage              Error message string.
   * @param  {String} testRgqlErrorPathesults   Error message path.
   * @return {Boolean}                          Returns if a product error is a 404 error or not.
   */
  static is404ProductError(errorMessage, gqlErrorPath) {
    if (errorMessage && gqlErrorPath) {
      return (
        errorMessage.toLowerCase().includes('product not found') &&
        (gqlErrorPath?.[0] === 'inventory' || gqlErrorPath?.[0] === 'product')
      );
    }
    return false;
  }

  static getStatusCode(isErrors404, stringStatus) {
    return isErrors404 ? 404 : parseInt(stringStatus?.[0] || 0, 10);
  }

  static propTypes = {
    // The withPageLoading HOC must wrap a component
    component,

    // additional options
    withPageLoadingOptions: PropTypes.shape({
      custom: PropTypes.arrayOf(
        PropTypes.shape({
          errorData: PropTypes.func,
          isError: PropTypes.func,
          isLoading: PropTypes.func,
          isSuccess: PropTypes.func,
        })
      ),
    }),
  };

  static defaultProps = {
    component: null,
    withPageLoadingOptions: {
      custom: [],
    },
  };

  constructor() {
    super();
    this.state = { ...WithPageLoading.defaultState };
  }

  /**
   * React lifecycle method that is called before first render and after prop changes.  Used to
   * set the state for this component.
   * @param  {Object} nextProps incoming prop values
   * @return {[Object|null]}    An object that is merged into current state. Null if no changes.
   */
  static getDerivedStateFromProps(nextProps) {
    // get a list Component prop names that were inserted by Apollo running a query.
    // we do this by examining each prop for a particular shape.
    // This is the signature of a loading graphql query:
    // queryName: {
    //  loading: [bool],
    //  networkStatus: [number < 7]
    // }
    //
    // This is the signature of a graphql query error:
    // queryName: {
    //  error: [is not undefined]
    //  networkStatus: 8
    // }
    const apolloPropNames = WithPageLoading.scanForApolloPropNames(nextProps);

    const testResults = [
      ...WithPageLoading.createApolloTestResults(apolloPropNames, nextProps),
      ...WithPageLoading.createCustomTestResults(nextProps),
    ];

    const retVal = {
      isLoading: WithPageLoading.isLoading(testResults),
      isError: WithPageLoading.isError(testResults),
      errorData: WithPageLoading.makeErrorDataArray(testResults),
    };

    return retVal;
  }

  /**
   * Returns a loading skeleton for the page.
   * @return {Node} instance of Skeleton component.
   */
  static renderLoading() {
    const skel = document.getElementById('skel');
    const page = skel ? skel.getAttribute('data-skel-template') : 'empty';
    return <Skeleton page={page} />;
  }

  /**
   * TODO: need to move this out of here into it's own HOC.
   * @return {React.Node} Error page.
   */
  renderError() {
    // just grab the first apollo error message for now.
    // TODO: loop through the errorData array and find the first valid error data
    const gqlErrorPath = getPath(
      this.state,
      'errorData.0.graphQLErrors.0.path',
      null
    );
    const gqlErrorMessage = getPath(
      this.state,
      'errorData.0.graphQLErrors.0.message',
      null
    );
    let statusCode = getPath(
      this.state,
      'errorData.0.networkError.response.status',
      null
    );
    if (!statusCode && gqlErrorMessage) {
      const isErrors404 = WithPageLoading.is404ProductError(
        gqlErrorMessage,
        gqlErrorPath
      );
      // String.match() returns null if not found, array with details if found
      const stringStatus = gqlErrorMessage.match(/^\d{3}/);

      statusCode = WithPageLoading.getStatusCode(isErrors404, stringStatus);
    }

    // ATG used to return a 404 when the product endpoint could not find a product ID.
    // The new NOVAs now return a 400 when a product ID isn't found.
    if (statusCode && statusCode === 400) {
      return <Error400 />;
    }

    // TO DO: Enable test when DevOps configures the 404 url
    /* istanbul ignore next */
    if (statusCode && statusCode === 404) {
      return <Error400 mode={MODE_404_SEO} />;
    }

    const {
      userAgent,
      buildID,
      cookieEnabled,
      language,
      maxTouchPoints,
      onLine,
      userAgentData,
      vendor,
      webdriver,
    } = window.navigator; // returns a Navigator() object.  See MDN docs.

    const errorPayload = {
      note: 'withPageLoading() hoc encountered an error',
      location: window.location,
      info: {
        state: { ...this.state },
        browser: {
          userAgent,
          buildID,
          cookieEnabled,
          language,
          maxTouchPoints,
          onLine,
          userAgentData,
          vendor,
          webdriver,
        },
      },
    };
    // if graphql passes along something 500 or above, then there was a network or server error.
    loog('withPageLoading caught a network error: ', undefined, errorPayload);

    return <Error500 trackJsPayload={errorPayload} />;
  }

  render() {
    const {
      component: WrappedComponent,
      withPageLoadingOptions,
      ...filteredProps
    } = this.props;

    const { isLoading, isError, errorData } = this.state;

    if (isLoading) return WithPageLoading.renderLoading();

    if (isError) return this.renderError();

    return (
      <WrappedComponent
        {...filteredProps}
        withPageLoading={{
          errorData,
          isError,
          isLoading,
        }}
      />
    );
  }
}

function withPageLoading(WrappedComponent, options) {
  const hoc = (props) => (
    <WithPageLoading
      {...props}
      component={WrappedComponent}
      withPageLoadingOptions={options}
    />
  );

  hoc.displayName = 'withPageLoading';

  return hoc;
}

function withPageLoadingFactory() {
  return (options) => (Component) => withPageLoading(Component, options);
}

export default withPageLoadingFactory();
