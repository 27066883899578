import gql from 'graphql-tag';

export const schema = `
  connectedSocialMedias
`;

export default gql`
  query getConnectedSocialMediaAccounts {
    getConnectedSocialMediaAccounts {
      ${schema}
    }
  }
`;
