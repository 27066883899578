const isSkuInStockHelper = ({
  backOrderable,
  isBackorderConsideredInStock,
  hasInventory,
}) => Boolean(hasInventory || (isBackorderConsideredInStock && backOrderable));

const reconcileSkuInventory = ({
  products: productSkus = [],
  inventory: inventorySkus = [],
} = {}) => {
  const commands = { remove: [], add: [] };

  // make an object map for the inventory skus.
  // looking up a key in an object map is faster than find() or some other array searching method.
  const inventorySkuMap = {};
  for (let i = 0, max = inventorySkus.length; i < max; i += 1) {
    inventorySkuMap[inventorySkus[i].skuId] = inventorySkus[i];
  }

  // Loop through every product. Find "twins"
  // Also create "add" and "remove" commands.
  //
  // "Twin" means that the inventory api contains info on a sku that also exists
  // in the product api.
  //
  // The product api is updated every 24 hours.  The inventory api is updated
  // every 15 minutes.  Therefore, we go with what is in the inventory api.

  for (let p = 0, max = productSkus.length; p < max; p += 1) {
    const product = productSkus[p];
    const { skuId } = product;
    const twin = inventorySkuMap[skuId];

    if (twin) {
      // if the twin has the same inventory as product, skip to save some cpu cycles.
      if (product.onlineInventoryCount !== twin.onlineInventoryCount) {
        // inventory says this sku is out of stock. We need to remove it from our list of skus
        if (
          !isSkuInStockHelper({
            hasInventory: !!twin.onlineInventoryCount,
            isBackorderConsideredInStock:
              window.FLAGS && window.FLAGS.BackorderConsideredInStock,
            backOrderable: product.backOrderable,
          })
        ) {
          commands.remove.push({ color: product.colorName, id: skuId });
        }
        // inventory says this sku is in stock. Update product's online inventory count.
        if (twin.onlineInventoryCount) {
          commands.add.push({ color: product.colorName, id: skuId });
        }
      }
    } else {
      // if no twin exists, then the product is sold out or has been discontinued
      commands.remove.push({ color: product.colorName, id: skuId });
    }
  }

  return commands;
};

export default reconcileSkuInventory;
