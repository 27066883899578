import gql from 'graphql-tag';

export default gql`
  mutation updateSellerInCart(
    $sellerId: String!
    $sellerName: String!
    $seLookUp: String
  ) {
    updateSellerInCart(
      sellerId: $sellerId
      sellerName: $sellerName
      seLookUp: $seLookUp
    ) {
      sellerInfo {
        sellerId
        sellerName
        sellerImage
      }
    }
  }
`;
