import gql from 'graphql-tag';

export default gql`
  query lineItemsAvailabilities {
    lineItemsAvailabilities {
      sku
      quantity
      isEligible
      isAvailable
      availabilityMessage
    }
  }
`;
