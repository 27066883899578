import React from 'react';
import PropTypes from 'prop-types';
import { Button, Title } from '@express-labs/raven-ui';

import s from './Error500.scss';

const reloadPage = () => {
  window.location.reload(true);
};

const Error500 = ({
  handleTryAgainClick = reloadPage,
  trackJsPayload = false,
}) => {
  if (trackJsPayload && window?.trackJs?.console?.error) {
    window.trackJs.console.error(trackJsPayload);
  }

  return (
    <div className={s.div}>
      <Title tag="h1" className={s.maintext}>
        INTERNAL SERVER ERROR
      </Title>
      <img
        src="https://www.express.com/cdn/responsive/images/pdp-500-error-image.png"
        className="responsive"
        alt="Clothing care tag with iron highlighted"
      />
      <Title tag="h2" className={s.regulartext}>
        We&apos;re working on ironing out this wrinkle.
      </Title>
      <Button href="/clothing/women/sec/womenCategory" className={s.button}>
        SHOP WOMEN&apos;S
      </Button>
      <Button href="/clothing/men/sec/menCategory" className={s.button}>
        SHOP MEN&apos;S
      </Button>
      <Button onClick={handleTryAgainClick} className={s.button}>
        TRY AGAIN
      </Button>
    </div>
  );
};

Error500.propTypes = {
  handleTryAgainClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  trackJsPayload: PropTypes.any,
};

export default Error500;
