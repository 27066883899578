import gql from 'graphql-tag';

export const schema = `
  memberWalletOffers {
    type
    title
    short_description
    short_description_cta
    expiry_date
    description
    legal_text
    long_description
    crm_code
    personalized_offer
    offer_id
    subheading
    featured_offer
    details_image {
      url
    }
    image {
      url
    }
    cta_1 {
      title
      href
    }
    cta_2 {
      title
      href
    }
    mobile_cta_1 {
      title
      href
    }
    mobile_cta_2 {
      title
      href
    }
  }
`;

const query = gql`
  query  memberWallet($loyaltyId: String){
    memberWallet(loyaltyId: $loyaltyId) {
      ${schema}
    }
  }
`;

export default query;
