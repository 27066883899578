import gql from 'graphql-tag';

const query = gql`
  query createApplePaySession {
    createApplePaySession {
      displayName
      domainName
      epochTimestamp
      expiresAt
      merchantIdentifier
      merchantSessionIdentifier
      nonce
      operationalAnalyticsIdentifier
      retries
      signature
    }
  }
`;

export default query;
