import gql from 'graphql-tag';

export default gql`
  query PreferredStoreQuery {
    preferredStore {
      storeId
      storeNumber
      preferredStore
      name
    }
  }
`;
