import { loog } from '@express-labs/raven-tools';
import { GOOGLE_RECAPTCHA_ENTERPRISE_KEY } from '../../constants';

export const ACTION_ADD_TO_BAG = 'ACTION_ADD_TO_BAG';
export const ACTION_GIFT_CARD_BALANCE_FORM = 'ACTION_GIFT_CARD_BALANCE_FORM';
export const ACTION_GO_TO_CHECKOUT = 'GO_TO_CHECKOUT';
export const ACTION_LOGIN_SIGN_IN_BTN = 'ACTION_LOGIN_SIGN_IN_BTN';
export const ACTION_REGISTER_SIGN_IN_BTN = 'ACTION_REGISTER_SIGN_IN_BTN';
export const ACTION_LOGIN_SIGN_IN_ASSOCIATE = 'ACTION_LOGIN_SIGN_IN_ASSOCIATE';
export const ACTION_REGISTER_SIGN_IN_ASSOCIATE =
  'ACTION_REGISTER_SIGN_IN_ASSOCIATE';
export const ACTION_SUBMIT_ORDER = 'ACTION_SUBMIT_ORDER';

const validActions = [
  ACTION_ADD_TO_BAG,
  ACTION_GO_TO_CHECKOUT,
  ACTION_LOGIN_SIGN_IN_BTN,
  ACTION_REGISTER_SIGN_IN_BTN,
  ACTION_GIFT_CARD_BALANCE_FORM,
  ACTION_LOGIN_SIGN_IN_ASSOCIATE,
  ACTION_REGISTER_SIGN_IN_ASSOCIATE,
  ACTION_SUBMIT_ORDER,
];

function validateAction(action) {
  return validActions.includes(action);
}

/**
 * Google ReCaptcha Enterprise Helpers
 */

/**
 * @description Returns a token from Google ReCaptcha Enterprise
 *
 * @param {string} action Name of action to track.
 *
 * @returns {string}
 */

function isRecaptchaEnterpriseError(error, search) {
  if (error) {
    const stringStatus = `${error}`.search(search);
    return stringStatus !== -1;
  }
  return false;
}

function getGoogleReCaptchaEnterpriseToken(action) {
  return new Promise((resolve) => {
    let googleReCaptchaToken = '';
    const grecaptcha = window?.grecaptcha;
    if (
      grecaptcha &&
      GOOGLE_RECAPTCHA_ENTERPRISE_KEY &&
      validateAction(action)
    ) {
      grecaptcha.enterprise.ready(async () => {
        try {
          googleReCaptchaToken = await grecaptcha.enterprise.execute(
            GOOGLE_RECAPTCHA_ENTERPRISE_KEY,
            { action }
          );
          resolve(googleReCaptchaToken);
        } catch (error) {
          loog(
            `Error trying to get a Google ReCaptcha Enterprise token: ${error}`,
            'error'
          );
          resolve('');
        }
      });
    }
    resolve('');
  });
}

const GoogleRecaptchaEnterprise = {
  ACTION_ADD_TO_BAG,
  ACTION_GIFT_CARD_BALANCE_FORM,
  ACTION_LOGIN_SIGN_IN_ASSOCIATE,
  ACTION_REGISTER_SIGN_IN_ASSOCIATE,
  ACTION_LOGIN_SIGN_IN_BTN,
  ACTION_REGISTER_SIGN_IN_BTN,
  ACTION_GO_TO_CHECKOUT,
  ACTION_SUBMIT_ORDER,
  getGoogleReCaptchaEnterpriseToken,
  isRecaptchaEnterpriseError,
};

export default GoogleRecaptchaEnterprise;
