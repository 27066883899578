import gql from 'graphql-tag';

export const schema = `
  modal {
    enable
  title
  body {
    text
    html
    json {
      nodeType
      content {
        value
      }
    }
  }
  }
   benefits {
    enable
  title
  body {
    text
    html
    json {
      nodeType
      content {
        value
      }
    }
  }
  image{
    type
    path
  }
  }
`;

export default gql`
  query getTenderTypePreferredCardProfileModal {
    getTenderTypePreferredCardProfileModal{
    ${schema}
    }
  }
`;
