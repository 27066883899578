import gql from 'graphql-tag';

export const schema = `
getUnbxdCategory(categoryId: $categoryId, start: $start, rows: $rows, filter: $filter, sort: $sort, overrideCatApi: $overrideCatApi, uc_param: $uc_param, queryParams: $queryParams) {
  categoryId
  categoryName
  facets {
    facetId
    name
    position
    values
    valuesWithCount {
      value
      count
    }
  }
  pagination {
    totalProductCount
    pageNumber
    pageSize
    start
    end
  }
  products {
    availabilityStoreIds,
    averageOverallRating
    colors {
      color
      skuUpc
      defaultSku
      defaultSkuUpc
      imageSet
      colorFamily
    }
    EFOProduct
    ensembleListPrice
    ensembleSalePrice
    key
    isEnsemble
    listPrice
    marketplaceProduct
    name
    newProduct
    onlineExclusive
    onlineExclusivePromoMsg
    paginationEnd
    paginationStart
    productDescription
    productId
    productImage
    productURL
    promoArray {
      ensembleItem
      promoMessage
    }
    promoMessage
    salePrice
    totalReviewCount
  }
  requestId
  source
  sortAlgorithm {
    sortParamSource
    algorithm
    otherSortParams
  }
}
`;

const query = gql`
  query CategoryQuery ($categoryId: String, $start: Int!, $rows: Int, $filter: String, $sort: String, $overrideCatApi: String, $uc_param: String, $queryParams: [String]) {
    ${schema}
  }
`;

export default query;
