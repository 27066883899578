import gql from 'graphql-tag';

const schema = `
  isStorePickup,
  lineId,
  eGiftCardInfo{
    recipientFirstName,
    recipientLastName,
    recipientEmail,
    recipientMessage
  },
  giftWrapSelected,
  internationalShippingAvailable,
  internationalShippingDisclaimer,
  itemPromotionDiscount{
    displayAmount,
    specialDiscountMessage
  },
  itemPromotions{
    name
  },
  price{
    displayAmount,
    amount
  }
  product{
    listPrice,
    name,
    productId,
    productImage,
    productSlug,
    productURL,
    promoMessage,
    salePrice,
    sku{
      backOrderable,
      backOrderDate,
      skuId,
      sizeName,
      sizeCode,
      colorCode,
      colorName,
      colorFamilyName,
      displayPrice,
      displayMSRP,
      giftBox,
      sizeExtension1,
      sizeExtension2,
      marketPlaceSku,
      isFinalSale,
      miraklOffer {
        minimumShippingPrice
        sellerName
      }
      originalPrice,
      currentPrice,
      displayMSRP,
      displayPrice,
      bopisEligible,
    }
  },
  quantity,
  fulFillmentMethods {
    shipToHome {
      description
      id
      name
      estimatedDeliveryMessage
    }
    inStorePickup {
      bopisMessage
      storeId
      isEligible
      item {
        sku
        quantity
        availabilityMessage
        isAvailable
        isEligible
      }
    }
  }
`;

export default gql`
  query getLineItems($storeId: String!) {
    lineItems(storeId: $storeId) {
      ${schema}
    }
  }
`;
