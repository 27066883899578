import gql from 'graphql-tag';
import { schema as orderSummarySchema } from './getOrderSummary';

export default gql`
  query getShoppingList($userId: String) {
    getShoppingList(userId: $userId) {
      ${orderSummarySchema}
    }
  }
`;
