import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { createNewState } from '../ravenReduxHelpers';
import sagasManager from '../ravenSagaManager';

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.
const FETCH_CATEGORY = 'demoSaga/GET_CATEGORY';
const SET_CATEGORY = 'demoSaga/SET_CATEGORY';
const FETCH_CATEGORY_ERROR = 'demoSaga/FETCH_CATEGORY_ERROR';

// Initial State
// -------------
const initialState = {
  firstName: '',
};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_CATEGORY:
      return createNewState(state, {
        categoryFetchState: 'fetching',
      });
    case SET_CATEGORY:
      return createNewState(state, {
        data: action.data,
        categoryFetchState: 'success',
      });
    case FETCH_CATEGORY_ERROR:
      return createNewState(state, {
        message: action.message,
        categoryFetchState: 'error',
      });
    default:
      return state;
  }
}

// Action Creators
// ---------------
export const fetchCategory = (categoryId) => ({
  type: FETCH_CATEGORY,
  categoryId,
});
export const setCategory = (data) => ({ type: SET_CATEGORY, data });
export const fetchCategoryError = (message) => ({
  type: FETCH_CATEGORY_ERROR,
  message,
});

// Worker Sagas
// ------------
export function* fetchCategoryAsync(action) {
  // http://www.express.com/api/category/cat3250006
  try {
    const response = yield call(
      axios.get,
      `/api/category/${action.categoryId}`
    );
    yield put(setCategory(response.data));
  } catch (error) {
    yield put(fetchCategoryError(error.message));
  }
}

// Add To Root Saga
// ----------------
sagasManager.addSagaToRoot(function* watcher() {
  yield [takeEvery(FETCH_CATEGORY, fetchCategoryAsync)];
});
