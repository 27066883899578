import React from 'react';
import PropTypes from 'prop-types';
import { parseUrlProductId } from '@express-labs/raven-tools';
import ScrollToTopContext from './ScrollToTopContext';

class ScrollToTop extends React.Component {
  constructor(props) {
    super(props);
    // store previous location
    this.prevLoc = null;
    // store previous scroll location
    this.prevScrollPos = 0;
    // context value
    this.contextValue = {
      scrollTo: this.scrollTo.bind(this),
    };
  }

  static isSameProduct({ newRoute, oldRoute }) {
    const newProductId = parseUrlProductId(newRoute);
    const oldProductId = parseUrlProductId(oldRoute);
    // The product id will exist on the object, but be undefined if not
    // navigating between product routes
    const isProduct = Boolean(newProductId && oldProductId);
    const isSameProduct = newProductId === oldProductId;
    return isProduct && isSameProduct;
  }

  static isRouteChange({ oldRoute, newRoute }) {
    return oldRoute !== newRoute;
  }

  static isWhiteListed({ newRoute }) {
    // should not scroll to top automatically on the following pages...

    // checkout
    if (newRoute.indexOf('/checkout/') !== -1) return true;

    // category pages
    // Because we have product pages' url in this format: https://www.express.com/clothing/women/lace-cami-midi-flounce-dress/pro/07924228/cat550007
    // and category pages in this format: https://www.express.com/womens-clothing/tops/cat430028,
    // Category pages are pages which URL contain 'cat' and not '/pro/'
    if (/\/cat\d+/.test(newRoute) && newRoute.indexOf('/pro/') === -1)
      return true;

    // search pages
    if (newRoute.indexOf('/search') !== -1) return true;

    return false;
  }

  static shouldScrollToTop(routes) {
    return (
      ScrollToTop.isRouteChange(routes) &&
      !ScrollToTop.isSameProduct(routes) &&
      !ScrollToTop.isWhiteListed(routes)
    );
  }

  static getScrollPosition = () => {
    const docScrollTop = document?.documentElement?.scrollTop || 0;
    return window.pageYOffset || docScrollTop;
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({
      key: PropTypes.string,
      pathname: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {};

  componentDidUpdate(prevProps) {
    const oldRoute = prevProps?.location?.pathname || '';
    const newRoute = this.props?.location?.pathname || '';
    this.prevLoc = prevProps?.location;

    // Verify we're not navigating between product pages and that the route pathname has changed
    // In the future, we'll want to not do this when going to a page with the same product id
    if (ScrollToTop.shouldScrollToTop({ newRoute, oldRoute })) {
      window.scrollTo(0, 0);
    }
  }

  shouldComponentUpdate(newProps) {
    // before the component update get the scroll position
    const oldRoute = this.props?.location?.pathname || '';
    const newRoute = newProps?.location?.pathname || '';
    // Only set the scroll position when there is a route change that is
    // to a non white listed page like PDP so when the user hits the back button
    // the page is where it was on the product list
    if (
      ScrollToTop.isRouteChange({ newRoute, oldRoute }) &&
      !ScrollToTop.isSameProduct({ newRoute, oldRoute }) &&
      !ScrollToTop.isWhiteListed({ newRoute })
    ) {
      // Set scroll position before changing location
      this.prevScrollPos = ScrollToTop.getScrollPosition();
    }
    return true;
  }

  scrollTo() {
    const newRoute = this.props?.location?.pathname || '';
    const oldRoute = this.prevLoc?.pathname || '';

    if (
      oldRoute &&
      ScrollToTop.isRouteChange({ newRoute, oldRoute }) &&
      ScrollToTop.isWhiteListed({ newRoute })
    ) {
      // scroll back to correct position
      if (this.prevScrollPos) {
        window.requestAnimationFrame(() =>
          window.scrollTo(0, this.prevScrollPos)
        );
      }
    }
  }

  render() {
    return (
      <ScrollToTopContext.Provider value={this.contextValue}>
        {this.props.children}
      </ScrollToTopContext.Provider>
    );
  }
}
export default ScrollToTop;
