import gql from 'graphql-tag';

export default gql`
  query getGcpPredictionConfigs {
    getGcpPredictionConfigs {
      page
      predictions {
        containerId
        eventType
        predictionId
        servingConfig
        testing
        title
        enabled
        minProducts
      }
    }
  }
`;
