import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import getLocalFitDetails from '../../components/SuggestedSize/helpers/getLocalFitDetails';
import { component } from '../custom-prop-types';

export const getNewHeight = (localFitDetails) =>
  `${
    parseInt(localFitDetails.HEIGHTFEET, 10) * 12 +
    parseInt(localFitDetails.HEIGHTINCHES, 10)
  }`;

export const isThisAPerfectFitObject = (obj) => {
  if (
    typeof obj !== 'undefined' &&
    obj !== null &&
    Object.keys(obj).length > 0
  ) {
    const nonApplicableField = obj.GENDER === 'male' ? 'BRASIZE' : 'JEANWAIST';

    if (
      Object.keys(obj).some((k) => obj[k] === null && k !== nonApplicableField)
    ) {
      return false;
    }

    return true;
  }

  return false;
};

export class SyncWithMyAccount extends React.Component {
  static meta = {
    VERSION: '1.0.0',
  };

  static propTypes = {
    component,
    updateFitDetails: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool,
    getMyAccountPerfectFitDetails: PropTypes.shape({
      AGE: PropTypes.string,
      WEIGHT: PropTypes.string,
      SHOESIZE: PropTypes.string,
      GENDER: PropTypes.string,
      HEIGHT: PropTypes.string,
    }),
    vendorFlags: PropTypes.shape({
      BoldMetrics: PropTypes.bool,
    }).isRequired,
  };

  static defaultProps = {
    component: null,
    getMyAccountPerfectFitDetails: null,
    isLoggedIn: false,
  };

  constructor(props) {
    super(props);
    this.syncFitDetails = this.syncFitDetails.bind(this);
    this.updateMyAccountFitDetails = this.updateMyAccountFitDetails.bind(this);
  }

  componentDidMount() {
    const { getMyAccountPerfectFitDetails, isLoggedIn, vendorFlags } =
      this.props;
    const hasBoldMetrics = vendorFlags.BoldMetrics;

    const localFitDetails = getLocalFitDetails();
    const myAccountFitDetails = getMyAccountPerfectFitDetails;

    // user has to be logged in
    // flag to turn BoldMetrics on/off
    if (isLoggedIn && hasBoldMetrics) {
      this.syncFitDetails(
        localFitDetails,
        myAccountFitDetails,
        window.hasStorage
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { getMyAccountPerfectFitDetails, vendorFlags } = this.props;
    const hasBoldMetrics = vendorFlags.BoldMetrics;

    const localFitDetails = getLocalFitDetails();
    const myAccountFitDetails = getMyAccountPerfectFitDetails;

    // user has to be logged in
    // flag to turn BoldMetrics on/off
    if (!prevProps.isLoggedIn && this.props.isLoggedIn && hasBoldMetrics) {
      this.syncFitDetails(
        localFitDetails,
        myAccountFitDetails,
        window.hasStorage
      );
    }
  }

  updateMyAccountFitDetails(localFitDetails) {
    const { updateFitDetails } = this.props;

    updateFitDetails({
      payload: {
        age: localFitDetails.AGE,
        weight: localFitDetails.WEIGHT,
        shoeSize: localFitDetails.SHOESIZE,
        gender: localFitDetails.GENDER,
        heightFeet: localFitDetails.HEIGHTFEET,
        heightInches: localFitDetails.HEIGHTINCHES,
        jeanWaist: localFitDetails.JEANWAIST ? localFitDetails.JEANWAIST : '',
        braSizeBand: localFitDetails.BRASIZEBAND
          ? localFitDetails.BRASIZEBAND
          : '',
        braSizeCup: localFitDetails.BRASIZECUP
          ? localFitDetails.BRASIZECUP
          : '',
      },
    });
  }

  syncFitDetails(localFitDetails, myAccountFitDetails, hasStorage) {
    // exit function if there is no window.hasStorage
    if (!hasStorage) {
      return null;
    }

    // localFitDetails === true && myAccountFitDetails === false
    if (
      isThisAPerfectFitObject(localFitDetails) &&
      !isThisAPerfectFitObject(myAccountFitDetails)
    ) {
      // update myAccountFitDetails
      return this.updateMyAccountFitDetails(localFitDetails);
    }

    return null;
  }

  render() {
    const { component: WrappedComponent } = this.props;

    return <WrappedComponent {...this.props} />;
  }
}

export const updateFitDetails =
  (mutate) =>
  ({ payload }) =>
    mutate({
      variables: { payload },
    });

export const updateFitDetailsProps = ({ mutate }) => ({
  updateFitDetails: updateFitDetails(mutate),
});

const ConnectedSyncWithMyAccount = graphql(
  gql`
    mutation updateFitDetails($payload: FitDetailsInput!) {
      updateFitDetails(payload: $payload) {
        success
      }
    }
  `,
  { props: updateFitDetailsProps }
)(SyncWithMyAccount);

function syncWithMyAccount(WrappedComponent) {
  const hoc = (props) => (
    <ConnectedSyncWithMyAccount {...props} component={WrappedComponent} />
  );

  hoc.displayName = 'syncWithMyAccount';
  return hoc;
}

function syncWithMyAccountFactory() {
  return () => (Component) => syncWithMyAccount(Component);
}

export default syncWithMyAccountFactory();
