export default function useAnalytics() {
  function log(name, data) {
    if (window.isDebugMode && window.log?.info) {
      window.log.info(`Analytics event ${name} fired.`, data);
    }
  }

  function sendSignal({ name, mergedData }) {
    window.Eva.signal(name, window, mergedData);
  }

  function contactEva({ name, mergedData }) {
    const { Eva } = window;
    if (Eva) {
      Eva.listen('SIGNALReady', sendSignal({ name, mergedData }), document);
    }
  }

  function analyze({ name, props = {}, data = {} }) {
    const mergedData = JSON.parse(JSON.stringify({ ...props, ...data }));
    log(name, mergedData);
    contactEva({ name, mergedData });
  }

  return analyze;
}
