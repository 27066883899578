import gql from 'graphql-tag';

export const schema = `
getUnbxdSearch(searchTerm: $searchTerm, start: $start, rows: $rows, filter: $filter, sort: $sort, queryParams: $queryParams) {
  facets {
    facetId
    name
    position
    values
  }
  didYouMean {
    suggestion
    frequency
  }
  pagination {
    totalProductCount
    pageNumber
    pageSize
    start
    end
  }
  products {
    availabilityStoreIds,
    colors {
      color
      skuUpc
      defaultSku
      defaultSkuUpc
      imageSet
    }
    isEnsemble
    ensembleListPrice
    ensembleSalePrice
    key
    listPrice
    name
    newProduct
    onlineExclusive
    onlineExclusivePromoMsg
    paginationEnd
    paginationStart
    productDescription
    productId
    productImage
    productURL
    promoMessage
    salePrice
    marketplaceProduct
  }
  redirect {
    type
    value
  }
  requestId
  source
}
`;

const query = gql`
  query SearchQuery ($searchTerm: String!, $start: Int!, $rows: Int, $filter: String, $sort: String, $queryParams: [String]) {
    ${schema}
  }
`;

export default query;
