import gql from 'graphql-tag';
import { schema as orderSummarySchema } from './getOrderSummary';

const query = gql`
  query getOrderConfirmation($orderNumber: String!) {
    orderConfirmation(orderNumber: $orderNumber) {
      ${orderSummarySchema}
    }
  }
`;

export default query;
