const getNarvarUrl = (url = window.location.hostname) => {
  if (
    url.includes('local.') ||
    url.includes('localhost:') ||
    url.includes('dev') ||
    url.includes('qa') ||
    url.includes('uat')
  ) {
    return 'https://returns-st01.narvar.qa/express/returns';
  }
  return 'https://returns.narvar.com/express/returns';
};

export default getNarvarUrl;
