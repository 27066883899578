import gql from 'graphql-tag';

const query = gql`
  query getStyliticsOutfits(
    $itemNumber: [String!]
    $tags: String
    $total: Int
  ) {
    getStyliticsOutfits(itemNumber: $itemNumber, tags: $tags, total: $total) {
      id
      large_image_url
      image_url
      onModelImage
      transparent_image_url
      items {
        remote_id
        affiliate_link
        image_url
        large_image_url
        small_image_url
        name
      }
    }
  }
`;

export default query;
