import gql from 'graphql-tag';

const schema = `
  actionButton
  actionStep
  challengeId
  challengeName
  featuredChallenge
  headline
  image
  longDescription
  points
  shortDescription
  openIndicatiorImage
  appActionUrl
  appImage
  appIndicatorImage
`;

const query = gql`
  query getCustomerRewards{
    getCustomerRewards{
      ${schema}
    }
  }
`;

export default query;
