/**
 * @todo Find a way to split the review query out
 * and then we need to build a way to "combine"
 * queries from the separate queries folder;
 */
import gql from 'graphql-tag';

export const schema = `
inventory(id: $productId) {
  skus {
    skuId,
    inStoreInventoryCount,
    inventoryMessage,
    onlineInventoryCount
  }
}
`;

const query = gql`
  query ProductInventoryQuery($productId: String!) {
    ${schema}
  }
`;

export default query;
