import gql from 'graphql-tag';

const query = gql`
  query createKlarnaSession($payload: KlarnaSessionPayloadInput!) {
    klarnaSession(payload: $payload) {
      client_token
      payment_method_categories
    }
  }
`;

export default query;
