import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSelector, useQuery, useLocation } from '../../hooks';
import getGcpPredictionConfigs from '../../queries/getGcpPredictionConfigs';
import { GCP_PREDICTIONS_ENUM, GENDER_ENUM } from '../../constants';
import useCustomerInfo from '../../hooks/useCustomerInfo/useCustomerInfo';

export const GcpPredictionsContext = createContext(null);

/** Helper Functions */
export function getPagePredictionConfig({
  featuredEnabled = false,
  loading = false,
  predictionConfigs = [],
  page = '',
} = {}) {
  return featuredEnabled && !loading && predictionConfigs?.length && page
    ? predictionConfigs.find((item) => item.page === page)
    : null;
}

export function getContainerPredictionConfig({
  pagePredictionConfigs = {},
  containerId = '',
} = {}) {
  if (
    containerId &&
    pagePredictionConfigs &&
    Object.keys(pagePredictionConfigs)
  ) {
    return pagePredictionConfigs.predictions.find(
      (item) => item.containerId === containerId
    );
  }
  return null;
}

export function validatePredictionId(predictionId) {
  return predictionId
    ? !!Object.keys(GCP_PREDICTIONS_ENUM).find(
        (key) => GCP_PREDICTIONS_ENUM[key] === predictionId
      )
    : false;
}

/** Context Function */
function GcpPredictionsProvider(props) {
  const { pathname } = useLocation();
  const [predictionAnalyticsData, setPredictionAnalyticsData] = useState(null);
  /** Redux Data */
  const isGcpRecommendationsEnabled = useSelector(
    (store) => !!store?.vendorReducer?.vendors?.GcpRecommendations
  );

  const gcpRVNPredictionConfigs = useSelector(
    (store) => store.RVNReducer.RVN?.gcpConfig || {}
  );
  const isGcpConfigValid = Object.keys(gcpRVNPredictionConfigs).length > 0;
  const featuredEnabled = isGcpConfigValid && isGcpRecommendationsEnabled;

  const {
    customerLoyaltyId: userId,
    sessionId,
    visitorId,
    gender,
  } = useCustomerInfo();

  /* Retrieve the data regarding the GCP Predictions from GQL.
   */
  const {
    loading: isLoadingGcpPredictionConfigs,
    data: gcpPredictionConfigsData,
  } = useQuery(getGcpPredictionConfigs, {
    skip: !featuredEnabled,
    fetchPolicy: 'cache-first',
  });

  /** DATA MAPPING FROM getGcpPredictionConfigs QUERY */
  const loading = isLoadingGcpPredictionConfigs;
  const gcpPredictionConfigs = useMemo(
    () =>
      !isLoadingGcpPredictionConfigs
        ? gcpPredictionConfigsData?.getGcpPredictionConfigs || []
        : [],
    [
      gcpPredictionConfigsData?.getGcpPredictionConfigs,
      isLoadingGcpPredictionConfigs,
    ]
  );

  const getPredictionConfig = useCallback(
    ({ page = '', containerId = '' }) => {
      const pagePredictionConfigs = getPagePredictionConfig({
        featuredEnabled,
        predictionConfigs: gcpPredictionConfigs,
        loading,
        page,
      });

      if (!pagePredictionConfigs) return {};

      const containerPredictionConfig = getContainerPredictionConfig({
        pagePredictionConfigs,
        containerId,
      });

      try {
        if (validatePredictionId(containerPredictionConfig?.predictionId)) {
          const { enabled: enbledFromConfig = false, testing = true } =
            containerPredictionConfig;
          const enabled = testing
            ? gcpRVNPredictionConfigs?.[page]?.[
                containerPredictionConfig.predictionId
              ] || false
            : enbledFromConfig;

          return {
            ...containerPredictionConfig,
            enabled,
          };
        }
      } catch (error) {
        return null;
      }
      return null;
    },
    [featuredEnabled, gcpPredictionConfigs, gcpRVNPredictionConfigs, loading]
  );

  const setPredictionDataToTrack = useCallback(
    ({
      recsAlgorithm = '',
      recsLocation = '',
      recsResource = '',
      recsReferring = '',
    }) => {
      setPredictionAnalyticsData({
        recsAlgorithm,
        recsLocation,
        recsResource,
        recsReferring,
      });
    },
    []
  );

  const resetPredictionDataToTrack = useCallback(() => {
    setPredictionAnalyticsData(null);
  }, []);

  const getGenderFilter = useCallback(
    (overrideGender = '', bagProductsCount = 0) => {
      try {
        if (!gender && !overrideGender) return '';
        let genderFiltersKey = '';
        if (gender && !overrideGender) {
          genderFiltersKey =
            bagProductsCount === 0
              ? (genderFiltersKey = Object.keys(GENDER_ENUM).filter(
                  (key) =>
                    key.toLocaleLowerCase() === gender.toLocaleLowerCase()
                ))
              : '';
        } else {
          genderFiltersKey = overrideGender;
        }

        const genderFilters = GENDER_ENUM[genderFiltersKey[0].toUpperCase()];
        const customFilter = genderFilters?.length
          ? genderFilters
              .map((filter) => `(genders: ANY("${filter}"))`)
              .join(' OR ')
          : '';
        return customFilter ? `(${customFilter})` : '';
      } catch (e) {
        return '';
      }
    },
    [gender]
  );

  const productIdMatch = pathname.match(/pro\/([^/]+)/);
  const recsReferring = productIdMatch
    ? productIdMatch && productIdMatch[1]
    : '';

  const gcpPredictionsData = useMemo(
    () => ({
      getGenderFilter,
      getPredictionConfig,
      isGcpRecommendationsEnabled: featuredEnabled,
      loading,
      predictionAnalyticsData,
      recsReferring,
      resetPredictionDataToTrack,
      sessionId,
      setPredictionDataToTrack,
      userId,
      visitorId,
    }),
    [
      featuredEnabled,
      getGenderFilter,
      getPredictionConfig,
      loading,
      predictionAnalyticsData,
      recsReferring,
      resetPredictionDataToTrack,
      sessionId,
      setPredictionDataToTrack,
      userId,
      visitorId,
    ]
  );

  return (
    <GcpPredictionsContext.Provider value={gcpPredictionsData} {...props} />
  );
}

export const useGcpPredictionsContext = () => useContext(GcpPredictionsContext);

export default GcpPredictionsProvider;
