import React, { createContext, useMemo, useContext } from 'react';
import { useQuery } from '../../../hooks';
import { creditCardDiscount } from '../../../constants';
import getCreditCardDiscount from '../../../queries/getCreditCardDiscount';

export const CreditCardContext = createContext(null);

const creditCardDiscountConstant = creditCardDiscount;

function CreditCardProvider(props) {
  const { loading: isloadingCreditCardData, data: creditCardData } = useQuery(
    getCreditCardDiscount,
    {
      fetchPolicy: 'cache-first',
    }
  );
  // fetch from raven-server if raven-server unavailable then import from constant
  const creditCardDiscountPercentage = useMemo(
    () => ({
      percentage: !isloadingCreditCardData
        ? creditCardData?.getCreditCardDiscount?.creditCardDiscount ||
          creditCardDiscountConstant
        : creditCardDiscountConstant,
    }),
    [creditCardData, isloadingCreditCardData]
  );

  return (
    <CreditCardContext.Provider
      value={creditCardDiscountPercentage}
      {...props}
    />
  );
}

export const useCreditCardContext = () => useContext(CreditCardContext);
export default CreditCardProvider;
