import {
  getAkamaiStorage,
  getIsLoggedInCookie,
  getPath,
} from '@express-labs/raven-tools';
import { createNewState } from '../ravenReduxHelpers';

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.
const SET_POSTAL_CODE = 'user/SET_POSTAL_CODE';
const SET_PREFERRED_STORE = 'user/SET_PREFERRED_STORE';

// Initial State
// -------------
const localStoreInfo = getAkamaiStorage();

const initialState = {
  isLoggedIn: getIsLoggedInCookie(),
  latitude: getPath(localStoreInfo, 'latitude'),
  longitude: getPath(localStoreInfo, 'longitude'),
  postalCode: getPath(localStoreInfo, 'postalCode'),
  preferredStore: null,
};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  const { type, ...filteredAction } = action;

  switch (action.type) {
    case SET_POSTAL_CODE:
      return createNewState(state, { ...filteredAction });
    case SET_PREFERRED_STORE:
      return createNewState(state, { ...filteredAction });
    default:
      return state;
  }
}

// Action Creators
// ---------------
export const setPostalCode = (postalCode) => ({
  type: SET_POSTAL_CODE,
  postalCode,
});
export const setPreferredStore = (preferredStore) => ({
  type: SET_PREFERRED_STORE,
  preferredStore,
});
