import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@express-labs/raven-ui';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { connect, compose, errorBoundary } from '../hocs';
import Error500 from '../components/Error500';
import ScrollToTop from './ScrollToTop';
import HandleCustomRouteEvent from './HandleCustomRouteEvent';
import handleLoading from './helpers/handleLoading';
import GcpPredictionsProvider from './GcpPredictionsProvider';
import CreditCardProvider from './Providers/CreditCardProvider';
import { MODE_404_SEO } from '../constants';

let EgifterCallback;
let egifterRoute;
if (ROUTE_ALL || ROUTE_EGIFTER) {
  EgifterCallback = lazy(() =>
    import(/* webpackChunkName: "egifter" */ '../pages/EgifterCallback')
  );
  egifterRoute = {
    path: '/bag/egifter-callback',
    component: (ownProps) => (
      <Suspense fallback={null}>
        <EgifterCallback {...ownProps} />
      </Suspense>
    ),
  };
}

let Bag;
let bagPageRoute;
if (ROUTE_ALL || ROUTE_BAGPAGE) {
  Bag = lazy(() => import(/* webpackChunkName: "bag" */ '../pages/Bag'));
  bagPageRoute = {
    path: '/bag',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="pdp" />)}>
        <Bag />
      </Suspense>
    ),
  };
}

let ReferFriend;
let referFriendRoute;
if (ROUTE_ALL || ROUTE_REFERFRIEND) {
  ReferFriend = lazy(() =>
    import(/* webpackChunkName: "bag" */ '../pages/ReferFriend')
  );
  referFriendRoute = {
    path: '/referafriend',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <ReferFriend />
      </Suspense>
    ),
  };
}

let Checkout;
let checkoutPageRoute;
if (ROUTE_ALL || ROUTE_CHECKOUT) {
  Checkout = lazy(() =>
    import(/* webpackChunkName: "bag" */ '../pages/Checkout')
  );
  checkoutPageRoute = {
    path: '/checkout',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="pdp" />)}>
        <Checkout />
      </Suspense>
    ),
  };
}

let CheckoutII;
let checkoutIIPageRoute;
if (ROUTE_ALL || ROUTE_CHECKOUTII) {
  CheckoutII = lazy(() =>
    import(/* webpackChunkName: "bag" */ '../pages/Checkout/CheckoutVM')
  );
  checkoutIIPageRoute = {
    path: '/checkout',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="pdp" />)}>
        <CheckoutII />
      </Suspense>
    ),
  };
}

let ResetPassword;
let resetPasswordRoute;
if (ROUTE_ALL || ROUTE_RESETPASSWORD) {
  ResetPassword = lazy(() =>
    import(/* webpackChunkName: "reset-password" */ '../pages/ResetPassword')
  );
  resetPasswordRoute = {
    path: '/reset-password',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <ResetPassword />
      </Suspense>
    ),
  };
}

// Associates pages
let LoginAssociate;
let loginAssociateRoute;
if (ROUTE_ALL || ROUTE_LOGINASSOCIATE) {
  LoginAssociate = lazy(() =>
    import(
      /* webpackChunkName: "login-associate" */ '../pages/AssociateAccount/pages/LoginAssociate'
    )
  );
  loginAssociateRoute = {
    exact: true,
    path: '/login/associate',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <LoginAssociate />
      </Suspense>
    ),
  };
}

let RegisterAssociate;
let registerAssociateRoute;
if (ROUTE_ALL || ROUTE_REGISTERASSOCIATE) {
  RegisterAssociate = lazy(() =>
    import(
      /* webpackChunkName: "register-associate" */ '../pages/AssociateAccount/pages/RegisterAssociate'
    )
  );
  registerAssociateRoute = {
    exact: true,
    path: '/register/associate',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <RegisterAssociate />
      </Suspense>
    ),
  };
}

let SellerLogin;
let sellerLoginRoute;
if (ROUTE_ALL || ROUTE_SELLERLOGIN) {
  SellerLogin = lazy(() =>
    import(
      /* webpackChunkName: "seller-login" */ '../pages/AssociateAccount/pages/SellerLogin'
    )
  );
  sellerLoginRoute = {
    exact: true,
    path: '/login/seller',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <SellerLogin />
      </Suspense>
    ),
  };
}

let RegisterSeller;
let registerSellerRoute;
if (ROUTE_ALL || ROUTE_REGISTERSELLER) {
  RegisterSeller = lazy(() =>
    import(
      /* webpackChunkName: "register-seller" */ '../pages/AssociateAccount/pages/RegisterSeller'
    )
  );
  registerSellerRoute = {
    exact: true,
    path: '/register/seller',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <RegisterSeller />
      </Suspense>
    ),
  };
}

let SellerError;
let sellerErrorRoute;
if (ROUTE_ALL || ROUTE_SELLERERROR) {
  SellerError = lazy(() =>
    import(
      /* webpackChunkName: "register-seller" */ '../pages/AssociateAccount/pages/SellerErrorPage'
    )
  );
  sellerErrorRoute = {
    exact: true,
    path: ['/register/seller', '/login/seller'],
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <SellerError />
      </Suspense>
    ),
  };
}

let Login;
let loginRoutes;
if (ROUTE_ALL || ROUTE_LOGIN) {
  const loginPaths = ['/login', '/login/:redirectUrl'];
  Login = lazy(() =>
    import(/* webpackChunkName: "reset-password" */ '../pages/Login')
  );
  loginRoutes = loginPaths.map((route) => ({
    path: route,
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <Login />
      </Suspense>
    ),
  }));
}

let Logout;
let logoutRoute;
if (ROUTE_ALL || ROUTE_LOGOUT) {
  Logout = lazy(() =>
    import(/* webpackChunkName: "logout" */ '../pages/Logout')
  );
  logoutRoute = {
    path: '/logout',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <Logout />
      </Suspense>
    ),
  };
}

let Register;
let registerRoute;
if (ROUTE_ALL || ROUTE_REGISTER) {
  Register = lazy(() =>
    import(/* webpackChunkName: "reset-password" */ '../pages/Register')
  );
  registerRoute = {
    path: '/register',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <Register />
      </Suspense>
    ),
  };
}

let ReviewsRoute;
let reviewsRoute;
if (ROUTE_ALL || ROUTE_REVIEWS) {
  ReviewsRoute = lazy(() =>
    import(/* webpackChunkName: "reviews" */ './ReviewsRoute')
  );
  reviewsRoute = {
    path: '/*/reviews/:sort?/:page?',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <ReviewsRoute />
      </Suspense>
    ),
  };
}

let OrderConfirmation;
let orderConfirmationPageRoute;
if (ROUTE_ALL || ROUTE_ORDERCONFIRMATION) {
  OrderConfirmation = lazy(() =>
    import(
      /* webpackChunkName: "order-confirmation" */ '../pages/OrderConfirmation'
    )
  );
  orderConfirmationPageRoute = {
    path: '/checkout/order-confirmation/:orderNumber?',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <OrderConfirmation />
      </Suspense>
    ),
  };
}

let MyAccount;
let myAccountPageRoute;
if (ROUTE_ALL || ROUTE_MYACCOUNT) {
  MyAccount = lazy(() =>
    import(/* webpackChunkName: "my-account" */ '../pages/MyAccount')
  );
  myAccountPageRoute = {
    path: '/my-account',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <MyAccount />
      </Suspense>
    ),
  };
}

let ActivateGiftCard;
let activateGiftCardRoute;
if (ROUTE_ALL || ROUTE_ACTIVATEGIFTCARD) {
  ActivateGiftCard = lazy(() =>
    import(
      /* webpackChunkName: "activate-gift-card" */ '../pages/ActivateGiftCard'
    )
  );
  activateGiftCardRoute = {
    path: '/gift-cards/activate',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <ActivateGiftCard />
      </Suspense>
    ),
  };
}

let StylingPersonalInfo;
let stylistPersonalInfoRoute;
if (ROUTE_ALL || ROUTE_STYLINGPERSONALINFO) {
  StylingPersonalInfo = lazy(() =>
    import(
      /* webpackChunkName: "stylist-personal-infoRoute" */ '../pages/StylingPersonalInfo'
    )
  );
  stylistPersonalInfoRoute = {
    path: '/stylist-appointment/info',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <StylingPersonalInfo />
      </Suspense>
    ),
  };
}

let ProductRouteNormalizer;
let productRoute;
if (ROUTE_ALL || ROUTE_PRODUCT || ROUTE_PDP) {
  ProductRouteNormalizer = lazy(() =>
    import(/* webpackChunkName: "product" */ './ProductRouteNormalizer')
  );
  productRoute = {
    path: '/(.*)pro/:productId',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="pdp" />)}>
        <ProductRouteNormalizer />
      </Suspense>
    ),
  };
}

// required route
const Error404 = lazy(() =>
  import(/* webpackChunkName: "404" */ '../pages/404')
);
export const errorRoute = {
  path: '*',
  component: (ownProps) => (
    <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
      <Error404 status={MODE_404_SEO} />
    </Suspense>
  ),
};

let CreditCardApp;
let creditCardAppRoute;
if (ROUTE_ALL || ROUTE_CREDITCARDAPP) {
  CreditCardApp = lazy(() =>
    import(
      /* webpackChunkName: "credit-card-application" */ '../pages/CreditCardApp'
    )
  );
  creditCardAppRoute = {
    path: '/credit-card-application',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="pdp" />)}>
        <CreditCardApp />
      </Suspense>
    ),
  };
}

let ProductReviewWrapper;
let productReviewRoute;
if (ROUTE_ALL || ROUTE_PRODUCTREVIEW) {
  ProductReviewWrapper = lazy(() =>
    import(/* webpackChunkName: "product-review" */ '../pages/ProductReview')
  );
  productReviewRoute = {
    path: '/product-review',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <ProductReviewWrapper />
      </Suspense>
    ),
  };
}

let BazaarVoiceReviewWrapper;
let bazaarVoiceReviewRoute;
if (ROUTE_ALL || ROUTE_BAZAARVOICEREVIEW) {
  BazaarVoiceReviewWrapper = lazy(() =>
    import(
      /* webpackChunkName: "bazaar-voice-review" */ '../components/BazaarVoiceReviews'
    )
  );
  bazaarVoiceReviewRoute = {
    path: [
      '/product-review',
      '/(.*)pro/:productId/(.*)?/reviews/:sort?/:page',
      '/(.*)pro/:productId/(.*)?/reviews',
    ],
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <BazaarVoiceReviewWrapper isFullPage />
      </Suspense>
    ),
  };
}

// Order the category path array from more params to fewer params.  React router picks first match
// in the array. For filter and sort to work you must place the more specific routes first.
export const categoryPath = [
  '/*/:categoryId(cat\\d+)/sort/:sort/filter/:filter/page/:page',
  '/*/:categoryId(cat\\d+)/sort/:sort/filter/:filter',
  '/*/:categoryId(cat\\d+)/filter/:filter/sort/:sort/page/:page',
  '/*/:categoryId(cat\\d+)/filter/:filter/sort/:sort',
  '/*/:categoryId(cat\\d+)/sort/:sort/page/:page',
  '/*/:categoryId(cat\\d+)/sort/:sort',
  '/*/:categoryId(cat\\d+)/filter/:filter/page/:page',
  '/*/:categoryId(cat\\d+)/filter/:filter',
  '/*/:categoryId(cat\\d+)/page/:page',
  '/*/:categoryId(cat\\d+)',
];

let Category;
let categoryPageRoute;
if (ROUTE_ALL || ROUTE_CATEGORYPAGE || ROUTE_PLP) {
  Category = lazy(() =>
    import(/* webpackChunkName: "category" */ '../pages/Category')
  );
  categoryPageRoute = {
    path: categoryPath,
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <Category mode="category" />
      </Suspense>
    ),
  };
}

export const searchPath = [
  '/*/search/filter/:filter/sort/:sort',
  '/*/search/sort/:sort/filter/:filter',
  '/*/search/filter/:filter',
  '/*/search/sort/:sort',
  '/*/search',
];

let Search;
let searchPageRoute;
if (ROUTE_ALL || ROUTE_SEARCHPAGE) {
  Search = lazy(() =>
    import(/* webpackChunkName: "category" */ '../pages/Category')
  );
  searchPageRoute = {
    // path: '/(.*)cat_TBD/:categoryId',
    // order these from more params to fewer params.  router picks first match in the array.
    path: searchPath,
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <Search mode="search" />
      </Suspense>
    ),
  };
}

let GiftcardPageRoute;
let giftcardPageRoute;
if (ROUTE_ALL || ROUTE_GIFTCARDPAGE) {
  GiftcardPageRoute = lazy(() =>
    import(/* webpackChunkName: "gift-card" */ '../pages/GiftCard')
  );
  giftcardPageRoute = {
    path: '/giftcard',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <GiftcardPageRoute />
      </Suspense>
    ),
  };
}

let SizeChartWrapper;
let sizeChartWrapper;
if (ROUTE_ALL || ROUTE_SIZECHART) {
  SizeChartWrapper = lazy(() =>
    import(/* webpackChunkName: "size-chart" */ '../pages/SizeChartWrapper')
  );
  sizeChartWrapper = {
    path: '/size-chart/:gender?',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <SizeChartWrapper match={ownProps.match} />
      </Suspense>
    ),
  };
}

let developerRoute;

// The constant, PRODUCTION is inserted by webpack at compile time.
// It is only set to true when doing `npm run build`, 'npm run stats', or `npm run deploy`
// see  webpack.config.js/tasks/common.js
if (
  (ROUTE_ALL && PRODUCTION === false) ||
  (ROUTE_DEV && PRODUCTION === false)
) {
  // NOTE:  webpackChunkName is important! It tells webpack where to store a route's javascript.
  // Do not use a webpackChunkName that is used in production routes!!!
  const DeveloperRoutes = lazy(() =>
    import(/* webpackChunkName: "developer" */ '../developer/DeveloperRoutes')
  );
  developerRoute = {
    path: '/developer',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <DeveloperRoutes />
      </Suspense>
    ),
  };
}

let EmailPreferencesPageRoute;
let emailPreferencesPageRoute;
if (ROUTE_ALL || ROUTE_EMAILPREFERENCES) {
  EmailPreferencesPageRoute = lazy(() =>
    import(
      /* webpackChunkName: "email-preferences" */ '../pages/EmailPreferences'
    )
  );
  emailPreferencesPageRoute = {
    path: '/email-preferences',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <EmailPreferencesPageRoute />
      </Suspense>
    ),
  };
}

let EmailSignupPage;
let emailSignupPageRoute;
if (ROUTE_ALL || ROUTE_EMAILSIGNUP) {
  EmailSignupPage = lazy(() =>
    import(/* webpackChunkName: "email-signup" */ '../pages/EmailSignup')
  );
  emailSignupPageRoute = {
    path: '/email/subscribe',
    component: (ownProps) => (
      <Suspense fallback={handleLoading(ownProps, <Skeleton page="empty" />)}>
        <EmailSignupPage />
      </Suspense>
    ),
  };
}

const Routes = ({
  isBazaarVoiceV2Enabled = false,
  isEnhancedCheckoutFormEnabled = false,
  isHAGiftCardEnabled = false,
  isSellerPagesEnabled = false,
  isSoComEnabled = false,
  isStylistsEnabled = false,
}) => (
  <Router>
    <GcpPredictionsProvider>
      <CreditCardProvider>
        <ScrollToTop>
          <HandleCustomRouteEvent>
            <Switch>
              {egifterRoute && <Route {...egifterRoute} />}
              {bagPageRoute && <Route {...bagPageRoute} />}
              {resetPasswordRoute && <Route {...resetPasswordRoute} />}
              {orderConfirmationPageRoute && (
                <Route {...orderConfirmationPageRoute} />
              )}
              {isEnhancedCheckoutFormEnabled
                ? checkoutIIPageRoute && <Route {...checkoutIIPageRoute} />
                : checkoutPageRoute && <Route {...checkoutPageRoute} />}
              {isBazaarVoiceV2Enabled
                ? bazaarVoiceReviewRoute && (
                    <Route {...bazaarVoiceReviewRoute} />
                  )
                : reviewsRoute && <Route {...reviewsRoute} />}
              {reviewsRoute && <Route {...reviewsRoute} />}
              {loginAssociateRoute && <Route {...loginAssociateRoute} />}
              {registerAssociateRoute && <Route {...registerAssociateRoute} />}
              {isSoComEnabled && isSellerPagesEnabled
                ? sellerLoginRoute && <Route {...sellerLoginRoute} />
                : sellerErrorRoute && <Route {...sellerErrorRoute} />}
              {isSoComEnabled && isSellerPagesEnabled
                ? registerSellerRoute && <Route {...registerSellerRoute} />
                : sellerErrorRoute && <Route {...sellerErrorRoute} />}
              {registerRoute && <Route {...registerRoute} />}
              {logoutRoute && <Route {...logoutRoute} />}
              {myAccountPageRoute && <Route {...myAccountPageRoute} />}
              {productRoute && <Route {...productRoute} />}
              {loginRoutes &&
                loginRoutes.map((route) => (
                  <Route key={route.path} {...route} />
                ))}
              {isHAGiftCardEnabled && activateGiftCardRoute && (
                <Route {...activateGiftCardRoute} />
              )}
              {isStylistsEnabled && stylistPersonalInfoRoute && (
                <Route {...stylistPersonalInfoRoute} />
              )}
              {creditCardAppRoute && <Route {...creditCardAppRoute} />}
              {categoryPageRoute && <Route {...categoryPageRoute} />}
              {searchPageRoute && <Route {...searchPageRoute} />}
              {giftcardPageRoute && <Route {...giftcardPageRoute} />}
              {sizeChartWrapper && <Route {...sizeChartWrapper} />}
              {isBazaarVoiceV2Enabled
                ? bazaarVoiceReviewRoute && (
                    <Route {...bazaarVoiceReviewRoute} />
                  )
                : productReviewRoute && <Route {...productReviewRoute} />}
              {referFriendRoute && <Route {...referFriendRoute} />}
              {PRODUCTION === false && developerRoute && (
                <Route {...developerRoute} />
              )}
              {emailPreferencesPageRoute && (
                <Route {...emailPreferencesPageRoute} />
              )}
              {emailSignupPageRoute && <Route {...emailSignupPageRoute} />}
              <Route {...errorRoute} />
            </Switch>
          </HandleCustomRouteEvent>
        </ScrollToTop>
      </CreditCardProvider>
    </GcpPredictionsProvider>
  </Router>
);

Routes.propTypes = {
  isBazaarVoiceV2Enabled: PropTypes.bool,
  isHAGiftCardEnabled: PropTypes.bool,
  isSoComEnabled: PropTypes.bool,
  isStylistsEnabled: PropTypes.bool,
  isSellerPagesEnabled: PropTypes.bool,
  isEnhancedCheckoutFormEnabled: PropTypes.bool,
};

export default compose(
  errorBoundary({ errorView: <Error500 /> }),
  connect((state) => ({
    isBazaarVoiceV2Enabled: !!state?.vendorReducer?.vendors?.BazaarVoiceV2,
    isHAGiftCardEnabled: state.vendorReducer.vendors.GiftCardActivation,
    isSoComEnabled: state.vendorReducer.vendors.SoCom,
    isStylistsEnabled: state.vendorReducer.vendors.StylistAppointments,
    isSellerPagesEnabled: state.vendorReducer.vendors.SellerPages,
    isEnhancedCheckoutFormEnabled:
      state.vendorReducer.vendors.EnhancedCheckoutForm,
  }))
)(Routes);
