import { createNewState } from '../ravenReduxHelpers';

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.
const UPDATE_CAMPAIGNS = 'campaign/UPDATE_CAMPAIGNS';
const INIT_CAMPAIGNS = 'campaign/INIT_CAMPAIGNS';

// Initial State
// -------------
const initialState = {
  campaigns: null,
  loading: true,
};

const validateInitialCampaign = (state, { campaigns, loading }) => {
  const newData = {};

  if (typeof loading === 'boolean') newData.loading = loading;

  if (typeof campaigns !== 'undefined' && Object.keys(campaigns).length) {
    newData.campaigns = campaigns;
  }

  return Object.keys(newData).length > 0 ? newData : state;
};

const formatCampaignData = (oldState, { name, experience }) => {
  const newCampaigns = {};

  if (oldState.campaigns) {
    Object.assign(newCampaigns, oldState.campaigns);
  }

  if (typeof name === 'string' && typeof experience !== 'undefined') {
    Object.assign(newCampaigns, { [name]: experience });
  }

  return Object.keys(newCampaigns).length > 0 ? newCampaigns : null;
};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  const { type, ...filteredAction } = action;

  switch (type) {
    case UPDATE_CAMPAIGNS:
      return createNewState(state, {
        campaigns: formatCampaignData(state, filteredAction),
      });
    case INIT_CAMPAIGNS:
      return createNewState(
        state,
        validateInitialCampaign(state, { ...filteredAction.campaignData })
      );
    default:
      return state;
  }
}

// Action Creators
// ---------------
export const initCampaigns = (campaignData) => ({
  type: INIT_CAMPAIGNS,
  campaignData,
});
export const updateCampaigns = ({ name, experience }) => ({
  type: UPDATE_CAMPAIGNS,
  name,
  experience,
});
