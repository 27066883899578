import gql from 'graphql-tag';

export default gql`
  query OrderDetailQuery($orderNumber: String!) {
    orderDetails(orderNumber: $orderNumber) {
      externalOrderNumber
      isExchange
      isReturn
      returnEligible
      orderBarCode
      formattedTotalDiscounts
      formattedTotalTaxes
      formattedItemSubTotal
      formattedGrandTotal
      paymentDetails {
        paymentMethod
        cardType
        accountDisplayNumber
        formattedAuthorizationAmount
        formattedRefundedAmount
      }
      orderLines {
        itemId
        itemStyle
        orderLineNumber
        orderLineStatus
        isReturnable
        orderedQuantity
        itemDescription
        imageURL
        itemSize
        itemColor
        unitPriceAmount
        formattedLineDiscountAmount
        returnEligible
        lockerBarCode
        trackingNumber
        trackingWebURL
        marketPlaceData {
          sellerId
          sellerName
          sellerPhone
          sellerEmail
        }
        shippingInfo {
          shipViaDescription
          deliveryOption
          firstName
          lastName
          addressLine1
          addressLine2
          city
          stateProv
          postalCode
        }
        taxDetails {
          extTaxDetailId
          taxCategory
          chargeCategory
          taxName
          taxAmount
          formattedTaxAmount
          taxableAmount
          taxDTTM
          deleted
          taxType
        }
      }
      returnsAndExchanges {
        itemCount
        items
        submittedDate
        trackingUrl
        trackingNumber
        storeReturn
        returnLabelPrintURL
      }
    }
  }
`;
