import gql from 'graphql-tag';

export const getRecommendationsSchema = `
  productId,
  productImage,
  productURL,
  name,
  listPrice,
  salePrice,
`;

const query = gql`
  query getRecommendations($id: String!, $type: String){
    recommendations(id: $id, type: $type) {
      ${getRecommendationsSchema}
    }
  }
`;

export default query;
