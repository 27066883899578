import React from 'react';
import Error500 from '../../components/Error500';

export default function handleLoading(ownProps, loading) {
  if (ownProps.error) {
    // PRODUCTION is inserted by webpack at compile time
    if (window.isDebugMode || PRODUCTION === false) {
      window.log.error(ownProps.error);
    }
    return (
      <Error500
        trackJsPayload={{
          note: 'handleLoading.js encountered an error',
          error: ownProps.error,
        }}
      />
    );
  }
  return loading;
}
