import gql from 'graphql-tag';

export const schema = `
customerActedOnENCCAsPreferredModal
customer{
  customerInfo{
    aList,
    associateLogin,
    sellerId,
    associateStatus,
    associateNumber,
    birthDayReward,
    bopisEligibleStatus,
    country,
    emailAddress,
    firstName,
    fitDetails {
      AGE,
      WEIGHT,
      SHOESIZE,
      GENDER,
      HEIGHT,
      JEANWAIST,
      BRASIZE,
    },
    gender,
    id,
    externalId,
    nextTermsAndConditionsAccepted,
    lastName,
    loginStatus,
    loyaltyNumber,
    loyaltyStatus,
    pendingRewardsCount,
    phoneNumber,
    pointsBalance,
    pointsToNextTier,
    pointsToRetainCurrentTier,
    pointsTowardsAListStatus,
    postalCode,
    preferredStore,
    referralCount,
    referralLink,
    referralMaxCount,
    rewardAmountInCentsForAListReward,
    rewardAmountInCentsForNextReward,
    rewardCount,
    rewards{
      rewardId,
      currency,
      amount,
      displayAmount,
      dateIssued,
      expirationDate,
      shortDescription,
      expirationDays,
    }
    rewardsTotal,
    tierName,
    tierExpirationDate,
    tierExpirationDays,
    totalPointsForAListReward,
    totalPointsForNextReward,
    nextCreditCardHolder,
    memberRewardChoice{
      catalogEndDate,
      catalogStartDate,
      currencyToEarn,
      displayName,
      ipCode,
      rewardId,
    },
    memberRewardChoiceInDollars,
  }
}
challengeList{
  customerChallenges{
    challengeId,
    challengeName,
    headline,
  }
}
pointsHistoryResponse{
  pointsHistoryList{
    dateTime
    eventName
    earnedPoints
  }
}
customerStore{
  storeId,
  storeNumber,
  name,
  addressLine1,
  city,
  distance,
  state,
  postalCode,
  country,
  phoneNumber,
  hoursOfOperation,
  weeklyHoursOfOperation{
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday
  }
  bopisEligible,
}
`;

const query = gql`
  query customerInfo {
    customer {
      ${schema}
    }
  }
`;

export default query;
