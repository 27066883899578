import React from 'react';
import PropTypes from 'prop-types';
import PdpUrlNormalizer from '../../sharedClasses/PdpUrlNormalizer';
import { component } from '../custom-prop-types';

export class WithPDPURL extends React.Component {
  static computeParams(ownProps) {
    const { mapParams } = ownProps;
    let params = {};
    if (typeof mapParams === 'function') {
      params = mapParams(ownProps);
    }
    if (typeof mapParams === 'object') {
      params = mapParams;
    }
    if (Object.keys(params).length < 1) {
      params = false;
    }
    return params;
  }

  static propTypes = {
    // The withPDPURL HOC must wrap a component
    component,

    // withInventory() hoc is required
    withInventory: PropTypes.shape({
      product: PropTypes.shape({}).isRequired,
    }).isRequired,

    // for the "mapParams" prop you can:
    // - pass in match.params from withRouter() hoc or...
    // - pass in withRouterPDP.params from withRouterPDP() hoc or...
    // - pass in a function that is given all the current props in the prop stream as an argument
    //   and returns an object that will be used as params.
    // - manually set params yourself by passing in an object of your choosing.
    mapParams: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func])
      .isRequired,

    // history.replace prop is inserted by withRouter() hoc and is required,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    component: null,
  };

  constructor(props) {
    super(props);

    if (!props.withInventory)
      throw new Error(
        'withPDPURL requires withInventory to be in the prop stream before this hoc. Missing withInventory prop.'
      );
    if (!WithPDPURL.computeParams(props))
      throw new Error(
        'withPDPURL requires a configuration option called mapParams that maps props in the prop stream to route params.'
      );

    this.go = this.go.bind(this);
    this.getNewPath = this.getNewPath.bind(this);
  }

  /**
   * Creates a new path string.  The new path is passed through the PdpUrlNormalizer to sanity check
   * and untaint url segments for this product. Any invalid newPathParams are changed to defaults
   * or removed.  The new params are then merged with the existing path params from withRouter.
   *
   * All of the newPathParams properties are optional.
   *
   * Provide only the properties that you wish to change.  For instance, if a person clicks on a
   * size option button on the PDP, we only need to change the size value in the URL.  If a
   * person click on a color swatch, we only need to change the color portion of the URL.
   *
   * If no options are provided, it simply returns a normalized version of the current URL.
   *
   * You can use the new path returned by this method as the "to" property for a react-router or
   * raven-ui Link component.
   *
   * There is no need to URL encode or decode any strings passed to newPathParams.  React router
   * takes care of this for us.
   *
   * @param  {Object}  newPathParams            An object containing new url segment values.
   * @param  {String}  newPathParams.beforePro  The stuff before /pro/  not needed on PDP pages.
   * @param  {String}  newPathParams.color      The name of a color. e.g. "WILD GINGER"
   * @param  {String}  newPathParams.ext        The name of a size extension. e.g. "regular", "tall"
   * @param  {String}  newPathParams.inseam     The length of an inseam. e.g. "31"
   * @param  {String}  newPathParams.model      The current model to show
   * @param  {String}  newPathParams.productId  A product id. e.g. "06344737"
   * @param  {String}  newPathParams.size       A size or waist mesurment. e.g. "L", "M", "S", "32"
   * @param  {Boolean} includeHashandSearch     If true, append any existing ?thing=what and #anchor
   * @return {String}                           A new path. e.g
   *     /clothing/women/express-one-eleven-slash-neck-cold-shoulder-tee/pro/06344737/c/PALE%20PINK
   */
  getNewPath(newPathParams, includeHashandSearch = true) {
    const params = {
      ...WithPDPURL.computeParams(this.props),
      ...newPathParams,
    };
    const product = this.props.withInventory.product[params.productId];
    const newUrl = PdpUrlNormalizer.normalize(params, product);
    const { search, hash } = window.location;
    const retval = includeHashandSearch ? `${newUrl}${search}${hash}` : newUrl;
    return retval;
  }

  /**
   * Safely change the PDP URL and mutate the browser history by replacing the last URL in the
   * browser's history with the new url.  This lets us update the PDP URL when the user presses
   * a new size selection option, or color swatch, and prevents the user from having to press the
   * back button multiple times to get back to the previous page.
   *
   * note: go REPLACES the current url in the browser's history stack.
   *
   * Example of use:
   * As a shopper, I want to see the current product in a different color.
   * go({ color: 'WILD GINGER'})
   *
   * As a shopper, I want to see the current product in tall sizes.
   * go({ ext: 'tall' })
   *
   * As a shopper, I want to see only model two in the carousel.
   * go({ model: '2'})
   *
   * @param  {Object} newPathParams            An object containing new url segment values.
   * @param  {String} newPathParams.color      The name of a color. e.g. "WILD GINGER"
   * @param  {String} newPathParams.ext        The name of a size extension. e.g. "regular", "tall"
   * @param  {String} newPathParams.inseam     The length of an inseam. e.g. "31"
   * @param  {String} newPathParams.model      The current model to show
   * @param  {String} newPathParams.productId  A product id. e.g. "06344737"
   * @param  {String} newPathParams.size       A size or waist mesurment. e.g. "L", "M", "S", "32"
   * @return {undefined}
   */
  go(newPathParams) {
    this.props.history.replace(this.getNewPath(newPathParams));
  }

  render() {
    const { component: WrappedComponent, ...filteredProps } = this.props;

    return (
      <WrappedComponent
        {...filteredProps}
        withPDPURL={{
          getNewPath: this.getNewPath,
          go: this.go,
        }}
      />
    );
  }
}

function withPDPURL(WrappedComponent, config) {
  const { mapParams = {} } = config;
  const hoc = (props) => (
    <WithPDPURL {...props} component={WrappedComponent} mapParams={mapParams} />
  );

  hoc.displayName = 'withPDPURL';

  return hoc;
}

function withPDPURLFactory() {
  return (config) => (Component) => withPDPURL(Component, config);
}

export default withPDPURLFactory();
