import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { getCustomer as getCustomerQuery } from '../../queries';

export function sanitizeGSSession(gsSession) {
  return gsSession ? gsSession.replace(/['"]+/g, '') : '';
}

export default ({ isUpdateOnHeartbeat = true } = {}) => {
  if (isUpdateOnHeartbeat && (window?.Heartbeat?.on ?? false) === false) {
    throw new Error(
      'useCustomerInfo failed to subscribe to Heartbeat.  Heartbeat.on() is undefined.'
    );
  }

  // Make a hopefully random number. (timestamp * random digit)
  const [uniqueId] = useState(Math.floor(Math.random() * Date.now()));
  const [visitorId, setVisitorId] = useState('');

  const [getCustomer, { data, error, loading }] =
    useLazyQuery(getCustomerQuery);

  const handleVisitorIdIsAvailable = useCallback(() => {
    setVisitorId(window?.RVN?.googleRetailVisitorId || '');
  }, []);

  useEffect(() => {
    if (!visitorId) {
      const googleRetailVisitorId = window?.RVN?.googleRetailVisitorId;
      if (!googleRetailVisitorId) {
        window.addEventListener(
          'retail-visitor-id-is-available',
          handleVisitorIdIsAvailable
        );
      } else {
        setVisitorId(googleRetailVisitorId);
      }
    }
    return () => {
      window.removeEventListener(
        'retail-visitor-id-is-available',
        handleVisitorIdIsAvailable
      );
    };
  }, [handleVisitorIdIsAvailable, visitorId]);

  useEffect(() => {
    getCustomer();

    /* istanbul ignore else */
    if (isUpdateOnHeartbeat) {
      window.Heartbeat.on('beat', `${uniqueId}`, getCustomer);
    }

    return () => {
      window?.Heartbeat?.off('beat', `${uniqueId}`);
    };
  }, [getCustomer, isUpdateOnHeartbeat, uniqueId]);

  const {
    firstName,
    gender,
    id: customerId,
    lastName,
  } = data?.customer?.customer?.customerInfo ?? {};
  const { name: storeName, storeId } = data?.customer?.customerStore ?? {};
  const {
    referralLink,
    referralCount,
    referralMaxCount,
    nextCreditCardHolder,
  } = data?.customer?.customer?.customerInfo ?? {};

  const user = {
    customer: data?.customer?.customer?.customerInfo ?? {},
    customerStore: data?.customer?.customerStore ?? {},
    isLoading: loading,
  };

  const gsSession = sessionStorage.getItem('tt_sessionId');
  const cleanGsSession = sanitizeGSSession(gsSession);
  const expCustId = localStorage.getItem('expCustId') || '';
  const customerLoyaltyData = JSON.parse(
    localStorage.getItem('loyaltyWarmStateData')
  );
  const customerLoyaltyId = customerLoyaltyData?.loyaltyNumber || '';
  const sessionId = cleanGsSession || expCustId;

  // YOU MUST ONLY RETURN SCALAR VALUES.  Do not embed objects into this response.
  // only exception allowed is the error object.
  const returnVal = {
    customerId,
    customerLoyaltyId,
    error,
    expCustId,
    firstName,
    gender,
    lastName,
    loading: loading || visitorId === '',
    referralCount,
    referralLink,
    referralMaxCount,
    sessionId,
    storeId,
    storeName,
    nextCreditCardHolder,
    user,
    visitorId,
  };

  return returnVal;
};
