import { getPath } from '@express-labs/raven-tools';

/**
 * pageLevelAfterWithInventoryHelper
 *
 * The withInventory HOC creates loading and error props that are shared with other HOC's
 * downstream.  This "helper" provides common configuration options to withPageLoading() to
 * reduce duplicated code.
 *
 * How to use this helper:
 * - the target component is a Page container.
 * - withInventory() is the first HOC.
 * - withPageLoading() is the second HOC, immediately after withInventory()
 * - supply pageLevelAfterWithInventoryHelper as the sole argument for withPageLoading()
 *
 * Example
 * import { pageLevelAfterWithInventoryHelper } from '[ relative path to helper ]';
 * import { compose } from '../../hocs';
 *
 * compose(
 *   withInventory(...)
 *   withPageLoading({ custom: [pageLevelAfterWithInventoryHelper] }),
 *   [ other HOCs ]
 * )(MyTestPage)
 *
 * @type {Object}   Insert this object into withPageLoading() "custom" array option.
 */
export default {
  isError: (ownProps) =>
    !!getPath(ownProps, 'withInventory.isInventoryError.length', 0),
  isLoading: (ownProps) => {
    const { isInventoryLoading, queue } = ownProps.withInventory;
    return !!isInventoryLoading.length || !!queue.length;
  },
  isSuccess: (ownProps) =>
    getPath(ownProps, 'withInventory.isInventorySuccess.length', 0) === 2,
  errorData: (ownProps) =>
    ownProps.withInventory.inventoryErrors[ownProps.productId],
};
