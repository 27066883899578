import React from 'react';
import gql from 'graphql-tag';
import PropType from 'prop-types';
import { ApolloClient } from 'apollo-client';
import { COUNTRIES, US_STATES } from '../../constants';

export default class Demographics extends React.Component {
  static propTypes = {
    client: PropType.instanceOf(ApolloClient).isRequired,
    countries: PropType.arrayOf(
      PropType.shape({
        value: PropType.string,
        displayValue: PropType.string,
      })
    ),
    countryCode: PropType.string,
    provinces: PropType.arrayOf(
      PropType.shape({
        value: PropType.string,
        displayValue: PropType.string,
      })
    ),
    component: PropType.oneOfType([PropType.node, PropType.elementType]),
  };

  static defaultProps = {
    countryCode: 'US',
    countries: COUNTRIES,
    provinces: US_STATES,
    component: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      countries: props.countries,
      countryCode: props.countryCode,
      provinces: props.provinces,
    };

    this.onChangeCountry = this.onChangeCountry.bind(this);
  }

  onChangeCountry(ev) {
    const countryCode = ev.target.value;
    return this.getProvinces(countryCode).then((response) => {
      this.setState({
        provinces: response,
        countryCode,
      });
    });
  }

  // TODO: Maybe move this to index
  /* istanbul ignore next */
  getProvinces(countryCode) {
    const { client } = this.props;
    return new Promise((resolve, reject) => {
      if (countryCode === 'US') resolve(US_STATES);
      client
        .query({
          query: gql`
            query getProvinces($countryCode: String!) {
              provinces(countryCode: $countryCode) {
                value: code
                displayValue: value
              }
            }
          `,
          variables: { countryCode },
        })
        .then((response) => resolve(response.data.provinces))
        .catch((error) => reject(error));
    });
  }

  render() {
    const {
      component: Component,
      countryCode,
      countries,
      provinces,
      ...filteredProps
    } = this.props;
    const demographics = {
      countries: this.state.countries,
      countryCode: this.state.countryCode,
      onChangeCountry: this.onChangeCountry,
      provinces: this.state.provinces,
    };

    return <Component {...filteredProps} demographics={demographics} />;
  }
}
