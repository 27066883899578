import { doEncryptLocal } from '@express-labs/raven-tools';
import { createNewState } from '../ravenReduxHelpers';

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one occasion to clear a form, the best practice is to namespace the
// constant values.  Programmatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.
const HEARTBEAT_BEAT = 'customer/HEARTBEAT_BEAT';
const UPDATE_HEARTBEAT_DATA = 'customer/UPDATE_HEARTBEAT_DATA';
const RESET_HEARTBEAT_DATA = 'customer/RESET_HEARTBEAT_DATA';
const SET_SESSION_DATA = 'customer/SET_SESSION_DATA';

const defaultValues = {
  anon: 'true',
  count: 0,
  data: null,
  uid: null,
  id: null,
};

const getInitialState = () => {
  let appState = { ...defaultValues };

  if (window.Heartbeat) {
    const { anon, id, items, uid } = window.Heartbeat.getStorage();

    appState = {
      anon,
      id,
      items,
      uid,
    };

    const sessionData =
      window.hasStorage !== false ? sessionStorage.getItem('hbSession') : null;

    if (sessionData) {
      try {
        const [ct, iv, salt] = sessionData.split('|');

        const data = doEncryptLocal.doDecrypt(
          `${anon ? 'A' : 'L'}${uid}`,
          `{"iv":"${iv}","v":1,"iter":10000,"ks":128,"ts":64,"mode":"ccm","adata":"","cipher":"aes","salt":"${salt}","ct":"${ct}"}`
        );

        const json = JSON.parse(data);

        if (json) appState.data = json;
      } catch (e) {
        if (window.isDebugMode)
          window.log.error('Error parsing Heartbeat session data.');
        if (window.hasStorage !== false) sessionStorage.removeItem('hbSession');
      }
    }
  }

  return appState;
};

const initialState = getInitialState();

const beat = () => {
  if (window.Heartbeat) {
    window.Heartbeat.beat();
  }
};

const onBeat = (oldState, nextState) => {
  if (oldState.id !== nextState.id) {
    const { stamp, ...filteredData } = nextState;
    return createNewState(oldState, { ...filteredData });
  }

  return oldState;
};

const createCustomerData = (customerData) => {
  const newData = {};

  Object.keys(customerData).forEach((key) => {
    const data = customerData[key];

    if ((!data && data !== false) || key === '__typename') return;
    newData[key] =
      key === 'customerActedOnENCCAsPreferredModal' ? data : { ...data };
  });

  return newData;
};

const getNewSessionData = (currentState, newSessionData) => {
  const newData = createCustomerData(newSessionData.userData);

  const { ct, iv, salt } = JSON.parse(
    doEncryptLocal.doEncrypt(
      `${currentState.anon ? 'A' : 'L'}${currentState.uid}`,
      JSON.stringify(newData)
    )
  );

  if (window.hasStorage !== false)
    sessionStorage.setItem('hbSession', `${ct}|${iv}|${salt}`);
  // send event with data so that header can update the customer data in the courtesy navigation
  window.Eva.signal('updateHeaderCustomerData', window, newData);
  return { data: newData };
};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  const { type, ...filteredAction } = action;

  switch (action.type) {
    case HEARTBEAT_BEAT:
      beat();
      return state;
    case UPDATE_HEARTBEAT_DATA:
      return onBeat(state, filteredAction.storage);
    case RESET_HEARTBEAT_DATA:
      return createNewState(state, { ...filteredAction });
    case SET_SESSION_DATA:
      return createNewState(
        state,
        getNewSessionData(state, { ...filteredAction })
      );
    default:
      return state;
  }
}

// Action Creators
// ---------------
export const beatHeartbeat = () => ({ type: HEARTBEAT_BEAT });
export const updateHeartbeatData = (storage) => ({
  type: UPDATE_HEARTBEAT_DATA,
  storage,
});
export const resetHeartbeatData = () => ({
  type: RESET_HEARTBEAT_DATA,
  defaultValues,
});
export const setSessionData = (userData) => ({
  type: SET_SESSION_DATA,
  userData,
});
