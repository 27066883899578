import gql from 'graphql-tag';

export default gql`
  query getBopisNearbyStores {
    getBopisNearbyStores {
      distance
      name
      storeId
    }
  }
`;
