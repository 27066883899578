import gql from 'graphql-tag';

const query = gql`
  query validateResetPasswordKey($key: String!, $value: String!) {
    validateResetPasswordKey(key: $key, value: $value) {
      valid
      email
      code
    }
  }
`;

export default query;
