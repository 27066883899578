import React from 'react';
import PropTypes from 'prop-types';

const ScrollToTopContext = React.createContext();

ScrollToTopContext.Provider.propTypes = {
  scrollTo: PropTypes.func,
};

export default ScrollToTopContext;
