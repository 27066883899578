import gql from 'graphql-tag';

const query = gql`
  query SizeRecQuery(
    $age: String
    $braSize: String
    $email: String!
    $garmentId: String!
    $height: String!
    $preferredWaist: String
    $shoeSize: Int
    $weight: String!
  ) {
    sizeRecommendations(
      age: $age
      braSize: $braSize
      email: $email
      garmentId: $garmentId
      height: $height
      preferredWaist: $preferredWaist
      shoeSize: $shoeSize
      weight: $weight
    ) {
      outlier_status
      size_recommendations {
        good_matches {
          fit_percentage
          garment {
            size
          }
        }
      }
    }
  }
`;

export default query;
