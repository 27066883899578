import { createNewState } from '../ravenReduxHelpers';

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.
const TOGGLE_MODAL = 'perfectFit/TOGGLE_MODAL';
const TOGGLE_ERROR_MODAL = 'perfectFit/TOGGLE_ERROR_MODAL';
const SET_FIT_FORM_COMPLETE = 'perfectFit/SET_FIT_FORM_COMPLETE';
const RESET_FIT_FORM_COMPLETE = 'perfectFit/RESET_FIT_FORM_COMPLETE';
const SET_BOLD_METRICS_TO_FORM_STEP =
  'perfectFit/SET_BOLD_METRICS_TO_FORM_STEP';
const SET_BOLD_METRICS_TO_RESULTS_STEP =
  'perfectFit/SET_BOLD_METRICS_TO_RESULTS_STEP';
const SET_ERROR_MESSAGE = 'perfectFit/SET_ERROR_MESSAGE';
const RESET_ERROR_MESSAGE = 'perfectFit/RESET_ERROR_MESSAGE';
const SET_IS_OUTLIER = 'perfectFit/SET_IS_OUTLIER';
const RESET_IS_OUTLIER = 'perfectFit/RESET_IS_OUTLIER';

// Initial State
// -------------
const initialState = {
  isModalOpen: false,
  isErrorModalOpen: false,
  fitFormSubmitted: false,
  isResultFormOpen: false,
  boldMetricsModalStep: 'form',
  displayErrorMessage: false,
  isOutlier: false,
};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return createNewState(state, { isModalOpen: !state.isModalOpen });
    case TOGGLE_ERROR_MODAL:
      return createNewState(state, {
        isErrorModalOpen: !state.isErrorModalOpen,
      });
    case SET_FIT_FORM_COMPLETE:
      return createNewState(state, { fitFormSubmitted: true });
    case RESET_FIT_FORM_COMPLETE:
      return createNewState(state, { fitFormSubmitted: false });
    case SET_BOLD_METRICS_TO_FORM_STEP:
      return createNewState(state, { boldMetricsModalStep: 'form' });
    case SET_BOLD_METRICS_TO_RESULTS_STEP:
      return createNewState(state, { boldMetricsModalStep: 'results' });
    case SET_ERROR_MESSAGE:
      return createNewState(state, { displayErrorMessage: true });
    case RESET_ERROR_MESSAGE:
      return createNewState(state, { displayErrorMessage: false });
    case SET_IS_OUTLIER:
      return createNewState(state, { isOutlier: true });
    case RESET_IS_OUTLIER:
      return createNewState(state, { isOutlier: false });
    default:
      return state;
  }
}

// Action Creators
// ---------------
export const toggleModal = () => ({ type: TOGGLE_MODAL });
export const toggleErrorModal = () => ({ type: TOGGLE_ERROR_MODAL });
export const setFitFormComplete = () => ({ type: SET_FIT_FORM_COMPLETE });
export const resetFitFormComplete = () => ({ type: RESET_FIT_FORM_COMPLETE });
export const setBoldMetricsFormStep = () => ({
  type: SET_BOLD_METRICS_TO_FORM_STEP,
});
export const setBoldMetricsResultsStep = () => ({
  type: SET_BOLD_METRICS_TO_RESULTS_STEP,
});
export const setErrorMessage = () => ({ type: SET_ERROR_MESSAGE });
export const resetErrorMessage = () => ({ type: RESET_ERROR_MESSAGE });
export const setIsOutlier = () => ({ type: SET_IS_OUTLIER });
export const resetIsOutlier = () => ({ type: RESET_IS_OUTLIER });
