import { createNewState } from '../ravenReduxHelpers';

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.
export const UPDATE_DIMENSIONS = 'deviceWidth/UPDATE_DIMENSIONS';
export const UPDATE_SCROLL = 'deviceWidth/UPDATE_SCROLL';

// These are "min width" breakpoints. The min-width of MD is 768px.  That infers the max-width of
// SM is 767px. If your page, container, or component needs a custom breakpoint DO NOT ALTER THESE.
// Do not add a new breakpoint here.  Instead, use the "width" property in your component's
// view model.  ex:   `const isMyCustomBreakpoint = width >= 123`
export const BREAKPOINTS = {
  XS: 0, // 0 to 479
  SM: 480, // 480 to 767
  MD: 768, // 768 to 991
  LG: 992, // etc...
  XL: 1200,
  XXL: 1600,
};

// Action Creators
// ---------------
export const updateDimensions = () => {
  const dims = {
    scrollTop: document.documentElement.scrollTop || document.body.scrollTop,
  };

  const offsets = { Height: 'height', Width: 'width' };

  // very helpful:  https://ryanve.com/lab/dimensions/
  Object.keys(offsets).forEach((key) => {
    dims[offsets[key]] = Math.max(
      document.documentElement[`client${key}`],
      window[`inner${key}`],
      0
    );
  });

  const { width } = dims;

  // breakPoint is a number so that you can easily tell if prevBreakPoint is smaller, the same, or
  // larger than the current value of breakPoint.  People could resize the window really fast, or
  // turn a device sideways and skip a few breakpoints instantly.
  let breakPoint = 0; // the default is xxsm

  // these breakpoints are mobile first, and come directly from the RavenUI grid system
  if (width >= 1600) breakPoint = BREAKPOINTS.XXL; // xxlg
  else if (width >= 1200) breakPoint = BREAKPOINTS.XL; // xlg
  else if (width >= 992) breakPoint = BREAKPOINTS.LG; // lg
  else if (width >= 768) breakPoint = BREAKPOINTS.MD; // md
  else if (width >= 480) breakPoint = BREAKPOINTS.SM; // sm

  return {
    type: UPDATE_DIMENSIONS,
    ...dims,
    breakPoint,
    isMobileLayout: breakPoint < BREAKPOINTS.SM,
    isTabletLayout:
      breakPoint >= BREAKPOINTS.SM && breakPoint <= BREAKPOINTS.MD,
    isDesktopLayout: breakPoint > BREAKPOINTS.MD,
  };
};

export const updateScrollTop = () => ({
  type: UPDATE_SCROLL,
  scrollTop: document.documentElement.scrollTop || document.body.scrollTop,
});

// Initial State
// -------------
const initialDims = updateDimensions();
const { type, ...filteredDims } = initialDims;
const initialState = {
  width: null,
  height: null,
  scrollTop: null,
  prevWidth: null,
  prevHeight: null,
  prevScrollTop: null,
  breakPoint: null,
  ...filteredDims,
};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  const { typeAction, ...filteredAction } = action;
  switch (action.type) {
    case UPDATE_DIMENSIONS:
      return createNewState(state, {
        ...filteredAction,
        prevWidth: state.width,
        prevHeight: state.height,
        prevScrollTop: state.scrollTop,
        prevBreakPoint: state.breakPoint,
      });
    case UPDATE_SCROLL:
      return createNewState(state, {
        ...filteredAction,
        prevScrollTop: state.scrollTop,
      });
    default:
      return state;
  }
}
