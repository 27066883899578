import gql from 'graphql-tag';

const schema = `
  bopisMessage
  storeId
  addressLine1
  addressLine2
  bopisEligible
  bopisAvailabilityMessage
  bopisMessage
  city
  country
  distance
  hoursOfOperation
  items {
    sku
    quantity
    availabilityMessage
    isAvailable
    isEligible
  }
  skuAvailability {
    sku
    quantity
    availabilityMessage
    isAvailable
    isEligible
  }
  name
  phoneNumber
  postalCode
  state
  storeId
  storeNumber
  weeklyHoursOfOperation {
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
  }
`;

export default gql`
  query getItemsAvailabilities($zipcode: String!, $store: String, $pdpItem: String) {
    itemsAvailabilities(zipcode: $zipcode, store: $store, pdpItem: $pdpItem) {
      ${schema}
    }
  }
`;
