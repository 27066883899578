import { fork, all } from 'redux-saga/effects';

let sagas = [];

/**
 * Helper class that alows us to compose the root saga in each of our ducks.
 */
export default class SagaManager {
  static addSagaToRoot(...arrayOfSagas) {
    sagas.push([...arrayOfSagas]);
  }

  static getRootSaga() {
    return function* rootSaga() {
      yield all(sagas.map((saga) => fork(...saga)));
    };
  }

  static clearRootSaga() {
    sagas = [];
  }

  static getSagas() {
    return sagas;
  }
}
