import React from 'react';
import PropTypes from 'prop-types';

export default function WithFormAccessibility(WrappedComponent) {
  return class WithFormAccessibility extends React.Component {
    constructor(props) {
      super(props);
      this.proc = this.proc.bind(this);
    }

    static propTypes = {
      submitting: PropTypes.bool,
    };

    static defaultProps = {
      submitting: null,
    };

    componentDidMount() {
      this.errorFocusFieldRef = this.containerRef.querySelector('input');
      if (this.errorFocusFieldRef) {
        this.errorFocusFieldRef.focus();
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.submitting) {
        this.errorFocusFieldRef = this.containerRef.querySelector('.has-error');
        if (this.errorFocusFieldRef) {
          this.errorFocusFieldRef.focus();
        }
      }
    }

    proc(wrappedComponentInstance) {
      this.containerRef = wrappedComponentInstance;
    }

    render() {
      return (
        <div ref={this.proc}>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };
}
