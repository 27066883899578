import gql from 'graphql-tag';

const schema = `
  pointsHistoryList {
    dateTime
    earnedPoints
    eventName
  }
`;

const query = gql`
  query getPointsHistory{
    getPointsHistory{
      ${schema}
    }
  }
`;

export default query;
