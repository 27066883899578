import gql from 'graphql-tag';
import React from 'react';
import { compose } from 'redux';
import { withApollo, graphql } from '@apollo/react-hoc';

import WithMelissaData from './withMelissaData';

const DataComponent = compose(
  withApollo,
  graphql(
    gql`
      mutation getMelissaData($address: MelissaAddress!) {
        getMelissaData(address: $address) {
          Records {
            PremisesNumber
            Thoroughfare
            SubPremises
            Locality
            AdministrativeArea
            PostalCode
            CountryISO3166_1_Alpha2
          }
        }
      }
    `,
    {
      props({ mutate }) {
        return {
          getMelissaData({ address }) {
            return mutate({
              variables: { address },
            });
          },
        };
      },
    }
  )
)(WithMelissaData);

export default (WrappedComponent) =>
  function WithMelissaData(props) {
    return <DataComponent {...props} component={WrappedComponent} />;
  };
