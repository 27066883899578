import gql from 'graphql-tag';

export const schema = `
getCategoryContent(categoryId: $categoryId, limit: $limit, offset: $offset, orderBy: $orderBy, queryParams: $queryParams) {
  carouselSummary {
    desktop {
      headlineText
      cards {
        id
        type
        imageUrl
        imagePath
        imageAltText
        ctaText
        ctaUrl
      }
    }
    mobile {
      headlineText
      cards {
        id
        type
        imageUrl
        imagePath
        imageAltText
        ctaText
        ctaUrl
      }
    }
  }
  categorySummary {
    allowShopMyStore
    canonicalLink
    categoryId
    colorDisplayDesktop
    colorDisplayMobile
    enablePagination
    expressViewSuppress
    headerTitle
    hybridCategoryPage
    includedFiltersMap {
      filterKey
      placeholder
      defaultLabel
    }
    isMixedDataSource
    itemsPerPage
    metaDescription
    navTitle
    overrideCatApi
    priceSuppress
    shopMySize
    robotsTag
    seoDescription
    seoTitle
    segmentedCategoryViewType
  }
  inlineContentSummary {
    mobile {
      componentName
      placement
      props {
        col
        row
        imageUrl
        imagePath
        videoPath
        videoWithAudio
        imageAltText
        headlineText
        cta {
          alignment
          links {
            ctaText
            ctaUrl
            ctaType
          }
        }
        galleryTag
        ctaText
        itemsShown
      }
    }
    desktop {
      componentName
      placement
      newPlacement
      props {
        col
        row
        imageUrl
        imagePath
        videoPath
        videoWithAudio
        imageAltText
        imageAltText
        headlineText
        cta {
          alignment
          links {
            ctaText
            ctaUrl
            ctaType
          }
        }
        galleryTag
        ctaText
        itemsShown
      }
    }
  }
  leftNavSummary {
    topCategoryTitle
    navDetails {
      title
      url
      showCaret
      hideInApp
    }
  }
  visualNavigationSummary {
    visualNavTitle
    description
    firstCard {
      image
      imageAlt
      title
      description
      links {
        linkText
        linkUrl
        linkTextColor
      }
      sizes {
        size1
        size2
        size3
        size4
      }
    }
    filterDetails {
      filterTitle
      iconImage
      filterOption {
        title
        description
        filterId
        filterValue
        filterValueItems {
          filterId
          filterValue
        }
        size1 {
          image
          imageAlt
          mobileImage
          imageUrl
        }
        size2 {
          image
          imageAlt
          mobileImage
          imageUrl
          videoPath
          videoWithAudio
        }
        size3 {
          image
          imageAlt
          mobileImage
          imageUrl
          videoPath
          videoWithAudio
        }
        size4 {
          image
          imageAlt
          mobileImage
          imageUrl
          videoPath
          videoWithAudio
        }
        image
        imageAlt
        imageUrl
        mobileImage
        videoPath
        videoWithAudio
      }
    }
  }
  BreadcrumbSummary
  segmentedCategorySummary {
    subcategoryData {
      subcategoryId
      bannerVideo
      videoWithAudio
      bannerImagePath
      mobileBannerImagePath
      bannerText
      bannerTextColor
      bannerImageAlt
      bannerLink
      carouselProductLimit
      gridDesktopProductLimit
      gridMobileProductLimit
      ctaText
      ctaLink
      overrideCatApi
    }
    moreItemsData {
      showMoreItems
      imagePath
      mobileImagePath
      imageAlt
      bannerLink
      bannerText
      categoryId
      overrideCatApi
    }
  }
  segmentedCategories {
    category {
      categoryId
      categoryName
      facets {
        facetId
        name
        position
        values
      }
      pagination {
        totalProductCount
        pageNumber
        pageSize
        start
        end
      }
      products {
        averageOverallRating
        availabilityStoreIds
        colors {
          color
          skuUpc
          defaultSku
          defaultSkuUpc
          imageSet
        }
        EFOProduct
        ensembleListPrice
        ensembleSalePrice
        key
        isEnsemble
        listPrice
        marketplaceProduct
        name
        newProduct
        onlineExclusive
        onlineExclusivePromoMsg
        paginationEnd
        paginationStart
        productDescription
        productId
        productImage
        productURL
        promoMessage
        salePrice
        totalReviewCount
      }
      source
    }
    metadata {
      subcategoryId
      bannerImagePath
      bannerVideo
      videoWithAudio
      bannerText
      bannerTextColor
      mobileBannerImagePath
      bannerImageAlt
      bannerLink
      carouselProductLimit
      gridDesktopProductLimit
      gridMobileProductLimit
      ctaText
      ctaLink
      overrideCatApi
      bannerSize {
        desktop {
          width
          height
        }
        mobile {
          width
          height
        }
      }
      inlineContent {
        mobile {
          componentName
          placement
          props {
            col
            row
            imageUrl
            imagePath
            videoPath
            videoWithAudio
            imageAltText
            headlineText
            cta {
              alignment
              links {
                ctaText
                ctaUrl
                ctaType
              }
            }
            galleryTag
            ctaText
            itemsShown
          }
        }
        desktop {
          componentName
          placement
          props {
            col
            row
            imageUrl
            imagePath
            videoPath
            videoWithAudio
            imageAltText
            imageAltText
            headlineText
            cta {
              alignment
              links {
                ctaText
                ctaUrl
                ctaType
              }
            }
            galleryTag
            ctaText
            itemsShown
          }
        }
      }
    }
  }
  recommendationsContentSummary {
    mobile {
      componentName
      placement
      props {
        title
      }
    }
    desktop {
      componentName
      placement
      props {
        title
      }
    }
  }
}
`;

const query = gql`
  query getCategoryContent ($categoryId: String, $limit: Int, $offset: Int, $orderBy: SegmentedCategoriesOrderBy, $queryParams: [String]) {
    ${schema}
  }
`;

export default query;
