import gql from 'graphql-tag';

export default gql`
  query getProvinces($countryCode: String!) {
    provinces(countryCode: $countryCode) {
      value: code
      displayValue: value
    }
  }
`;
