import gql from 'graphql-tag';

const query = gql`
  query getKlarnaAuthPayload {
    KlarnaAuthPayload {
      payload
    }
  }
`;

export default query;
