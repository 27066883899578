export default class DepartmentIdManager {
  /**
   * Gets a department id from the product image url.
   * @param  {string} productImage
   * @return {Boolean}
   */
  static shouldShowSizeRecommended(productImage) {
    let departmentId = 0;
    const productImageUrl = productImage && productImage.split('/');
    if (productImageUrl && productImageUrl.length) {
      departmentId = [
        productImageUrl[productImageUrl.length - 1].split('?')[0].split('_')[0],
      ];
    }
    return (
      departmentId &&
      !(
        [95, 6, 8, 34, 9, 4, 28, 999, 5].indexOf(
          parseInt(departmentId[0], 10)
        ) > -1
      )
    );
  }

  /**
   * Gets a department id from the product gender and customer gender url.
   * @param  {string} productGender
   * @param  {string} customerGender
   * @return {Boolean}
   */
  static customerGenderEqualsProductGender(productGender, customerGender) {
    const userGender =
      customerGender && (customerGender === 'male' ? 'men' : 'women');
    return userGender === productGender;
  }
}
