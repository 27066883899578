import gql from 'graphql-tag';

const schema = `
  actionButton
  actionStep
  challengeId
  challengeName
  complete
  featuredChallenge
  headline
  image
  longDescription
  points
  shortDescription
  openIndicatiorImage
  appActionUrl
  appImage
  appIndicatorImage
  optInChallenge
`;

const query = gql`
  query getCustomerChallenges{
    getCustomerChallenges{
      ${schema}     
    }
  }
`;

export default query;
