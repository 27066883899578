import gql from 'graphql-tag';

export const schema = `
  sku
  quantity
  availabilityMessage
  isAvailable
  isEligible
`;

const query = gql`
  query itemAvailability($items: [ItemAndQuantity]!, $storeId: String!) {
    itemAvailability(items: $items, storeId: $storeId) {
      ${schema}
    }
  }
`;

export default query;
