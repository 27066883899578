import ArrayMatrix from 'javascript-array-matrix';

import ColorManager from '../ColorManager';
import reconcileSkuInventory from '../reconcileSkuInventory';

export default class Matrix {
  colorManager = {};

  inventoryData = {};

  matrixCES = {};

  matrixCEI = {};

  matrixCESI = {};

  productData = {};

  /**
   * [constructor description]
   * @param {Object} args An object containing the arguments
   * @param {Object} args.inventoryData graphql response data from getInventory query. Required.
   * @param {Object} args.productData graphql response data from getProduct query. Required.
   * @param {Object} args.match react-router match object.
   * @param {String} args.productId The product ID. Required.
   */
  constructor({ ...args }) {
    this.inventoryData = args.inventoryData.data.inventory;
    this.productData = args.productData.data.product;
    const { skus: inventorySkus } = this.inventoryData;

    // scan all the productData colorSlices props to get a list of colorManager and skus.
    this.colorManager = new ColorManager(this.productData.colorSlices);

    // The inventory API has more up-to-date inventory information than the product API.
    // Therefore, we use the inventory API data whenever possible.
    //
    // The flag "isRejected" is added by withInventory.fetchProduct() ONLY if the
    // inventory API had a fatal network error.
    //
    // If the inventory API is down, fall back to the product API inventory data
    // by skipping the sku inventory reconciliation process.
    if (!this.inventoryData.isRejected) {
      const updatedColorDirectives = reconcileSkuInventory({
        products: this.colorManager.extractSkus(),
        inventory: inventorySkus,
      });

      this.colorManager.updateColorsAvailability(updatedColorDirectives);
    }

    // Now that the colorManager has an accurate list of skus and availability, we can make
    // ArrayMatrix stuff.
    // these matrix properties are the heart of the pantogram.  They let us make quick scans of all
    // the skus to find things out like available inseams for a particular waist size, and if
    // a color is in stock.

    this.matrixCES = new ArrayMatrix({
      data: this.colorManager.extractSkus(),
      orders: ['colorName', 'ext', 'size'],
    });

    this.matrixCESI = new ArrayMatrix({
      data: this.colorManager.extractSkus(),
      orders: ['colorName', 'ext', 'size', 'inseam'],
    });

    // surface the orders that are associated with the Array Matrix.
    this.orders = this.colorManager.orders;

    [
      // 'getModelNames',
      'getDefaultColorName',
      'getColorIndexFromUrl',
      'getColorNameFromUrl',
      'getColorSku',
    ].forEach((m) => {
      this[m] = this[m].bind(this);
    });
  }

  // TODO: make a helper to get all the model names: 'All', 'Model 1',  'Model 2'
  // and make it future-proof should we change "Model 1" to a real name, like "Alice"
  // getModelNames() {
  //   const modelNames = [];
  //   this.colorManager.colorSet.forEach((color) => {
  //
  //   });
  // }

  /**
   * Return the default color slice object.
   *
   * @see ColorManger.getDefaultColor
   * @param  {Object} [arg={}]    Optional object argument. See ColorManager docs for description.
   * @return {[Undefined|Object]} See ColorMananger docs for description.
   */
  getDefaultColorName({ isInStockOnline = true } = {}) {
    const colorObject = this.colorManager.getDefaultColor({ isInStockOnline });
    if (colorObject) return colorObject.color;
    return undefined;
  }

  /**
   * Try to determine the selected color index from the URL.  If it can't, then it fall back to
   * the default color index.
   * @param  {Object} match  match object created by react-router withRouter() HOC.
   * @return {String}        a color name.
   */
  getColorIndexFromUrl(match) {
    const colorName = this.getColorNameFromUrl(match);
    const colorIndex = this.colorManager.colorIdx[colorName];
    const { defaultColorIndex } = this.colorManager;

    return typeof colorIndex === 'number' ? colorIndex : defaultColorIndex;
  }

  /**
   * Try to determine the selected color name from the URL.  If it can't, then it fall back to
   * the default color name.
   * @param  {Object} match  match object created by react-router withRouter() HOC.
   * @return {String}        a color name.
   */
  getColorNameFromUrl(match) {
    const { color } = match.params;
    const defaultColorName = this.getDefaultColorName();
    let colorName = defaultColorName;
    if (color) {
      try {
        colorName = decodeURIComponent(color);
      } catch (e) {
        colorName = defaultColorName;
      }
    }

    return colorName;
  }

  getColorSku(colorName) {
    return this.colorManager.getColor(colorName);
  }
}
