/**
 * @todo Find a way to split the review query out
 * and then we need to build a way to "combine"
 * queries from the separate queries folder;
 */
import gql from 'graphql-tag';

export const schema = `
product (id: $productId) {
  bopisEligible
  categories {
    name
    key
  }
  clearancePromoMessage
  finalsalePromoMessage
  collection
  crossRelDetailMessage
  crossRelProductURL
  EFOProduct
  expressProductType
  fabricCare
  fabricDetailImages {
    caption
    image
  }
  gender
  internationalShippingAvailable
  listPrice
  marketPlaceProduct
  name
  newProduct
  onlineExclusive
  onlineExclusivePromoMsg
  pdpCrossSellHeader
  productDescription {
    type
    content
  }
  matchingSet {
    color
    skus
  }
  productDetail
  productFeatures
  productId
  productImage
  productInventory
  productURL
  promoMessage
  recsAlgorithm
  originRecsAlgorithm
  salePrice
  type
  breadCrumbSummary
  breadCrumbCategory {
    categoryName
    h1CategoryName
    links {
      rel
      href
    }
    breadCrumbCategory {
      categoryName
      h1CategoryName
      links {
        rel
        href
      }
    }
  }
  colorSlices {
    color
    colorFamily
    defaultSlice
    ipColorCode
    hasWaistAndInseam
    swatchURL
    imageMap {
      All {
        LARGE
        MAIN
      }
      Default {
        LARGE
        MAIN
      }
      Model1 {
        LARGE
        MAIN
      }
      Model2 {
        LARGE
        MAIN
      }
      Model3 {
        LARGE
        MAIN
      }
    }
    mediaMap {
      main {
        url
        sequenceId
      }
      large{
        url
        sequenceId
      }
      modelInfo {
        sequenceId
        labelText
      }
    }
    onlineSkus
    skus {
      backOrderable
      backOrderDate
      categoryType
      displayMSRP
      displayPrice
      ext
      inseam
      inStoreInventoryCount
      inventoryMessage
      isFinalSale
      isInStockOnline
      miraklOffer {
        minimumShippingPrice
        sellerId
        sellerName
      }
      marketPlaceSku
      onClearance
      onSale
      onlineExclusive
      onlineInventoryCount
      size
      sizeName
      skuId
    }
  }
  originRecs {
    listPrice
    marketPlaceProduct
    name
    productId
    productImage
    productURL
    salePrice
  }
  relatedProducts {
    listPrice
    marketPlaceProduct
    name
    productId
    productImage
    productURL
    salePrice
    colorSlices {
      color
      defaultSlice
    }
  }
  storeTier
  icons {
    icon
    category
  }
}
`;

const query = gql`
  query ProductQuery ($productId: String!) {
    ${schema}
  }
`;

export default query;
