import React from 'react';
import { Button, Title } from '@express-labs/raven-ui';
import staticLinks from '../../staticLinks';

import s from './Error400.scss';

const Error400 = () => (
  <div className={s.div}>
    <Title tag="h1" className={s.maintext}>
      404 PAGE NOT FOUND
    </Title>
    <img
      src="http://www.express.com/cdn/responsive/images/pdp-400-error-image.png"
      className="responsive"
      alt="Clothing care tag with washer highlighted"
    />
    <Title tag="h2" className={s.regulartext}>
      Looks like this page got lost in the wash
    </Title>
    <Button href="/clothing/women/sec/womenCategory" className={s.button}>
      SHOP WOMEN&apos;S
    </Button>
    <Button href="/clothing/men/sec/menCategory" className={s.button}>
      SHOP MEN&apos;S
    </Button>
    <Button href={staticLinks.contactUs} className={s.button}>
      WE CAN HELP
    </Button>
  </div>
);

export default Error400;
