import gql from 'graphql-tag';

export const getPDPRecommendationsSchema = `
  boxType
  productId,
  productImage,
  productURL,
  name,
  listPrice,
  salePrice,
  requestId,
`;

const query = gql`
  query getPDPRecommendations($productId: String!, $recsAlgorithm: String){
    pdpRecommendations(productId: $productId, recsAlgorithm: $recsAlgorithm) {
      ${getPDPRecommendationsSchema}
    }
  }
`;

export default query;
