import gql from 'graphql-tag';

export default gql`
  query getSocialSellerInfo($sellerId: String!) {
    getSocialSellerInfo(sellerId: $sellerId) {
      sellerInfo {
        sellerId
        sellerName
        sellerImage
      }
    }
  }
`;
