import gql from 'graphql-tag';

export const schema = `
  id,
  firstName,
  lastName,
  addressLineOne,
  addressLineTwo,
  city,
  state,
  country,
  zipCode,
  phone,
  default
`;

export default gql`
  query getShippingAddressesInProfile {
    getShippingAddressesInProfile{
      ${schema}
    }
  }
`;
