import gql from 'graphql-tag';

export default gql`
  query GetPaymentMethods {
    paymentMethods {
      payments {
        paymentType
        paymentId
        default
        attributes {
          CREDIT_CARD_NUMBER
          CREDIT_CARD_EXPIRATION_MONTH
          CREDIT_CARD_EXPIRY_YEAR
          CREDIT_CARD_TOKEN
          CREDIT_CARD_TENDER_TYPE
          IS_CVV_REQUIRED
        }
        billingAddress {
          firstName
          lastName
          addressLineOne
          addressLineTwo
          city
          state
          zipCode
          country
          phone
          default
        }
      }
    }
  }
`;
