import gql from 'graphql-tag';

export default gql`
  query getShippingCosts {
    getShippingCosts {
      marketplaceShipping
      expressShipping
    }
  }
`;
