import gql from 'graphql-tag';

export const getPDPMatchingProductSetsSchema = `
  cartPromoMessage
  clearancePromoMessage
  finalSalePromoMessage
  key
  listPrice
  name
  newProduct
  onlineExclusive
  productDescription
  productId
  productImage
  productRating
  productURL
  salePrice
  totalReviewCount
  productColorSwatches
  imageSet
`;

const query = gql`
  query pdpMatchingProductSets($ids: [String]){
    pdpMatchingProductSets(ids: $ids) {
      ${getPDPMatchingProductSetsSchema}
    }
  }
`;

export default query;
