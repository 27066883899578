import { createNewState } from '../ravenReduxHelpers';

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.
export const SET_KLARNA_SESSION_DATA = 'klarnaReducer/SET_KLARNA_SESSION_DATA';

// Action Creators
// ---------------
export const storeKlarnaSessionData = (klarnaSessionData) => ({
  type: SET_KLARNA_SESSION_DATA,
  klarnaSessionData,
});

// Initial State
// -------------
const initialState = {
  klarnaSessionData: {},
};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  const { type, ...filteredAction } = action;
  switch (type) {
    case SET_KLARNA_SESSION_DATA: {
      const { klarnaSessionData } = filteredAction;
      return createNewState(state, { klarnaSessionData });
    }
    default:
      return state;
  }
}
