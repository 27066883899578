// Apollo is an enterprise GraphQL module
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';

import fetch from 'unfetch';
import {
  akamaiCaching,
  hasHeaders,
  isCacheableHeader,
  queryClassificationHeader,
  queryDebug,
  sourceHeader,
  trackGraphQLErrors,
  uid,
  geoLocationHeader,
} from '@express-labs/raven-middleware';

const ignoreList = [
  'customer',
  'getAddresses',
  'getDashBoardDetails',
  'getPaymentMethodsInProfile',
  'getShippingAddressesInProfile',
  'orderSummary',
  'paymentMethods',
  'reserveOrder',
  'submitOrder',
  'validateResetPasswordKey',
  'wolpsPrescreenAccept',
];

const httpLink = new HttpLink({
  credentials: 'same-origin',
  fetch,
  uri: '/graphql',
});

const middlewares = [
  hasHeaders,
  akamaiCaching,
  queryClassificationHeader,
  sourceHeader('app_express.com'),
  isCacheableHeader,
  uid,
  geoLocationHeader,
];

if (window.isDebugMode) middlewares.push(queryDebug);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(trackGraphQLErrors(ignoreList)),
    ...middlewares,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  shouldBatch: true,
});

export default client;
