import gql from 'graphql-tag';

export const schema = `
  addressLine1
  addressLine2
  birthDay
  birthMonth
  city
  country
  firstName
  gender
  lastName
  optInToSMS
  phoneNumber
  postalCode
  state
  preEnrollPhoneNumber
`;

export default gql`
  query getCustomerProfile {
    getCustomerProfile {
      ${schema}
    }
  }
`;
