import React from 'react';
import PropTypes from 'prop-types';
import s from './errorBoundary.scss';
import { component } from '../custom-prop-types';

export class ErrorBoundary extends React.Component {
  static meta = {
    VERSION: '1.0.0',
  };

  static propTypes = {
    component,
    errorView: PropTypes.node.isRequired,
    priority: PropTypes.string.isRequired,
  };

  static defaultProps = {
    component: null,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    // display fallback UI
    this.setState({ hasError: true });

    if (window.trackJs) {
      // log to TrackJS
      window.trackJs.console.error({
        note: 'errorBoundary() hoc caught an error',
        error,
        info,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.errorView;
    }

    const { component: WrappedComponent } = this.props;
    return <WrappedComponent {...this.props} />;
  }
}

function errorBoundary(WrappedComponent, config) {
  const {
    priority = 'N/A',
    errorView = <h1 className={s.title}>Something went wrong here</h1>,
  } = config;
  const hoc = (props) => (
    <ErrorBoundary
      {...props}
      priority={priority}
      errorView={errorView}
      component={WrappedComponent}
    />
  );

  hoc.displayName = 'errorBoundary';
  return hoc;
}

function errorBoundaryFactory() {
  return (config = {}) =>
    (Component) =>
      errorBoundary(Component, config);
}

export default errorBoundaryFactory();
