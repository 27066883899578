import React from 'react';
import { Button, Title, Modal } from '@express-labs/raven-ui';
import { loog, getPath } from '@express-labs/raven-tools';
import PropTypes from 'prop-types';
import s from './withMelissaData.scss';
import { component } from '../custom-prop-types';

class WithMelissaData extends React.Component {
  static checkAddressValidity({ shippingAddress, melissaDataAddress }) {
    const melissaAddress = {
      line1:
        melissaDataAddress.PremisesNumber && melissaDataAddress.Thoroughfare
          ? `${melissaDataAddress.PremisesNumber} ${melissaDataAddress.Thoroughfare}`
          : '',
      line2: `${melissaDataAddress.SubPremises}` || '',
      city: `${melissaDataAddress.Locality}` || '',
      state: `${melissaDataAddress.AdministrativeArea}` || '',
      postalCode: `${melissaDataAddress.PostalCode}` || '',
      countryCode: `${melissaDataAddress.CountryISO3166_1_Alpha2}` || '',
    };
    const noMatch = Object.keys(melissaAddress).find((key) => {
      if (key === 'postalCode')
        return (
          melissaAddress[key].substr(0, shippingAddress[key].length) !==
          shippingAddress[key]
        );
      if (key === 'line2' && !shippingAddress[key] && !melissaAddress[key])
        return false;

      return melissaAddress[key] !== shippingAddress[key];
    });

    loog('Address validity', {
      isInvalid: !!noMatch,
      shippingAddress,
      melissaAddress,
    });

    return {
      isInvalid: !!noMatch,
      shippingAddress,
      melissaAddress,
    };
  }

  static propTypes = {
    getMelissaData: PropTypes.func.isRequired,
    component,
  };

  static defaultProps = {
    component: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      melissaProps: null,
    };
    this.getMelissaData = this.getMelissaData.bind(this);
    this.Component = props.component;
  }

  static getMelissaModal(props) {
    const { userAddress, melissaAddress } = props;
    const ariaAddress = Object.values(userAddress).join(' ');
    return (
      <Modal
        isCloseable={false}
        isOpen
        className={s.alertWindowModal}
        contentLabel="Please Verify Your Address"
        setAppElement=".content"
      >
        <div className={s.alertWindow}>
          <Title tag="h2" className={s.title}>
            Verify Address
          </Title>
          <section
            role="contentinfo"
            aria-label="Address you entered."
            className={s.usersAddress}
          >
            <Title tag="h3" className={s.addressTitle}>
              Address you entered:{' '}
            </Title>
            <div className={s.verifyAddress} data-pii="true">
              <span className={s.addressLine}>
                {userAddress.firstName} {userAddress.lastName}
              </span>
              <span className={s.addressLine}>{userAddress.line1}</span>
              {userAddress.line1 !== '' && (
                <span className={s.addressLine}>{userAddress.line2}</span>
              )}
              <span className={s.addressLine}>
                {userAddress.city},{userAddress.state} {userAddress.postalCode}
              </span>
              <span className={s.addressLine}>{userAddress.countryCode}</span>
            </div>
            <div className={s.verifyButton}>
              <Button
                className={s.useAddressButton}
                tag="button"
                aria-label={`Use Address ${ariaAddress}. Address entered section.`}
                onClick={props.onUserSelect}
              >
                Use Address
              </Button>
              <Button
                className={s.useAddressButton}
                tag="button"
                aria-label={`Edit Address ${ariaAddress}. Address entered section.`}
                onClick={props.onEditSelect}
              >
                Edit Address
              </Button>
            </div>
          </section>
          <section
            role="contentinfo"
            aria-label="Address we found."
            className={s.melissaAddress}
          >
            <Title tag="h3" className={s.addressTitle}>
              Address we found:{' '}
            </Title>
            <div className={s.verifyAddress} data-pii="true">
              <span className={s.addressLine}>
                {userAddress.firstName} {userAddress.lastName}
              </span>
              <span className={s.addressLine}>{melissaAddress.line1}</span>
              {melissaAddress.line2 !== '' && (
                <span className={s.addressLine}>{melissaAddress.line2}</span>
              )}
              <span className={s.addressLine}>
                {melissaAddress.city}
                {melissaAddress.city && melissaAddress.state && ', '}
                {melissaAddress.state} {melissaAddress.postalCode}
              </span>
              <span className={s.addressLine}>
                {melissaAddress.countryCode}
              </span>
            </div>
            <div className={s.verifyButton}>
              <Button
                className={s.useAddressButton}
                tag="button"
                aria-label={`Use Address ${ariaAddress}. Address we found section.`}
                onClick={props.onMelissaSelect}
              >
                Use Address
              </Button>
            </div>
          </section>
        </div>
      </Modal>
    );
  }

  getMelissaData(payload) {
    return new Promise((resolve) => {
      const { shippingAddress } = payload;
      this.props
        .getMelissaData({
          address: {
            city: shippingAddress.city || '',
            country: shippingAddress.countryCode || '',
            postalCode: shippingAddress.postalCode || '',
            state: shippingAddress.state || '',
            streetAddressOne: shippingAddress.line1 || '',
            streetAddressTwo: shippingAddress.line2 || '',
          },
        })
        .then((res) => {
          const records = Object.assign(
            [],
            res.data.getMelissaData.Records || ''
          );
          if (records.length) {
            const melissaVerification = WithMelissaData.checkAddressValidity({
              shippingAddress,
              melissaDataAddress: getPath(records, '0', {}),
            });
            if (melissaVerification.isInvalid) {
              const melissaWithNames = {
                ...melissaVerification.shippingAddress,
                ...melissaVerification.melissaAddress,
              };
              const onMelissaSelectCallback = (userPayload, melissaPayload) => {
                const splittingPostalCode =
                  melissaPayload.postalCode.split('-')[0];
                const newMelissaPayload = { ...melissaPayload };
                newMelissaPayload.postalCode = splittingPostalCode;

                this.setState(
                  {
                    melissaProps: null,
                  },
                  () => {
                    resolve({
                      ...payload,
                      shippingAddress: newMelissaPayload,
                    });
                  }
                );
              };
              const onEditSelectCallback = () => {
                this.setState({
                  melissaProps: null,
                });
              };
              const onUserSelectCallback = (userPayload) => {
                resolve(userPayload);
              };
              const melissaProps = {
                melissaProps: {
                  onMelissaSelect: () =>
                    onMelissaSelectCallback(payload, melissaWithNames),
                  onUserSelect: () => onUserSelectCallback(payload),
                  onEditSelect: () => onEditSelectCallback(payload),
                  melissaAddress: melissaVerification.melissaAddress,
                  userAddress: melissaVerification.shippingAddress,
                },
              };
              this.setState(melissaProps);

              // NOTE: This looks like it is never called? -- Matt Toledo 1/11/2022
              if (this.state.shouldResolve) {
                resolve(melissaProps);
              }

              resolve(payload);
            } else {
              resolve(payload);
            }
          } else {
            resolve(payload);
          }
        })
        .catch((error) => {
          loog(`Melissa Address Error: ${error}`, 'info', error);
          resolve(payload);
        });
    });
  }

  render() {
    const { component: wrappedComponent, ...filteredProps } = this.props;
    const { melissaProps } = this.state;
    const { Component } = this;
    return (
      <>
        <Component {...filteredProps} checkMelissaData={this.getMelissaData} />
        {melissaProps && WithMelissaData.getMelissaModal(melissaProps)}
      </>
    );
  }
}

export default WithMelissaData;
