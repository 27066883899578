import gql from 'graphql-tag';

export const getPDPExpertRecommendationsSchema = `
  boxType
  productId,
  productImage,
  productURL,
  name,
  listPrice,
  salePrice,
  productRating,
  totalReviewCount,
  productColorSwatches,
  newProduct,
  onlineExclusive,
  marketplaceProduct,
  pdpCrossSellPromoMessage,
`;

const query = gql`
  query getPDPExpertRecommendations($productId: String!, $unbxdTypeOverride: String){
    pdpExpertRecommendations(productId: $productId, unbxdTypeOverride: $unbxdTypeOverride) {
      ${getPDPExpertRecommendationsSchema}
    }
  }
`;

export default query;
