import gql from 'graphql-tag';

export default gql`
  query getFlyoutAuthorCopy {
    getFlyoutAuthorCopy {
      authorableText
      cta {
        title
        href
      }
    }
  }
`;
