export default class TestResult {
  constructor({
    errorData = null,
    isError = null,
    isLoading = null,
    isSuccess = null,
  } = {}) {
    this.errorData = errorData;
    this.isError = isError;
    this.isLoading = isLoading;
    this.isSuccess = isSuccess;
  }

  get() {
    return {
      errorData: this.errorData,
      isError: this.isError,
      isLoading: this.isLoading,
      isSuccess: this.isSuccess,
    };
  }
}
