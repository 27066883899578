import gql from 'graphql-tag';

export default gql`
  query ReviewContainerQuery(
    $id: String!
    $limit: Int
    $offset: Int
    $sort: String
    $useCache: Boolean
  ) {
    feedback(
      id: $id
      limit: $limit
      offset: $offset
      sort: $sort
      useCache: $useCache
    ) {
      ratings {
        count
        metrics {
          overall
          size
        }
        recommend {
          yes
          no
        }
      }
      results {
        count
        reviews {
          author {
            id
            age
            badges
            frequency
            location
            name
          }
          content {
            title
            text
            occasions
            photos
          }
          helpful {
            yes
            no
          }
          date
          id
          ratings {
            overall
            size
          }
        }
      }
    }
  }
`;
