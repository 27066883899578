import gql from 'graphql-tag';

export default gql`
  query getShopMyStoreConfig {
    getShopMyStoreConfig {
      shopMyStoreSessionDurationMinutes
      shopMyStoreSessionMessage
    }
  }
`;
