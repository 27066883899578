import gql from 'graphql-tag';
import { availabilitySchema } from './getAvailabilities';

const query = gql`
  query getAvailabilities{
    availabilities{
      ${availabilitySchema}
    }
  }
`;

export default query;
