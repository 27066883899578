import React from 'react';
import PropTypes from 'prop-types';
import { component } from '../custom-prop-types';

export class MapProps extends React.Component {
  static meta = {
    VERSION: '1.0.0',
  };

  static propTypes = {
    component,
    mapFunction: PropTypes.func,
    mapPropsMaps: PropTypes.shape({}),
    mapPropsMode: PropTypes.oneOf(['merge', 'gate']),
  };

  static defaultProps = {
    component: null,
    mapFunction: null,
    mapPropsMaps: {},
    mapPropsMode: 'merge',
  };

  render() {
    const {
      component: WrappedComponent,
      mapFunction,
      mapPropsMaps: maps,
      mapPropsMode: mode,
      ...filteredProps
    } = this.props;

    const initialValue = mode === 'gate' ? {} : filteredProps;
    let newProps = {};

    if (typeof mapFunction === 'function') {
      newProps = { ...initialValue, ...mapFunction(filteredProps) };
    } else {
      newProps = Object.keys(maps).reduce(
        (acc, key) => ({
          ...acc,
          [key]: maps[key](filteredProps),
        }),
        initialValue
      );
    }

    return <WrappedComponent {...newProps} />;
  }
}

function mapProps(WrappedComponent, config) {
  const { mode, maps, mapFunction } = config;
  const hoc = (props) => (
    <MapProps
      {...props}
      component={WrappedComponent}
      mapPropsMaps={maps}
      mapPropsMode={mode}
      mapFunction={mapFunction}
    />
  );

  hoc.displayName = 'mapProps';

  return hoc;
}

function mapPropsFactory() {
  return (config) => (Component) => mapProps(Component, config);
}

export default mapPropsFactory();
