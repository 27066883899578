import gql from 'graphql-tag';

const query = gql`
  query OrderHistoryQuery {
    orderHistory {
      totalNoOfRecords
      orders {
        exchange
        returned
        orderNumber
        orderCreatedDate
        orderStatus
        formattedGrandTotal
      }
    }
  }
`;

export default query;
