import gql from 'graphql-tag';

export default gql`
  query getOrderSummary($shouldRecalculateTax: Boolean) {
    orderSummary(shouldRecalculateTax: $shouldRecalculateTax) {
      sellerInfo {
        sellerId
        sellerName
        sellerImage
        displaySellerId
        displaySellerName
        displaySellerImage
      }
    }
  }
`;
