import gql from 'graphql-tag';

export default gql`
  query getFlyoutMarketingContent {
    getFlyoutMarketingContent {
      authorableHeader {
        content
        image
        cta {
          text
          action
        }
      }
      authorableContent {
        content
      }
      authorableFooter {
        content
        image
        cta {
          text
          webAction
        }
      }
    }
  }
`;
