import deepFreeze from 'deep-freeze';

/**
 * createNewState
 * A helper function used in reducers to create a new state.  Takes the old state object, and a new
 * state object and SHALLOWLY merges them. It then returns the updated state as an immutable
 * object.
 */
export const createNewState = (oldState, nextState) =>
  deepFreeze({ ...oldState, ...nextState });

/**
 * rehydrate
 * A helper function used in reducers that leverage redux-persist to persist their data to local
 * storage.  Pass in the action, state, and the name of the reducer as a string.
 */
export const rehydrate = (action, state, reducerName) => {
  const incoming = action.payload[reducerName];
  // NOTE: if this is their first visit, incoming will always be undefined
  // if (!incoming) {
  //   throw new Error(`Could not find reducer with the name of '${reducerName}'`);
  // }
  if (incoming) {
    return { ...state, ...incoming };
  }
  return state;
};
