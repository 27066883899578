import gql from 'graphql-tag';

export default gql`
  query enccPrefillCustomerInfo {
    enccPrefillCustomerInfo {
      addressLine1
      addressLine2
      birthDay
      birthMonth
      city
      country
      email
      firstName
      lastName
      phone
      postalCode
      state
    }
  }
`;
