/**
 * midStreamAfterWithInventoryHelper
 *
 * The withInventory HOC creates loading and error props that are shared with other HOC's
 * downstream.  This "helper" provides common configuration options to withComponentLoading() to
 * reduce duplicated code.
 *
 * This helper will render nothing (null) until withInventory has finished successfully loading
 * data.  This means that the target component will not render until the data has loaded
 * successfully.  Errors ALSO result in nothing being loaded.  This works in most situations
 * because if there is a network or graphql error, it will be handled at PAGE level. (the "oops"
 * page users see when a page has an error)
 *
 * simple terms:
 *
 *   If you use this HOC, you don't need to add repetitive logic to your Component to check if
 *   data is loaded, and logic to check if data exists.
 *
 * How to use this helper:
 * - the target component is being consumed by a container.
 * - withComponentLoading() is immediately after withInventory()
 * - supply midStreamAfterWithInventoryHelper as the sole argument for withComponentLoading()
 *
 * Example
 * import { pageLevelAfterWithInventoryHelper } from '[ relative path to helper ]';
 * import { compose } from '../../hocs';
 *
 * compose(
 *   [ optional HOCs before ]
 *   withInventory(...)
 *   withComponentLoading(midStreamAfterWithInventoryHelper),
 *   [ optional HOCs after]
 * )(MyTestPage)
 *
 * @type {Object}   Insert this object into withPageLoading() "custom" array option.
 */
export default {
  isLoading: (ownProps) => ownProps.withInventory.isLoading,
  isError: (ownProps) => ownProps.withInventory.isError,
  renderLoading: () => null,
};
