import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { component } from '../custom-prop-types';
import { isObject } from '../../utils';

export class Analytics extends React.Component {
  static log(name, data) {
    if (window.isDebugMode && window.log && window.log.info) {
      window.log.info(`Analytics event ${name} fired.`, data);
    }
  }

  static sendSignal({ name, mergedData }) {
    window.Eva.signal(name, window, mergedData);
  }

  static contactEva({ name, mergedData, vendorFlags }) {
    const { Eva } = window;

    if (vendorFlags.BrightTag) {
      /* istanbul ignore else */
      if (window.Eva) {
        Eva.listen(
          'SIGNALReady',
          Analytics.sendSignal({ name, mergedData }),
          document
        );
      }
    } else {
      Eva.signal(name, window, mergedData);
    }
  }

  static propTypes = {
    children: PropTypes.node,
    client: PropTypes.shape({}),
    component,
    dispatch: PropTypes.func,
    vendorFlags: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    children: null,
    client: null,
    component: null,
    dispatch: null,
  };

  constructor(props) {
    super(props);

    this.analyze = this.analyze.bind(this);
  }

  /**
   * Invoke an analytics event and pass the event handler data.
   *
   * @param  {String} name       A tag that the analytics team uses to namespace their analytics
   * @param  {Object} props={}   Pass in the props from the component calling this method.
   * @param  {Object} data={}    Any additional data that we wish to merge in.  Property names in
   *                             the data object will overwrite identical property names in the
   *                             props argument.
   * @return {name, mergedData}  Returns the tag name and merged data.  useful for testing.
   */
  analyze({ name, data = {} }) {
    const { children, client, dispatch, ...filteredProps } = this.props;

    let mergedData;
    // It is unclear why the original code is stringifying the object before parsing it.
    // JSON.parse(JSON.stringify({ ...filteredProps, ...data })) returns
    // the same result as { ...filteredProps, ...data }
    // However I will keep this in the meantime while investigating why JSON.stringify is required

    if (isObject(filteredProps) && isObject(data)) {
      mergedData = { ...filteredProps, ...data };
    } else {
      mergedData = JSON.parse(JSON.stringify({ ...filteredProps, ...data }));
    }

    Analytics.log(name, mergedData);

    Analytics.contactEva({
      name,
      mergedData,
      vendorFlags: this.props.vendorFlags,
    });

    return {
      name,
      mergedData,
    };
  }

  render() {
    const { component: Component } = this.props;

    return <Component {...this.props} analyze={this.analyze} />;
  }
}

export const mapStateToProps = (state) => ({
  vendorFlags: state.vendorReducer.vendors,
});

const ConnectedAnalytics = compose(connect(mapStateToProps, null))(Analytics);

export default function withAnalytics(WrappedComponent) {
  const hoc = (props) => (
    <ConnectedAnalytics {...props} component={WrappedComponent} />
  );
  hoc.displayName = 'withAnalytics';
  return hoc;
}
