import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useIsElementAboveViewportBottom(
  element,
  additionalTrigger
) {
  const [isElementInViewport, setIsElementInViewport] = useState(false);
  const { scrollTop, height: viewportHeight } = useSelector(
    (store) => store.deviceWidthReducer
  );

  useEffect(() => {
    if (element) {
      const { top } = element.getBoundingClientRect();
      setIsElementInViewport(top < viewportHeight);
    }
  }, [element, scrollTop, additionalTrigger, viewportHeight]);
  return isElementInViewport;
}
