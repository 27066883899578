import { useQuery } from 'react-apollo';
import { getOrderSummary } from '../../queries';

export default function useOrderSummary() {
  const { loading, data } =
    useQuery(getOrderSummary, { fetchPolicy: 'network-only' }) || {};

  const lineItem = data?.orderSummary?.lineItems ?? [];
  const lineItemSelect = lineItem[lineItem.length - 1] ?? null;
  const isSize = lineItemSelect?.product?.sku?.sizeName ?? null;
  const listPrice = lineItemSelect?.product?.sku?.displayMSRP ?? null;
  const salePrice = lineItemSelect?.product?.sku?.displayPrice ?? null;
  const isEnsemblePromoMessage = lineItemSelect?.product?.promoMessage ?? null;

  const total = data?.orderSummary?.priceDetails?.expressTotalAmount ?? '';
  const subtotal =
    data?.orderSummary?.priceDetails?.subTotalAmount?.displayAmount ?? '';

  const returnVal = {
    loading,
    data,
    lineItem,
    isSize,
    total,
    subtotal,
    listPrice,
    salePrice,
    isEnsemblePromoMessage,
  };

  return returnVal;
}
