import gql from 'graphql-tag';

export default gql`
  query getProfileStates {
    getProfileStates {
      unfilledProfile
      hasRequalification
      profileUpdated
      isAnnualUpdateEligible
      isProfileOutdated
    }
  }
`;
