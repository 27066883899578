import gql from 'graphql-tag';

export const schema = `
customer{
  customerInfo{
    aList,
    associateLogin,
    associateStatus,
    associateNumber,
    birthDayReward,
    bopisEligibleStatus,
    country,
    emailAddress,
    firstName,
    gender,
    lastName,
    loginStatus,
    loyaltyNumber,
    loyaltyStatus,
    pendingRewardsCount,
    phoneNumber,
    pointsBalance,
    pointsToNextTier,
    pointsTowardsAListStatus,
    postalCode,
    preferredStore,
    rewardAmountInCentsForAListReward,
    rewardAmountInCentsForNextReward,
    rewardCount,
    rewards{
      rewardId,
      currency,
      amount,
      displayAmount,
      dateIssued,
      expirationDate,
      shortDescription,
      expirationDays,
    }
    rewardsTotal,
    tierName,
    totalPointsForAListReward,
    totalPointsForNextReward,
    memberRewardChoice{
      catalogEndDate,
      catalogStartDate,
      currencyToEarn,
      displayName,
      ipCode,
      rewardId,
    },
    memberRewardChoiceInDollars,
  }
}
challengeList{
  customerChallenges{
      image,
      challengeId,
      shortDescription,
      longDescription,
      actionButton,
      challengeName,
      points,
      headline,
      actionStep,
    }
  }
pointsHistoryResponse{
  pointsHistoryList {
    dateTime
    eventName
    earnedPoints
  }
}
`;

export default gql`
  query getDashBoardDetails {
    getDashBoardDetails{
      ${schema}
    }
  }
`;
