import gql from 'graphql-tag';

export const schema = `
  errors{
    code,
    message,
    additionalDetails{
      productName,
      color,
      size,
      availableQuantity,
      giftCardPresent
    }
  }
  bagTotal,
  orderId,
  orderNumber,
  status,
  bopisEligibleStatus,
  bopisOrderPickupDays,
  socialSellerCart
  foStatus{
    foErrorMsg,
    lastCreateOrderAttemptTime,
    maxCreateOrderAttemptsAllowed,
    noOfCreateOrderAttempts,
    foErrorMsg,
  }
  contactInfo{
    email,
    phone,
    firstName,
    lastName,
  }
  billingAddress{
    addressId,
    firstName,
    lastName,
    title,
    line1,
    line2,
    line3,
    city,
    state,
    country,
    postalCode,
    preferredAddress
  }
  freeShippingThreshold
  lineItems{
    efoFlag,
    efoDisclaimer,
    eGiftCardInfo{
      recipientFirstName,
      recipientLastName,
      recipientEmail,
      recipientMessage
    },
    finalSaleDisclaimer,
    giftWrapSelected,
    internationalShippingAvailable,
    internationalShippingDisclaimer,
    isFinalSale,
    isStorePickup,
    isOnlineExclusive,
    discountMessages,
    itemPromotionDiscount{
      displayAmount,
      specialDiscountMessage
    },
    itemPromotions{
      name
    },
    lineId,
    marketPlaceProduct,
    marketPlaceProductDisclaimer,
    price{
      displayAmount,
      amount
    }
    product{
      listPrice,
      name,
      productId,
      productImage,
      productSlug,
      productURL,
      promoMessage,
      salePrice,
      isOnStock,
      sku{
        backOrderable,
        backOrderDate,
        skuId,
        sizeName,
        sizeCode,
        colorCode,
        colorName,
        colorFamilyName,
        displayPrice,
        displayMSRP,
        eGiftCard,
        giftBox,
        giftCard,
        giftCardInfo {
          fromName,
          toName,
          toMessage,
          toEmail,
          fromEmail,
          scheduledDeliveryDate,
        }
        sizeExtension1,
        sizeExtension2,
        marketPlaceSku,
        isFinalSale,
        miraklOffer {
          minimumShippingPrice
          sellerName
        }
        originalPrice,
        currentPrice,
        displayMSRP,
        displayPrice,
        bopisEligible,
      }
    },
    quantity,
  },
  loyalty {
    loyaltyFreeReturnsEligible
    loyaltyFreeReturnsReasonCode
    loyaltyFreeShippingEligible
    loyaltyFreeShippingReasonCode
  },
  promotions{
    code
    walletCouponCode
    promotionDiscount{
      displayAmount
      specialDiscountMessage
    }
  },
  rewards{
    amount
    displayAmount
    expirationDate
    expirationDays
    rewardId
  },
  shippingDestinations{
    shippingAddress{
      addressId,
      firstName,
      lastName,
      title,
      line1,
      line2,
      line3,
      city,
      state,
      country,
      postalCode,
      preferredAddress
    },
    shippingMethod{
      name,
      estimatedDelivery,
      estimatedDeliveryMessage,
      estimatedBusinessDeliveryMessage
    }
  },
  shippingMethods{
    cost
    description
    estimatedDelivery
    estimatedDeliveryMessage
    estimatedBusinessDeliveryMessage
    value
    id
    isDefault
    miraklSellerShipment
    name
  }
  orderHasGiftCard,
  giftCards{
    cards,
    eCards,
    total,
  }
  payments{
    paymentType,
    paymentgroupID,
    redirectUrl,
    attributes{
      APPLIED_AMOUNT
      BALANCE_AMOUNT
      CREDIT_CARD_EXPIRATION_MONTH
      CREDIT_CARD_EXPIRY_YEAR
      CREDIT_CARD_NUMBER
      CREDIT_CARD_TENDER_TYPE
      GIFT_CARD_NUMBER
    }
  },
  customerStoreInfo{
    orderStore{
      storeId,
      storeNumber,
      name,
      addressLine1,
      city,
      state,
      postalCode,
      country,
      phoneNumber,
      hoursOfOperation,
      weeklyHoursOfOperation{
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      }
      bopisEligible,
      bopisMessage,
      distance,
      skus{
        sku,
        quantity,
        availabilityMessage,
        bopisMessage,
        atgInventoryQuantity,
        backOrderable,
      }
    },
    preferredStore{
      bopisEligible
    }
  }
  priceDetails{
    appliedPaymentsTotal{
      amount
      displayAmount
    }
    coloradoDeliveryFee{
      amount,
      displayAmount
    }
    giftCardTotal{
      amount,
      displayAmount
    },
    orderPromotionTotal{
      amount,
      displayAmount
    },
    orderTransactionalSavings{
      amount,
      displayAmount
    },
    enccPromotionTotal{
      amount,
      displayAmount,
      specialDiscountMessage
    },
    processingFee{
      amount,
      displayAmount
    },
    rewardsTotal{
      amount,
      displayAmount
    },
    roundUpForCharityAmount{
      amount,
      displayAmount
    }
    salesTaxes{
      amount,
      displayAmount
    },
    shippingPrice{
      amount,
      displayAmount
    }
    marketplaceShippingPrice {
      amount,
      displayAmount
    }
    subTotalAmount{
      amount,
      displayAmount
    }
    expressTotalAmount{
      amount,
      displayAmount
    }
    totalAmount{
      amount,
      displayAmount
    }
    tenderTypePromo {
      amount,
      displayAmount,
      specialDiscountMessage
    }
    giftWrapAmount{
      amount,
      displayAmount
    }
    paymentDueAmount{
      amount,
      displayAmount
    }
    estimatedPoints{
      amount,
      displayAmount
    }
    estimatedPointsWithExpressCard{
      amount,
      displayAmount
    }
  }
  deliveryType,
  pickupOrderInformation{
    firstName,
    lastName,
    email,
    phone,
    otherPersonPickup,
    sendEmailNotifications,
    sendSmsNotifications,
  }
  sellerInfo {
    sellerId,
    sellerName,
    sellerImage,
    displaySellerId,
    displaySellerName,
    displaySellerImage
  }
`;

export default gql`
  query getOrderSummary($shouldRecalculateTax: Boolean, $shouldSkipUpdateShipping: Boolean) {
    orderSummary(shouldRecalculateTax: $shouldRecalculateTax, shouldSkipUpdateShipping: $shouldSkipUpdateShipping) {
      ${schema}
    }
  }
`;
