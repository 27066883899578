import PropTypes from 'prop-types';
import React from 'react';
import { areArraysSimilar, getPath, loog } from '@express-labs/raven-tools';
import { withApollo } from '@apollo/react-hoc';
import getInventory from '../../queries/getInventory';
import getProduct from '../../queries/getProduct';
import { LoadingStateManager, Matrix } from './helpers';
import { component } from '../custom-prop-types';

export class WithInventory extends React.Component {
  /**
   * Uses decodeURIComponent to decode URI encoded characters (e.g. %20 = space) but don't change
   * the type of the input variable.  For example decodeURIComponent(undefined) returns a string
   * "undefined". If you applied this to a size segment, the size would be "undefined". Likewise,
   * false becomes "false", null becomes "null". This is undesireable because lots of our code
   * uses Object.assign() and spread syntax to merge objects when computing the PDP url or the
   * sku id.
   *
   * @param  {Any} x Usually a string, but can be any type.
   * @return {Any}   Usually returns a decoded URL segment string, but preserves type if not string.
   */
  static safeDecode(x) {
    return typeof x === 'string' ? decodeURIComponent(x) : x;
  }

  /**
   * A helper function used by getSizes and getInseams to test if a given size or inseam is in
   * stock. Not to be used publically.
   *
   * @private
   * @param  {String}  buttonType                   Only allowed values: 'size' or 'inseam'
   * @param  {[type]}  buttonValue                  The size or inseam value to test
   * @param  {[type]}  ext                          A size extension like regular, tall, petite, etc
   * @param  {Boolean} isBackorderConsideredInStock If true, treat out of stock skus that are
   *                                                backorderable as in stock. Default is false.
   * @param  {Array}  skuSet                        All the sku objects for a product
   * @return {Boolean}                              True if the sku is in stock.
   */
  static testIsSkuInStock({
    buttonType,
    buttonValue,
    ext,
    isBackorderConsideredInStock = false,
    skuSet,
  }) {
    return skuSet.some(
      (sku) =>
        sku.ext === ext &&
        sku[buttonType] === buttonValue &&
        WithInventory.isSkuInStockHelper({
          backOrderable: sku.backOrderable,
          isBackorderConsideredInStock,
          isInStockOnline: sku.isInStockOnline,
        })
    );
  }

  /**
   * Transition the given product ID to an error state.
   * Product ID's move from Queue -> Loading -> Success or Error
   *
   * @private
   * @param  {string} productId A product id.
   * @param  {any} error        Usually a string describing the error, technically can be anything.
   * @return {function}         Returns a function that takes LoadingStateManager state as an arg
   *                            and returns the updated LoadingStateManager state.
   */
  static fetchProductError(productId, error) {
    return (state) =>
      LoadingStateManager.transitionLoadingToError(state, productId, error);
  }

  /**
   * Rejects an inventory promise by resolving the inventory promise with a properly shaped null
   * object that satisfies the requirements of Matrix, but provides no inventory data.
   *
   * We also add a flag to the object stating that this was a failed response.
   * withInventory/helpers/Matrix will detect this flag and adjust how product and
   * inventory api online inventory counts are reconciled.
   *
   * By "reconciled" we mean that the inventory API is updated every 15 minutes and the
   * product API is updated every 24 hours.  We go with the inventory API whenever possible.
   * If the inventory API is down for some reason, fall back to the product API.
   *
   * @private
   * @return {Object} A dummy Inventory response with no real data.
   */
  static safelyRejectInventoryPromise() {
    return {
      data: {
        inventory: {
          isRejected: true, // notifies Matrix.jsx that inventory API is down.
          skus: [],
        },
      },
    };
  }

  /**
   * Private helper used by withInventory in a few places to test if a sku is in stock based on the
   * configuration of some vendor flags.
   * @private
   * @param  {Boolean} isInStockOnline
   * @param  {Boolean} isBackorderConsideredInStock
   * @param  {Boolean} backOrderable
   * @return {Boolean}
   */
  static isSkuInStockHelper({
    backOrderable,
    isBackorderConsideredInStock,
    isInStockOnline,
  }) {
    return Boolean(
      isInStockOnline || (isBackorderConsideredInStock && backOrderable)
    );
  }

  /**
   * A helper method used by getSizes to further refine which "size" and "inseam" buttons receive
   * a the diagnoal stripe indicator to indicate the choice is unavailable.
   *
   * @private
   * @param  {Array}  buttons   Array of objects representing size or inseam buttons.
   * @param  {String} propName "inseam" or "size". The name of the sku object property to search.
   * @param {Boolean} isBackorderConsideredInStock If true, treat out-of-stock items that are
   *                            backOrderable as in stock items. Default is false.
   * @param  {Array}  dimension Array of sku objects returned by calling getDimension on matrixCES
   *                            or matrixCESI.
   * @return {Array}            A new Array of button objects with properties for isValidOption and
   *                            isInStock.
   *
   */
  static updateButtonValidityProp({
    buttons,
    propName,
    isBackorderConsideredInStock = false,
    dimension,
  }) {
    const validButtons = {};
    const outOfStockButtons = {};
    const skuMap = {};
    const newButtons = [...buttons];

    for (let i = 0, { length } = dimension; i < length; i += 1) {
      const property = dimension[i] && dimension[i][propName];
      // getDimension returns null for skus that fail our search parameters.
      if (property && validButtons[property] === undefined) {
        validButtons[property] = 1;
      }
      if (property) {
        if (!skuMap[property]) {
          skuMap[property] = [];
        }
        skuMap[property].push(dimension[i]);
      }
    }

    const isThereAtLeastOneInStockSku = (buttonValue) => (s) =>
      s[propName] === buttonValue &&
      WithInventory.isSkuInStockHelper({
        backOrderable: s.backOrderable,
        isBackorderConsideredInStock,
        isInStockOnline: s.isInStockOnline,
      });

    for (
      let i = 0, keys = Object.keys(validButtons), { length } = keys;
      i < length;
      i += 1
    ) {
      const buttonValue = keys[i];
      if (
        outOfStockButtons[buttonValue] === undefined &&
        (!skuMap[buttonValue] ||
          !skuMap[buttonValue].some(isThereAtLeastOneInStockSku(buttonValue)))
      ) {
        outOfStockButtons[buttonValue] = 1;
      }
    }

    for (let i = 0, { length } = newButtons; i < length; i += 1) {
      const button = newButtons[i];
      const updatedButton = button;
      if (!validButtons[button[propName]]) updatedButton.isValidOption = false;
      if (outOfStockButtons[button[propName]]) updatedButton.isInStock = false;
    }

    return newButtons;
  }

  static propTypes = {
    // The withInventory HOC must wrap a React component
    component,

    // Optional: Instantiate with this product's inventory inforation.
    // Otherwise, consumers of withInventory can pass a productId to the getInventory() prop method.
    productId: PropTypes.string,

    // client is added by withApollo HOC. It is used to make graphql queries.
    client: PropTypes.shape({
      query: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    component: null,
    productId: null,
  };

  constructor(props = {}) {
    super(props);

    // ES6 promises can not be cancelled. This tells our orphaned promises not to set state.
    this.mounted = false;

    this.state = {
      ...LoadingStateManager.pristineState(),
    };

    // see if we need to add something to the queue
    const { productId } = props;
    if (productId) {
      this.state.queue = [productId];
    }

    [
      'getColor',
      'getColorSet',
      'getDefaultColorIndex',
      'getDefaultColorSlice',
      'getSkuSet',
      'getProduct',
      'getRawProductData',
      'getSizeExtensions',
      'getMatrix',
      'getDefaultSizeExtension',
      'getSizes',
      'getInseams',
      'getSelectedSku',
      'getSkuByColor',
      'getSelectedSkuBySkuId',
      'getSelectedSkuId',
      'getColorSliceImagesByColorName',
      'getCarouselImages',
      'getMatchingSetSkus',
      'getImageViewerMediaMap',
      'hasMultipleModelImageSets',
      'hasSizeExtensions',
      'hasInseams',
      'hasSizes',
      'isProductInStock',
      'isSkuInStock',
      'fetchInventory',
      'colorNameToIndex',
      'colorIndexToName',
      'isSingleSkuProduct',
      'isSingleSkuColor',
      'loadProduct',
      'fetchProduct',
      'fetchLoadingProducts',
      'loadQueuedProducts',
    ].forEach((m) => {
      this[m] = this[m].bind(this);
    });
  }

  componentDidMount() {
    this.mounted = true;
    if (this.state.queue.length > 0) this.loadQueuedProducts();
  }

  componentDidUpdate(prevProps = {}) {
    // "the pipeline" is a description of the progression of a product ID through the process
    // of fetching remote data.  You start the pipeline by adding an ID to the queue, it then
    // moves on to loading, and then moves to either error or success.
    //
    // If the nextProps productId(s) is not already in the pipeline, add it to the queue.
    // The queue spawn graphql calls in componentDidMount() and componentDidUpdate()
    // How?  if there are any values in queue, React lifecycle methods detect this and kick off
    // the loading process

    const { productId: oldProductId } = prevProps;
    const { productId: newProductId } = this.props;
    if (newProductId && oldProductId !== newProductId) {
      const isBusy =
        [
          ...this.state.queue,
          ...this.state.isInventoryError,
          ...this.state.isInventoryLoading,
          ...this.state.isInventorySuccess,
        ].indexOf(newProductId) !== -1;

      const queue = [...this.state.queue, newProductId];
      if (!isBusy && !areArraysSimilar(queue, this.state.queue)) {
        // We need to update the linting rules.  React docs say that this is allowed if you put
        // setState in a condition to prevent infinite loops.  We have this behind 2 conditions.
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ queue });
      }
    } else {
      if (this.state.queue.length > 0) this.loadQueuedProducts();
      if (this.state.isInventoryLoading.length > 0) this.fetchLoadingProducts();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  static getRawInventoryData(id, ownProps) {
    return ownProps.withInventory.product[id].inventoryData;
  }

  /**
   * Returns all the colorSlices for a given productId.  The product API stores all the skus
   * associated to this product id grouped by color, which we call "colorSlices".  You can get
   * a slice of the associated skus by referenceing a color name.  This returns all the color
   * names as an array of objects.
   *
   * @public
   * @param  {Object} arg                   A single object argument
   * @param  {String} arg.productId
   * @return {[Undefined|Array]}
   */
  getColorSet({ productId }) {
    if (!this.state.product[productId]) return undefined;
    return this.state.product[productId].colorManager.colorSet;
  }

  /**
   * Gets a color object (a single color slice) from the colorSlices for this product.
   * @public
   * @param  {Object} arg                   A single object argument
   * @param  {String} arg.productId
   * @param  {String} arg.colorName
   * @return {[Undefined|Object]}
   */
  getColor({ productId, colorName }) {
    if (!colorName || !productId) return undefined;
    if (!this.state.product[productId]) return undefined;
    return this.state.product[productId].colorManager.getColor(colorName);
  }

  /**
   * Gets a the default color index for a product.
   * @public
   * @param  {Object} arg                   A single object argument
   * @param  {String} arg.productId
   * @return {[Undefined|Number]}
   */
  getDefaultColorIndex({ productId }) {
    if (!this.state.product[productId]) return undefined;
    return this.state.product[productId].colorManager.defaultColorIndex;
  }

  /**
   * Gets a the default color slice (aka: color object} for a product. If the default is sold out,
   * find the first color slice that is not sold out.
   * @public
   * @param  {Object} arg                   A single object argument
   * @param  {String} arg.productId
   * @param  {Boolean} arg.isBackorderConsideredInStock If true, consider out of stock items that
   *                                                    are backorderable to be "in stock". They
   *                                                    default is false.
   * @return {[Undefined|Object]}
   */
  getDefaultColorSlice({
    productId,
    isBackorderConsideredInStock = false,
    overrideColor = null,
  }) {
    if (!this.state.product[productId]) return undefined;
    return this.state.product[productId].colorManager.getDefaultColor({
      isInStockOnline: false,
      isBackorderConsideredInStock,
      overrideColor,
    });
  }

  /**
   * Return a list of all the skus associated with this product.
   *
   * @public
   * @param  {Object} arg             A single object argument
   * @param  {String} arg.productId
   * @return {[Undefined|Object]}               An array of product sku objects
   */
  getSkuSet({ productId }) {
    if (!this.state.product[productId]) return undefined;
    return this.state.product[productId].colorManager.skuSet;
  }

  /**
   * A utility method that returns a product object from the array of successfully loaded products.
   * The product
   *
   * @public
   * @param  {Object} arg                   A single object argument
   * @param  {String} arg.productId
   * @return {[Object|Undefined]} product   Either undefined or a product object
   * @return {Object} product.colorManager  An instance of ColorManager.
   * @return {Object} product.inventoryData An object that contains raw inventory data.
   * @return {Object} product.matrixCES     ArrayMatrix has 3 orders: colorName, ext, size
   * @return {Object} product.matrixCESI    ArrayMatrix with 4 orders: colorName, ext, size, inseam
   * @return {Object} product.productData   The raw data returned by a getProduct graphql call.
   */
  getProduct({ productId }) {
    return this.state.product[productId];
  }

  /**
   * A utility method that returns only the raw grapql results from the getProdcut graphql call.
   *
   * @public
   * @param  {Object} arg                   A single object argument
   * @param  {String} arg.productId
   * @return {Object} product
   */
  getRawProductData({ productId }) {
    if (!this.state.product[productId]) return undefined;
    return this.state.product[productId].productData;
  }

  /**
   * Returns a list of all possible sizes extensions for this product. e.g. regular, tall, petite.
   * By default, this also includes completely out-of-stock size extensions in the list.
   *
   * @public
   * @param  {Boolean} isBackorderConsideredInStock Treat out of stock items that are on backorder
   *                                                as if they are in stock. Default is false.
   * @param  {Boolean} isInStock                    If true, only show extensions that have at least
   *                                                one in-stock sku. Default is false.
   * @param  {String}  productId                    A product id.  e.g. "8675309"
   * @param  {String}  colorName                    If an optional colorName is supplied, narrow
   *                                                down extension options based on the availability
   *                                                of that extension in the supplied color.
   * @return {[Undefined|String]}                   An array of size extension names, or an empty
   *                                                array if none.
   */
  getSizeExtensions({
    isBackorderConsideredInStock = false,
    isInStock = false,
    productId,
    colorName = null,
  }) {
    if (!this.state.product[productId]) return undefined;
    // get all the potential size extensions from the ArrayMatrix
    let retval = this.state.product[productId].matrixCES.getAxisPoints('ext');

    // if isInStock is true, filter out any extensions that don't have at least one in stock sku.
    if (isInStock) {
      retval = retval.filter((ext) =>
        this.getSkuSet({ productId })
          // some = bail after first match.  return true or false.
          .some(
            (sku) =>
              // sku's extension matches. AND
              sku.ext === ext &&
              // if we supplied a color, the color must match.
              // if we did not supply a color, don't disqualify based on color.
              // AND
              (colorName === null ||
                (colorName !== null && colorName === sku.colorName)) &&
              // The sku is in stock (or considered in stock)
              // Use various vender flags to determine what is meant by "in stock"
              // See if the sku is in stock for real, or out of stock and on backorder and the
              // BackorderConsideredInStock vendor flag is true.  See docs in isSkuInStockHelper.
              WithInventory.isSkuInStockHelper({
                backOrderable: sku.backOrderable,
                isBackorderConsideredInStock,
                isInStockOnline: sku.isInStockOnline,
              })
          )
      );
    }
    return retval;
  }

  /**
   * A utility method that returns the correct flavor of ArrayMatrix depending on whether the
   * product has skus with an inseam (e.g. mens pants), or not (shirts).
   *
   * @public
   * @see https://github.com/express-labs/javascript-array-matrix
   * @param  {Object} arg                 A single object argument
   * @param  {String} arg.productId
   * @return {ArrayMatrix}                An array matrix object.
   */
  getMatrix({ productId }) {
    const product = this.state.product[productId];
    if (!product) return undefined;
    return this.hasInseams({ productId })
      ? product.matrixCESI
      : product.matrixCES;
  }

  /**
   * Helper function to find a default extension for getSizes and getInseams if a falsey value for
   * ext was given to either of those methods.
   * @private
   * @param  {String} productId
   * @param  {String} ext       the current size extension selected on the page. ex 'petite'
   * @return {String}           'regular', 'petite', or whatever is the first ext on the list.
   */
  getDefaultSizeExtension({ ext, productId }) {
    const extensions = this.getSizeExtensions({ productId });
    const hasRegular = extensions.some((e) => e === 'regular');

    // Try to default to regular, otherwise use the first available one
    if (hasRegular && ext === 'regular') return 'regular';
    if (hasRegular) return 'regular';
    return extensions[0];
  }

  /**
   * Create a list of button object for AVAILABLE sizes for a given productId, color extension and
   * inseam.
   *
   * @public
   * @todo   refactor all instances of getSizes to getSizeButtons
   * @param  {String}  colorName                    Ex: "RED", "WILD GINGER"
   * @param  {String}  ext                          Ex: petitie, short, tall, long
   * @param  {String}  inseam                       Ex: "29", "30", "31"
   * @param  {Boolean} isBackorderConsideredInStock When true, then out of stock skus that have a
   *                                                backorder flag set to true in their data can
   *                                                still be added to the shopping cart. For all
   *                                                intents and purposes, they are considered to be
   *                                                in stock by the PDP. The default is false
   * @param  {Boolean} isInStock                    When true, buttons are only created for sizes
   *                                                that are in stock.  The default is false.
   * @param  {String}  productId                    A product Id. EX: "8675309"
   * @return {[Object]}                   An array of button objects. Button objects have a the
   *                                      following structure:
   *                                      {
   *                                        size: String,
   *                                        isValidOption: Boolean,
   *                                        isInStock: Boolean
   *                                      }
   */
  getSizes({
    colorName,
    ext,
    inseam,
    isBackorderConsideredInStock = false,
    isInStock = false,
    productId,
  }) {
    if (!this.hasSizes({ productId })) return [];
    let newExt = ext;
    if (!newExt) newExt = this.getDefaultSizeExtension({ productId, ext });
    const { colorManager, matrixCES, matrixCESI } =
      this.state.product[productId];
    const { skuSizes, skuSet } = colorManager;
    const hasInseams = this.hasInseams({ productId });
    let buttons = [];

    // Get a list of all possible sizes for this product
    // "isInStock" variable name is a little confusing.  it means we are REQUIRING that
    // a size must have at least one in-stock sku in order to be displayed as a size option.
    //
    // If isInStock is TRUE that means...
    // - only show size buttons for a size that is in stock for a given color and extension
    // If isBackorderConsideredInStock then...
    // - treat items on backorder as if they are in stock.
    // If isInStock is FALSE then...
    // - show all available size buttons.

    if (!hasInseams) {
      const dimension = matrixCES.getDimension({ colorName, ext: newExt });
      const { length } = dimension;
      for (let i = 0; i < length; i += 1) {
        // skip nulls, null means that combo does not exist
        if (dimension[i]) {
          const { size, backOrderable, isInStockOnline } = dimension[i];

          const isSkuInStock = WithInventory.isSkuInStockHelper({
            backOrderable,
            isBackorderConsideredInStock,
            isInStockOnline,
          });

          if (!isInStock || isSkuInStock) {
            buttons.push({
              size,
              isValidOption: true,
              isInStock: isSkuInStock,
            });
          }
        }
      }
    }

    if (hasInseams) {
      const { length } = skuSizes;
      for (let i = 0; i < length; i += 1) {
        const size = skuSizes[i];
        // tests to see if at least one sku is in stock for this size waist
        const isSkuInStock = WithInventory.testIsSkuInStock({
          buttonType: 'size',
          buttonValue: size,
          ext: newExt,
          isBackorderConsideredInStock,
          skuSet,
        });

        if (!isInStock || isSkuInStock) {
          buttons.push({
            size,
            isValidOption: true,
            isInStock: isSkuInStock,
          });
        }
      }
    }

    // We now have a list of size buttons.
    // If the user has selected an inseam, tweak the isValidOption and isInStock values of each btn.
    // - disable size options that are not avaialble for this size & inseam combination
    // - disable size options that are sold out for this inseam.
    if (hasInseams && inseam) {
      const dimension = matrixCESI.getDimension({
        colorName,
        ext: newExt,
        inseam,
      });

      buttons = WithInventory.updateButtonValidityProp({
        buttons,
        propName: 'size',
        isBackorderConsideredInStock,
        isInStock,
        dimension,
      });
    }

    // if we picked a colorName then futher narrow down the size options
    // - disable size options that are not avaialble for this color.
    // - disable size options that are sold out for this color.
    if (colorName) {
      const dimension = matrixCES
        .getDimension({ colorName, ext: newExt })
        .filter((sku) => sku !== null);
      buttons = WithInventory.updateButtonValidityProp({
        buttons,
        propName: 'size',
        isBackorderConsideredInStock,
        isInStock,
        dimension,
      });
    }

    return buttons;
  }

  /**
   * Find a list of AVAILABLE inseams given a product id, color, extension, and waist size.
   *
   * @public
   * @todo   refactor all instances to getAvailableInseams
   * @param  {String}  colorName                    Ex: "RED", "WILD GINGER"
   * @param  {String}  ext                          Ex: petitie, short, tall, long
   * @param  {String}  inseam                       Ex: "29", "30", "31"
   * @param  {Boolean} isBackorderConsideredInStock When true, then out of stock skus that have a
   *                                                backorder flag set to true in their data can
   *                                                still be added to the shopping cart. For all
   *                                                intents and purposes, they are considered to be
   *                                                in stock by the PDP. The default is false
   * @param  {Boolean} isInStock                    When true, buttons are only created for sizes
   *                                                that are in stock.  The default is false.
   * @param  {String}  productId                    A product Id. EX: "8675309"
   * @param  {String}  size                           Ex: "29", "30", "31"
   * @return {[Object]}                   An array of button objects. Button objects have a the
   *                                      following structure:
   *                                      {
   *                                        inseam: String,
   *                                        isValidOption: Boolean,
   *                                        isInStock: Boolean
   *                                      }
   */
  getInseams({
    colorName,
    ext,
    isBackorderConsideredInStock = false,
    isInStock = false,
    productId,
    size,
  }) {
    if (!this.hasInseams({ productId })) return [];
    let newExt = ext;
    if (!newExt) newExt = this.getDefaultSizeExtension({ productId, ext });
    if (!colorName) throw new Error('getInseams() requires colorName');

    const { colorManager, matrixCESI } = this.state.product[productId];
    const { skuInseams, skuSet } = colorManager;
    let buttons = [];

    // get list of all possible inseams for this product
    // - find out if inseam is in stock or not.
    // - remove any completely sold out inseam option.
    skuInseams.forEach((skuInseam) => {
      if (
        !isInStock ||
        WithInventory.testIsSkuInStock({
          buttonType: 'inseam',
          buttonValue: skuInseam,
          ext: newExt,
          isBackorderConsideredInStock,
          skuSet,
        })
      ) {
        buttons.push({
          inseam: skuInseam,
          isValidOption: true,
          isInStock: true,
        });
      }
    });

    // if we picked a waist size  and colorName then further narrow down inseam options
    // - disable inseam buttons that are not available in this waist size
    // - disable inseam buttons that are out of stock in this waist size
    if (this.hasSizes({ productId }) && size) {
      const dimension = matrixCESI.getDimension({
        colorName,
        ext: newExt,
        size,
      });
      buttons = WithInventory.updateButtonValidityProp({
        dimension,
        buttons,
        propName: 'inseam',
        isBackorderConsideredInStock,
      });
    }
    return buttons;
  }

  /**
   * Search the Pantseract array matrix for a particular sku that matches the supplied critera.
   *
   * If the product has inseams, then you need to supply three of the following four search
   * parameters: colorName, ext, inseam, size.
   *
   * If the product does NOT have inseams, then you need to supply two of the following search
   * parameters: colorName, ext, size.
   *
   * Use this.hasInseams() to find if the product id has inseams.
   *
   * If isInStock is true, then size, inseam, and ext options that are not in stock are treated
   * as it they are incomplete (They are ignored as if they aren't even there.)
   *
   * - If the search parameters are incomplete, return false.
   * - If isInStock is true, then out of stock sizes & inseams, return false too.
   * - If the search parameters were OK, but nothing matches, return null.
   * - If the search parameters find a sku, return a sku object.
   *
   * @public
   * @param  {String} colorName  A color name e.g. 'WILD GINGER'
   * @param  {String} ext        A size extension e.g. 'tall', 'petitie'
   * @param  {Boolean} isBackorderConsideredInStock When true, then out of stock skus that have a
   *                                                backorder flag set to true in their data can
   *                                                still be added to the shopping cart. For all
   *                                                intents and purposes, they are considered to be
   *                                                in stock by the PDP. The default is false
   * @param  {String} inseam     An inseam length for products that have inseams.
   * @param  {String} productId  A product id.  e.g. '8675309'
   * @param  {String} size       A size or waist size e.g. 'XS', '0', '32'
   * @param  {Boolean} decode    decode all urlEncoded characters in all fields. default is false
   * @return {[false|null|object]}
   */
  getSelectedSku({
    colorName,
    decode = false,
    ext = 'regular',
    inseam,
    isBackorderConsideredInStock = false,
    isInStock = false,
    productId,
    size,
  }) {
    const product = this.getProduct({ productId });
    let newSize = size;

    if (!product) return false;

    // every sku must have a colorName
    if (!colorName) return false;

    // every sku has an ext, even sunglasses or perfume.
    // The default ext is "regular"

    // if this is a single sku product, the size is "no size"
    const isSingleSkuProduct = this.isSingleSkuProduct({ productId });
    if (isSingleSkuProduct) newSize = 'no size';

    // if this is a single sku color slice (there are several colors, but they all have one sku
    // and each sku size is "no size")
    const isSingleSkuColor = this.isSingleSkuColor({ productId, colorName });
    if (isSingleSkuColor) newSize = 'no size';

    // if the product has sizes, then require a size.
    if (!isSingleSkuProduct && this.hasSizes({ productId }) && !size) {
      return false;
    }

    // -------
    // Beyond this point, colorName, ext, and newSize should not be undefined.
    // -------

    // if the product has inseams, then require an inseam.
    if (this.hasInseams({ productId }) && !inseam) return false;

    // optional decode escaped characters from a URL. e.g. %2F = /
    const decodedColorName = WithInventory.safeDecode(colorName);
    const decodedExt = WithInventory.safeDecode(ext);
    const decodedInseam = WithInventory.safeDecode(inseam);
    const decodedSize = WithInventory.safeDecode(newSize);

    const finalColorName = decode ? decodedColorName : colorName;
    const finalExt = decode ? decodedExt : ext;
    const finalInseam = decode ? decodedInseam : inseam;
    const finalSize = decode ? decodedSize : newSize;

    // if we're here, we met all the requirements to get the sku.
    const matrix = this.getMatrix({ productId });

    let selectedSku = false;

    const hasInseams = this.hasInseams({ productId });

    // This "try-catch" statement prevents the page from crashing when receiving unusual PIM data.
    // For example, the DB folks forget to transform tie skus so that they have sizes of REG and
    // TALL after restoring the database from Sterling.
    try {
      selectedSku = hasInseams
        ? matrix.getEntry({
            colorName: finalColorName,
            ext: finalExt,
            inseam: finalInseam,
            size: finalSize,
          })
        : matrix.getEntry({
            colorName: finalColorName,
            ext: finalExt,
            size: finalSize,
          });
    } catch (e) {
      // We need to send an error report to ourselves stating why the ArrayMatrix crashed.
      // It was most likely caused by a product that had "duplicate skus" or "evil twins"
      // see https://github.com/express-labs/raven-server/pull/140 for some information
      // on evil twins.
      //
      // TODO: once we settle on a replacement for New Relic, have it fire off an alert!
      // For now, just log.
      loog('ArrayMatrix encountered an error', 'info', e);
      loog('product has inseams?', 'info', hasInseams.toString());
      loog('colorName', 'info', colorName);
      loog('ext', 'info', ext);
      loog('inseam', 'info', inseam);
      loog('size', 'info', size);
    }

    // When vendor.OutOfStockSkuButtons is FALSE...
    // It means we are hiding out of stock size and inseam buttons for a given color (and extension)
    // so we return false if the selected size or inseam is for a button that is hidden on the PDP
    // for this product.
    //
    // TLDR; It mimics the pre-pantseract behavior
    //
    // This lets us keep the size in the url, but the add to cart button will not say "sold out".
    // It will say "Add to cart" and be disabled.
    if (!isSingleSkuProduct && !isSingleSkuColor) {
      if (isInStock) {
        const sizeButtons = this.getSizes({
          colorName: finalColorName,
          ext: finalExt,
          inseam: finalInseam,
          isBackorderConsideredInStock,
          isInStock,
          productId,
        });

        const isSizeButtonVisibleOnPDP = sizeButtons.some(
          (button) => button.size === finalSize
        );

        if (!isSizeButtonVisibleOnPDP) return false;
      }

      if (hasInseams && isInStock) {
        const inseamButtons = this.getInseams({
          colorName: finalColorName,
          ext: finalExt,
          size: finalSize,
          isBackorderConsideredInStock,
          isInStock,
          productId,
        });

        const isInseamButtonVisibleOnPDP = inseamButtons.some(
          (button) => button.inseam === finalInseam
        );

        if (!isInseamButtonVisibleOnPDP) return false;
      }
    }

    return selectedSku;
  }

  /**
   * Get a sku object from the sku set using a skuId.
   * @public
   * @param  {Object}  arg           A single object argument
   * @param  {String}  arg.productId A product ID.
   * @param  {String}  arg.skuId     A Sku ID.
   * @return {[Undefined|Object]}    Undefined if not found.  Otherwise a sku object.
   */
  getSelectedSkuBySkuId({ productId, skuId }) {
    if (!this.state.product[productId]) return undefined;
    if (!skuId) return undefined;
    return this.state.product[productId].colorManager.skuMap[skuId];
  }

  /**
   * Get a sku object from the sku set using name of a color.
   * @public
   * @param  {Object}  arg           A single object argument
   * @param  {String}  arg.productId A product ID.
   * @param  {String}  arg.color     A color name.
   * @param  {Boolean} arg.isBackorderConsideredInStock When true, then out of stock skus that have
   *                                                    a backorder flag set to true in their data
   *                                                    can still be added to the shopping cart. For
   *                                                    all intents and purposes,they are considered
   *                                                    to be in stock by the PDP. Default is false.
   * @return {[Undefined|Object]}    Undefined if not found.  Otherwise a sku object.
   */
  getSkuByColor({ productId, color, isBackorderConsideredInStock }) {
    if (!this.state.product[productId]) return undefined;
    if (!color) return undefined;
    return this.state.product[productId].colorManager
      .getColor(color)
      .skus.find((sku) =>
        this.isSkuInStock({
          isBackorderConsideredInStock,
          productId,
          skuId: sku.skuId,
        })
      );
  }

  /**
   * Search the Pantseract array matrix for a particular skuId that matches the supplied critera.
   * If the search parameters are incomplete, return false.
   * If isInStock is true, then out of stock sizes & inseams, return false too.
   * If the search parameters were OK, but nothing matches, return null.
   * If the search parameters find a sku, return a sku id.
   *
   * @public
   * @param  {String} colorName       A color name e.g. 'WILD GINGER'
   * @param  {String} [ext='regular'] A size extension e.g. 'tall', 'petitie'
   * @param  {String} inseam          An inseam length for products that have inseams.
   * @param  {Boolean} isInStock      changes behavior based on if we require a sku to be in stock
   * @param  {Boolean} isBackorderConsideredInStock When true, then out of stock skus that have a
   *                                                backorder flag set to true in their data can
   *                                                still be added to the shopping cart. For all
   *                                                intents and purposes, they are considered to be
   *                                                in stock by the PDP. The default is false
   * @param  {String} productId
   * @param  {String} size            A size or waist size e.g. 'XS', '0', '32'
   * @param  {Boolean} decode         decode and urlEncoded characters
   * @return {[false|null|string]}
   */
  getSelectedSkuId({
    colorName,
    decode = false,
    ext = 'regular',
    inseam,
    isBackorderConsideredInStock = false,
    isInStock = false,
    productId,
    size,
  }) {
    const sku = this.getSelectedSku({
      colorName,
      decode,
      ext,
      inseam,
      isBackorderConsideredInStock,
      isInStock,
      productId,
      size,
    });

    if (sku) return sku.skuId;
    return sku;
  }

  /**
   * Return the imageMap object for a colorSlice.  The imageMap object contains properties and
   * arrays with image URL's based on the product color and model body shape.
   * @public
   * @param  {Object}  arg              A single object argument
   * @param  {String}  arg.productId    A product ID.
   * @param  {String}  arg.colorName    The name of a color. i.e. 'WILD GINGER'
   * @return {[Undefined|Object]}       Undefined if not found. Otherwise an imageMap object.
   */
  getColorSliceImagesByColorName({
    productId,
    colorName,
    isImageViewerV2 = false,
  }) {
    if (!colorName || !productId) return undefined;
    if (!this.state.product[productId]) return undefined;
    const idx = this.colorNameToIndex({ productId, colorName });
    const colorSet = this.getColorSet({ productId });
    /* istanbul ignore next */
    const objName = isImageViewerV2 ? 'mediaMap' : 'imageMap';
    return colorSet && colorSet[idx][objName];
  }

  /**
   * Return an array of images for an item based on color name and model.  If no images can be
   * found in the color slices, return the default image.
   * @public
   * @param  {string} productId      The product's productId
   * @param  {String} colorName      A color name.  e.g. 'WILD GINGER', 'RED'
   * @param  {String} [model='All']  Which model to show. Defaults to All.
   * @return {[String]}              Returns an array of url strings.
   */
  getCarouselImages({ productId, colorName, model = 'All' }) {
    if (!this.state.product[productId]) return undefined;
    const defaultImage = this.getRawProductData({ productId }).productImage;
    const imageMap = this.getColorSliceImagesByColorName({
      productId,
      colorName,
    });
    if (
      getPath(imageMap, `${[model]}.LARGE`, []).length > 0 &&
      getPath(imageMap, `${[model]}.MAIN`, []).length > 0
    ) {
      return {
        large: imageMap[model].LARGE,
        main: imageMap[model].MAIN,
      };
    }
    return {
      large: [defaultImage],
      main: [defaultImage],
    };
  }

  getMatchingSetSkus({ productId, colorName }) {
    const { colorSlices, matchingSet } =
      this.state?.product?.[productId]?.productData || {};

    const currentActiveColorSlice = colorSlices?.find(
      (slice) => slice.color === colorName
    );

    const currentColor = currentActiveColorSlice?.color?.toLowerCase();

    if (!currentColor || !matchingSet?.length) return undefined;

    return matchingSet.find((set) => set?.color?.toLowerCase() === currentColor)
      ?.skus;
  }

  /**
   * Return an object of arrays containing image/video data to populate an image slider;
   *  plus, optionally, modelInfo data
   * for for navigating to key points in the image/video array, with model height/size labels.
   * @public
   * @param  {string} productId      The product's productId
   * @param  {String} colorName      A color name.  e.g. 'WILD GINGER', 'RED'
   */
  /* istanbul ignore next */
  getImageViewerMediaMap({ productId, colorName }) {
    if (!this.state.product[productId]) return undefined;
    const isImageViewerV2 = true;
    return this.getColorSliceImagesByColorName({
      productId,
      colorName,
      isImageViewerV2,
    });
  }

  /**
   * Looks at the image map for All to determine if the product supports extended sizes.
   * NOTE: "Extended Sizes" are sizes like XXL, XXS, XXXL, etc.  "Size Extensions" are in the URL
   * and some examples are regular, tall, long, peteite.  Don't be confused.
   *
   * @public
   * @param  {Object}  arg              A single object argument
   * @param  {String}  arg.productId    A product ID.
   * @param  {String}  arg.colorName    The name of a color. i.e. 'WILD GINGER'
   * @return {[Undefined|Boolean]}       Undefined if not found. Otherwise true or false.
   */
  hasMultipleModelImageSets({ productId, colorName }) {
    if (!colorName || !productId) return undefined;
    if (!this.state.product[productId]) return undefined;
    const selectedColor = this.getColorSliceImagesByColorName({
      productId,
      colorName,
    });

    const model2ArrayLength = getPath(selectedColor, 'Model2.LARGE.length', 0);
    const model3ArrayLength = getPath(selectedColor, 'Model3.LARGE.length', 0);
    // if the All length exists, it supports extended sizes
    return model2ArrayLength > 0 || model3ArrayLength > 0;
  }

  /**
   * If the product size extensions besides "regular" and "petite" we consider it to have
   * size extensions. petitie is technically a size extension, but they have their own PDP pages
   * for now.
   * @param  {[type]}  productId                    A product ID. e.g. "8675309"
   * @param  {Boolean} isInStock                    Require a size extension to have at least one
   *                                                in stock sku.
   * @param  {Boolean} isBackorderConsideredInStock Treat out of stock skus that are on back order
   *                                                as if they are in stock.
   * @return {Boolean}                              {[Undefined|Boolean]}
   */
  hasSizeExtensions({
    isBackorderConsideredInStock = false,
    isInStock = false,
    productId,
  }) {
    if (!this.state.product[productId]) return undefined;
    const sizeExtensions = this.getSizeExtensions({
      isBackorderConsideredInStock,
      isInStock,
      productId,
    });
    const ignoreIfOnlyOne = ['regular'];

    if (!sizeExtensions.length) return false;

    // if the only size extension on in the ignore list, say there are none.
    // Example: prevents "regular" showing up for products that only have regular, like sunglasses.
    if (
      sizeExtensions.length === 1 &&
      ignoreIfOnlyOne.indexOf(sizeExtensions[0] !== -1)
    ) {
      return false;
    }

    return true;
  }

  /**
   * Determine if the product has any skus with an inseam value.
   *
   * @public
   * @param  {Object}  arg           A single object argument
   * @param  {String}  arg.productId A product ID.
   * @return {[Undefined|Boolean]}   True if the product has a single sku with a non-null inseam.
   */
  hasInseams({ productId }) {
    if (!this.state.product[productId]) return undefined;
    return !!this.state.product[productId].colorManager.skuInseams.length;
  }

  /**
   * Determine if the product has any skus in any color and any size extension with a size value
   * other than just "no size".
   *
   * @public
   * @param  {Object}  arg           A single object argument
   * @param  {String}  arg.productId A product ID.
   * @return {[Undefined|Boolean]}   True if the product has a single sku with a non-null size.
   */
  hasSizes({ productId }) {
    if (!this.state.product[productId]) return undefined;
    const { skuSizes } = this.state.product[productId].colorManager;
    const hasLength = !!skuSizes.length;
    return hasLength && !(skuSizes.length === 1 && skuSizes[0] === 'no size');
  }

  /**
   * Determine if the product has at least one sku in stock. If not, the product is Sold Out.
   *
   * @public
   * @param  {Object}  arg           A single object argument
   * @param  {String}  arg.productId A product ID.
   * @return {[Undefined|Boolean]}   True if the product has at least one sku in stock..
   */
  isProductInStock({ productId, isBackorderConsideredInStock = false }) {
    if (!this.state.product[productId]) return undefined;
    return this.getSkuSet({ productId }).some((sku) =>
      WithInventory.isSkuInStockHelper({
        backOrderable: sku.backOrderable,
        isBackorderConsideredInStock,
        isInStockOnline: sku.isInStockOnline,
      })
    );
  }

  /**
   * Determine if a skuId is in stock.  In stock can also mean out of stock but back orderable
   * depending on the value for isBackorderConsideredInStock.
   *
   * @param  {String}  productId                    A product ID.
   * @param  {String}  skuId                        A Sku ID.
   * @param  {Boolean} isBackorderConsideredInStock Treat an out of stock sku that is on backorder
   *                                                as an in-stock sku. Default is false.
   * @return {[Undefined|Boolean]}                  Undefined if the product id or sku id was not
   *                                                found. If found, true or false if the
   *                                                product is considered in stock or not.
   */
  isSkuInStock({ isBackorderConsideredInStock = false, productId, skuId }) {
    if (!this.state.product[productId]) return undefined;
    const sku = this.getSelectedSkuBySkuId({ productId, skuId });
    if (!sku) return undefined;
    return WithInventory.isSkuInStockHelper({
      backOrderable: sku.backOrderable,
      isBackorderConsideredInStock,
      isInStockOnline: sku.isInStockOnline,
    });
  }

  /**
   * Make a call to the product and inventory API's and store the data in the withInventory object.
   * This adds a product ID to the queue array, which begins the process of loading data.
   * To track the loading state of the query, examine withInventory's queue, isInventoryLoading,
   * isInventoryError, and isInventorySuccess arrays.
   *
   * The withInventory() HOC requires a productId at runtime. This method allow you to load data
   * on a product based on a user click, or programatically when a modal opens.  e.g. express view.
   *
   * @public
   * @param  {Object}  arg           A single object argument
   * @param  {String}  arg.productId A product ID.
   * @return void
   */
  fetchInventory({ productId }) {
    this.setState((state) =>
      LoadingStateManager.transitionToQueue(state, productId)
    );
  }

  /**
   * Return a colorSlice array index for the supplied colorName string.
   *
   * @public
   * @param  {Object} arg           A single object argument
   * @param  {String} arg.productId A product Id
   * @param  {String} arg.colorName An unescaped color name. No html entities. i.e. %20
   * @return {[Undefined|Number]}   An index number for the color in the colorSet array.
   */
  colorNameToIndex({ productId, colorName }) {
    if (!this.state.product[productId]) return undefined;
    return this.state.product[productId].colorManager.colorIdx[colorName];
  }

  /**
   * Return a colorSlice array index for the supplied colorName string.
   *
   * @public
   * @param  {Object} arg            A single object argument
   * @param  {String} arg.productId  A product Id
   * @param  {String} arg.colorIndex An array index number for the colorSet array.
   * @return {[Undefined|String]}    The color name that corresponds to this index in colorSet.
   */
  colorIndexToName({ productId, colorIndex }) {
    if (!this.state.product[productId]) return undefined;
    return this.state.product[productId].colorManager.colorSet[colorIndex]
      .color;
  }

  /**
   * Determine if the product contains a single sku that has a size besides just "no size"
   *
   * @public
   * @param  {Object}  arg           A single object argument
   * @param  {String}  arg.productId A product ID.
   * @return {Boolean}               True if there is a single sku.
   */
  isSingleSkuProduct({ productId }) {
    const skuSet = this.getSkuSet({ productId });
    return skuSet?.length === 1 && skuSet[0]?.sizeName === 'No Size';
  }

  /**
   * Some products, like sunglasses, ties, have multiple colors, but each color has a single sku,
   * with a sizename of "no size"
   *
   * @public
   * @param  {Object}  arg           A single object argument
   * @param  {String}  arg.productId A product ID.
   * @param  {String}  arg.colorName color name
   * @return {[Undefined, Boolean]}  True if there is a single sku.
   */
  isSingleSkuColor({ productId, colorName }) {
    if (!productId) return undefined;
    if (!colorName) return undefined;
    const skuSet = this.getSkuSet({ productId }).filter(
      (sku) => sku.colorName === colorName
    );
    return skuSet.length === 1 && skuSet[0].sizeName === 'No Size';
  }

  /**
   * Moves a product ID from the Queue to Loading to signify that the data for this product is
   * loading.  Product ID's move from Queue -> Loading -> Success or Error
   *
   * @private
   * @param  {String} productId [description]
   * @return {Promise}           [description]
   */
  loadProduct(productId) {
    this.setState((state) =>
      LoadingStateManager.transitionQueueToLoading(state, productId)
    );
  }

  /**
   * Make calls to API endpoints to get product and inventory information. Updates the state based
   * on success or failure.  If sucessful create a Martix object.  This is the heart of
   * withInventory.
   *
   * Product ID's move from Queue -> Loading -> Success or Error
   *
   * @private
   * @param  {string} productId A product ID to fetch.
   * @return {Promise}          Returns a promise.
   */
  fetchProduct(productId) {
    const variables = { productId };
    let inventoryData;
    let productData;

    const getInventoryPromise = this.props.client
      .query({ query: getInventory, variables, testFlag: 'inventory' })
      .then(
        (response) => {
          inventoryData = response;
        },
        () => {
          inventoryData = WithInventory.safelyRejectInventoryPromise();
        }
      );
    const getProductPromise = this.props.client
      .query({ query: getProduct, variables, testFlag: 'product' })
      .then((response) => {
        productData = response;
      });

    const allPromise = new Promise((resolve, reject) => {
      Promise.all([getProductPromise, getInventoryPromise])
        .then(() => {
          const matrix = new Matrix({
            inventoryData,
            productData,
          });

          if (this.mounted) {
            this.setState((state) =>
              LoadingStateManager.transitionLoadingToSuccess(state, productId, {
                ...matrix,
              })
            );
          }

          resolve(matrix);
        })
        .catch((error) => {
          /* istanbul ignore else */
          if (this.mounted) {
            this.setState(WithInventory.fetchProductError(productId, error));
          }

          // Since the change to CT and the Cloud, the Product Nova returns 400 for product IDs that
          // cannot be found, or no longer exist.  Previously in ATG, we returned a 404.
          // We need to capture 400 responses and resolve them without throwing an error.
          // This will prevent products that are not found from triggering an ErrorBoundary since
          // and mistakenly showing an "Oops" Error 500 page instead of a "Not Found" Error 400
          // page.
          const statusCode =
            getPath(error, 'networkError.response.status', null) ||
            getPath(error, 'graphQLErrors.0.extensions.statusCode', null);

          if (statusCode === 400) {
            return resolve(error);
          }

          return reject(error);
        });
    });

    // The next line is used by tests. It is useful for integration tests.
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.fetchPromisePromise = allPromise;

    // useful for unit tests
    return allPromise;
  }

  /**
   * Calls fetchProduct for product id's in the isInventoryLoading array
   * Product ID's move from Queue -> Loading -> Success or Error
   *
   * @private
   * @return {void}
   */
  fetchLoadingProducts() {
    const max = this.state.isInventoryLoading.length;
    const { isInventorySuccess } = this.state;
    let i;
    for (i = 0; i < max; i += 1) {
      if (isInventorySuccess.indexOf(this.state.isInventoryLoading[i]) === -1) {
        this.fetchProduct(this.state.isInventoryLoading[i]);
      }
    }
  }

  /**
   * Calls loadProduct on any product id's in the queue
   * Product ID's move from Queue -> Loading -> Success or Error
   *
   * @private
   * @return {void}
   */
  loadQueuedProducts() {
    const max = this.state.queue.length;
    const { isInventoryLoading } = this.state;
    let i;
    for (i = 0; i < max; i += 1) {
      if (isInventoryLoading.indexOf(this.state.queue[i]) === -1) {
        this.loadProduct(this.state.queue[i]);
      }
    }
  }

  render() {
    const { component: WrappedComponent, ...filteredProps } = this.props;

    const isLoading = !!(
      this.state.queue.length || this.state.isInventoryLoading.length
    );
    const isError = !!this.state.isInventoryError.length;

    return (
      <WrappedComponent
        {...filteredProps}
        fetchInventory={this.fetchInventory}
        withInventory={{
          ...this.state,
          colorIndexToName: this.colorIndexToName,
          colorNameToIndex: this.colorNameToIndex,
          fetchInventory: this.fetchInventory,
          getCarouselImages: this.getCarouselImages,
          getImageViewerMediaMap: this.getImageViewerMediaMap,
          getColor: this.getColor,
          getColorSet: this.getColorSet,
          getColorSliceImagesByColorName: this.getColorSliceImagesByColorName,
          getDefaultColorIndex: this.getDefaultColorIndex,
          getDefaultColorSlice: this.getDefaultColorSlice,
          getInseams: this.getInseams,
          getMatrix: this.getMatrix,
          getMatchingSetSkus: this.getMatchingSetSkus,
          getProduct: this.getProduct,
          getRawInventoryData: WithInventory.getRawInventoryData,
          getRawProductData: this.getRawProductData,
          getSelectedSku: this.getSelectedSku,
          getSkuByColor: this.getSkuByColor,
          getSelectedSkuBySkuId: this.getSelectedSkuBySkuId,
          getSelectedSkuId: this.getSelectedSkuId,
          getSizeExtensions: this.getSizeExtensions,
          getSizes: this.getSizes,
          getSkuSet: this.getSkuSet,
          hasInseams: this.hasInseams,
          hasMultipleModelImageSets: this.hasMultipleModelImageSets,
          hasSizeExtensions: this.hasSizeExtensions,
          hasSizes: this.hasSizes,
          isError,
          isLoading,
          isProductInStock: this.isProductInStock,
          isSingleSkuColor: this.isSingleSkuColor,
          isSingleSkuProduct: this.isSingleSkuProduct,
          isSkuInStock: this.isSkuInStock,
        }}
      />
    );
  }
}

/**
 * withInventory higher order component.
 *
 * @param  {React.Component} WrappedComponent
 * @param  {Object} options          The options must have a productId property.
 *                                   TODO: Any other properties are optional and will be used to
 *                                   help determine a selected sku, color, etc.
 * @return {React.Component}         A new React component with inventory props addded.
 */
function withInventory(WrappedComponent, options) {
  if (!WrappedComponent) {
    throw new Error(
      'withInventory() HOC is missing required component argument.'
    );
  }

  const hoc = (props) => {
    const optionProps = {};

    // ownProps
    Object.keys(options).forEach((key) => {
      if (typeof options[key] === 'function') {
        optionProps[key] = options[key](props);
      }
    });

    if (!optionProps.productId) {
      throw new Error(
        'withInventory() HOC missing required object argument.  This object must have a productId property. For example: withInventory({ productId: ownProps => ownProps.productId })'
      );
    }

    return (
      <WithInventory
        {...props}
        key={optionProps.productId}
        component={WrappedComponent}
        productId={optionProps.productId}
      />
    );
  };

  hoc.displayName = 'withInventory';

  return hoc;
}

function withInventoryFactory() {
  return (options) => (Component) =>
    withApollo(withInventory(Component, options));
}

export default withInventoryFactory();
