export default class PdpUrlNormalizer {
  static isUrlParamRequired(matrix, axisName, filter) {
    return (
      matrix
        .getAxisPoints(axisName)
        .filter((ext) => ext.toLowerCase() !== filter).length > 0
    );
  }

  static isValidParam(matrix, axisName, value) {
    return value && matrix.getAxisPoints(axisName).indexOf(value) !== -1;
  }

  static getValidParam(matrix, axisName, value, defaultValue) {
    const decodedValue = decodeURIComponent(value);
    return PdpUrlNormalizer.isValidParam(matrix, axisName, decodedValue)
      ? decodedValue
      : defaultValue;
  }

  static isValidExtension(sizeExtension, matrix) {
    return (
      sizeExtension &&
      matrix.getAxisPoints('sizeExtension').indexOf(sizeExtension) !== -1
    );
  }

  /**
   * See if the selected color name is sold out. If it is sold out, change the color to the
   * supplied color name.
   * @param  {Array} colorSet           Array of color objects from ColorMananger
   * @param  {String} colorName         The name of the color to test to see if it's in stock
   * @param  {String} defaultColorName  If not in stock, use this color.
   * @return {String}                   A colorName.
   */
  static reconcileColorWithInventory(colorSet, colorName, defaultColorName) {
    const selectedColorObject = colorSet.find(
      (colorObject) => colorObject.color === colorName
    );
    if (selectedColorObject && selectedColorObject.hasOnlineSkus === true)
      return colorName;
    return defaultColorName;
  }

  /**
   * Feed this withRouter.match.params and get a normalized PDP URL.  By normalized, we mean that
   * it will have a shape that varies based on the type of product, but those shape variations are
   * standardized. They will always match one of the page routes described in our routing table.
   * Invalid url segments are replaced with default values where applicable.  For example, if a
   * color does not exist, use the default color for that sku.
   *
   * If you are not using withRouter, you can manually set the params.  They will be normalized.
   *
   * @param  {Object} params   withRouter.match.params
   * @param  {object} product  withInventory.product[productId] created by withInventory HOC.
   * @return {string}          A normalized URL.
   */
  static normalize(params, product) {
    // If we specifically set a "root" use it.
    // Otherwise, we are most likely passing in withRouter's params object.  Extract the root
    // from the params.  (all the stuff before "/pro" in the URL is what we're calling the root)
    const root = params.beforePro || params[0];

    const { productId, color, ext, size, inseam, model } = params;

    const { getDefaultColorName, matrixCESI } = product;

    const path = {};

    // get the default color name.
    // isInStockOnline === false means...
    // - We try to find the default color slice
    // - If that color slice is out of stock, we find the first in stock color slice.
    // - If no color slices are in stock, revert back to the default color slice.
    const defaultColorName = getDefaultColorName({ isInStockOnline: false });

    // all PDP url's require a productId, obviously.
    path.pro = productId;

    // all PDP url's also require a color.
    path.color = PdpUrlNormalizer.getValidParam(
      matrixCESI,
      'colorName',
      color,
      defaultColorName
    );

    // we only show colors that are in stock.
    const { colorSet } = product.colorManager;
    path.color = PdpUrlNormalizer.reconcileColorWithInventory(
      colorSet,
      path.color,
      defaultColorName
    );

    // size extensions only required in PDP url if skus have any extension besides just "regular"
    if (PdpUrlNormalizer.isUrlParamRequired(matrixCESI, 'ext', 'regular')) {
      const { skuExtensions } = product.colorManager;

      const defaultExt = skuExtensions.find((dext) => dext === 'regular')
        ? 'regular'
        : skuExtensions.find((dext) => dext !== 'regular');

      path.e = PdpUrlNormalizer.getValidParam(
        matrixCESI,
        'ext',
        ext,
        defaultExt
      );
    }

    // optional size may only be in url if skus have any size besides just "no size"
    if (PdpUrlNormalizer.isUrlParamRequired(matrixCESI, 'size', 'no size')) {
      path.size = PdpUrlNormalizer.getValidParam(
        matrixCESI,
        'size',
        size,
        null
      );
    }

    // optional inseam may only be in url if skus have any inseam values besides null
    if (PdpUrlNormalizer.isUrlParamRequired(matrixCESI, 'inseam', 'null')) {
      path.i = PdpUrlNormalizer.getValidParam(
        matrixCESI,
        'inseam',
        inseam,
        null
      );
    }

    // see if they had a model selected
    // TODO: untaint this value, assign a default model if applicable.
    path.m = model;

    // assemble the new URL
    const untaintedPathname = `/${root}${['pro', 'color', 'e', 'size', 'i', 'm']
      .filter((param) => path[param])
      .map((param) => `${param}/${encodeURIComponent(path[param])}`)
      .join('/')}/`;

    return untaintedPathname;
  }
}
