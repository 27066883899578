import gql from 'graphql-tag';

export const schema = `
  getEvergreenMessage{
    headerProperty{
      logoImage,
      evergreenMessage,
      linkText,
      linkHref,
      searchPageUrl
    }
  }
`;

const query = gql`
  query getEvergreenMessage {
    ${schema}
  }
`;

export default query;
