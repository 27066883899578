/**
 * A simple state machine that transitions a productId from queue to loading to success/error
 * It creates a state object that can be used in a react component setState call.
 *
 * By state machine, I mean that a productId can only ever be in one condition at a time:
 * - queue,
 * - loading
 * - success
 * - error
 *
 * You move a product from one state to another state by using the "transition methods" below:
 *  - transitionToQueue
 *  - transitionQueueToLoading
 *  - transitionLoadingToSuccess
 *  - transitionLoadingToError
 *
 * When you transition a productId using a transition method, all other data associated with that
 * productId is removed from all other state properties that are not the target of the transition.
 *
 * @type {Class}
 */
export default class LoadingStateManager {
  /**
   * This method shares the same name as an npm module we also use on the site, "deepFreeze".  It's
   * a clone.  However, this version does not attempt to freeze methods that are functions. It's
   * not needed in this context, and it also blows up IE 11.  Stupid IE 11.
   *
   * We need to freeze state to prevent developers from accidently mutating the state in their code.
   * This is a direct replacement for React's state.  State in in react cannot be mutated. React
   * freezes their state, therefore we must as well.
   * @param  {Object} o An object. We will recursivley freeze all non-function properties.
   * @return {Object}   A frozen object.
   */
  static deepFreeze(o) {
    Object.freeze(o);

    Object.getOwnPropertyNames(o).forEach((prop) => {
      if (
        o.hasOwnProperty.call(o, prop) &&
        o[prop] !== null &&
        typeof o[prop] === 'object' &&
        !Object.isFrozen(o[prop])
      ) {
        LoadingStateManager.deepFreeze(o[prop]);
      }
    });

    return o;
  }

  static pristineState() {
    return {
      // an object where the keys are productIds and the values are the errors for that productId.
      inventoryErrors: {},

      // a list of productIds that failed to load
      isInventoryError: [],

      // a list of productIds that are currently loading
      isInventoryLoading: [],

      // a list of productIds that have loaded successfully
      isInventorySuccess: [],

      // successfully loaded data is stored here
      product: {},

      // a list of productIds that need to be loaded
      queue: [],
    };
  }

  static filterObject(state, productId, propName) {
    const obj = {};
    Object.keys(state[propName])
      .filter((key) => key !== productId)
      .forEach((key) => {
        obj[key] = state[propName][key];
      });
    return obj;
  }

  static insertObject(state, productId, propName, data) {
    const obj = { ...state[propName] };
    obj[productId] = data;
    return obj;
  }

  static insert(state, productId, propName) {
    return state[propName].indexOf(productId) === -1
      ? [...state[propName], productId]
      : [...state[propName]];
  }

  static filter(state, productId, propName) {
    return state[propName].filter((key) => key !== productId);
  }

  static delete(state, productId) {
    return LoadingStateManager.deepFreeze({
      inventoryErrors: LoadingStateManager.filterObject(
        state,
        productId,
        'inventoryErrors'
      ),
      isInventoryError: LoadingStateManager.filter(
        state,
        productId,
        'isInventoryError'
      ),
      isInventoryLoading: LoadingStateManager.filter(
        state,
        productId,
        'isInventoryLoading'
      ),
      isInventorySuccess: LoadingStateManager.filter(
        state,
        productId,
        'isInventorySuccess'
      ),
      product: LoadingStateManager.filterObject(state, productId, 'product'),
      queue: LoadingStateManager.filter(state, productId, 'queue'),
    });
  }

  static transitionToQueue(state, productId) {
    return LoadingStateManager.deepFreeze({
      inventoryErrors: LoadingStateManager.filterObject(
        state,
        productId,
        'inventoryErrors'
      ),
      isInventoryError: LoadingStateManager.filter(
        state,
        productId,
        'isInventoryError'
      ),
      isInventoryLoading: LoadingStateManager.filter(
        state,
        productId,
        'isInventoryLoading'
      ),
      isInventorySuccess: LoadingStateManager.filter(
        state,
        productId,
        'isInventorySuccess'
      ),
      product: LoadingStateManager.filterObject(state, productId, 'product'),
      queue: LoadingStateManager.insert(state, productId, 'queue'),
    });
  }

  static transitionQueueToLoading(state, productId) {
    return LoadingStateManager.deepFreeze({
      inventoryErrors: LoadingStateManager.filterObject(
        state,
        productId,
        'inventoryErrors'
      ),
      isInventoryError: LoadingStateManager.filter(
        state,
        productId,
        'isInventoryError'
      ),
      isInventoryLoading: LoadingStateManager.insert(
        state,
        productId,
        'isInventoryLoading'
      ),
      isInventorySuccess: LoadingStateManager.filter(
        state,
        productId,
        'isInventorySuccess'
      ),
      product: LoadingStateManager.filterObject(state, productId, 'product'),
      queue: LoadingStateManager.filter(state, productId, 'queue'),
    });
  }

  static transitionLoadingToSuccess(state, productId, data) {
    return LoadingStateManager.deepFreeze({
      inventoryErrors: LoadingStateManager.filterObject(
        state,
        productId,
        'inventoryErrors'
      ),
      isInventoryError: LoadingStateManager.filter(
        state,
        productId,
        'isInventoryError'
      ),
      isInventoryLoading: LoadingStateManager.filter(
        state,
        productId,
        'isInventoryLoading'
      ),
      isInventorySuccess: LoadingStateManager.insert(
        state,
        productId,
        'isInventorySuccess'
      ),
      product: LoadingStateManager.insertObject(
        state,
        productId,
        'product',
        data
      ),
      queue: LoadingStateManager.filter(state, productId, 'queue'),
    });
  }

  static transitionLoadingToError(state, productId, error) {
    return LoadingStateManager.deepFreeze({
      inventoryErrors: LoadingStateManager.insertObject(
        state,
        productId,
        'inventoryErrors',
        error
      ),
      isInventoryError: LoadingStateManager.insert(
        state,
        productId,
        'isInventoryError'
      ),
      isInventoryLoading: LoadingStateManager.filter(
        state,
        productId,
        'isInventoryLoading'
      ),
      isInventorySuccess: LoadingStateManager.filter(
        state,
        productId,
        'isInventorySuccess'
      ),
      product: LoadingStateManager.filterObject(state, productId, 'product'),
      queue: LoadingStateManager.filter(state, productId, 'queue'),
    });
  }
}
