import { checkIsEGiftCardOnly } from '@express-labs/raven-tools';
import { isHeaderEnvProduction } from '../../constants';

export const countryCode = 'US';
export const currencyCode = 'USD';
export const intent = 'AUTHORIZE';
export const locale = 'en-US';
export const softDescriptor = 'ECHI5786786'; // need to check with product team what value to pass

export function formatExpAdresstoAurusAddress(address, contact) {
  return {
    firstName: address?.firstName || '',
    lastName: address?.lastName || '',
    emailAddress: contact?.email || '',
    street: address?.line1 || '',
    street2: address?.line2 || '',
    city: address?.city || '',
    state: address?.state || '',
    postalCode: address?.postalCode || '',
    countryCode: address?.country || '',
    phoneNumber: contact?.phone || '',
  };
}

export function stringify(numb) {
  if (typeof numb === 'number') {
    return numb.toFixed(2);
  }
  if (typeof numb === 'string') {
    return numb;
  }
  return '0.00';
}

export function formatLineItemsToAurusItems({
  lineItems,
  taxAmount,
  shippingAmount,
  discountTotal = 0,
  coloradoDeliveryFee,
  charityAmount,
  marketplaceShippingAmount,
  giftCardPaidAmount = 0,
}) {
  const taxItem = {
    reference: '19-404',
    name: 'Tax',
    quantity: '1',
    totalAmount: stringify(taxAmount),
    totalDiscountAmount: '0',
    type: 'sales_tax',
    price: {
      amount: stringify(taxAmount),
      currency_code: currencyCode,
    },
    product_url: 'https://www.express.com/',
    image_url: 'https://www.express.com/',
  };

  const shippingItem = {
    reference: '19-405',
    name: 'Shipping',
    quantity: '1',
    totalAmount: stringify(shippingAmount),
    totalDiscountAmount: '0',
    type: 'shipping_fee',
    price: {
      amount: stringify(shippingAmount),
      currency_code: currencyCode,
    },
    product_url: 'https://www.express.com/',
    image_url: 'https://www.express.com/',
  };

  const marketplaceShippingItem = {
    reference: '19-408',
    name: 'Marketplace Shipping',
    quantity: 1,
    totalAmount: stringify(marketplaceShippingAmount),
    totalDiscountAmount: '0',
    totalTaxAmount: '0',
    type: 'shipping_fee',
    taxRate: '0',
    price: {
      amount: stringify(marketplaceShippingAmount),
      currency_code: currencyCode,
    },
    // Need to add for PayPal. Discuss with Aurus
    description: {
      item_description: '',
      item_category: 'FEES',
    },
    product_url: 'https://www.express.com/',
    image_url: 'https://www.express.com/',
  };

  const discountItem = {
    reference: '19-402',
    name: 'Discount',
    quantity: '1',
    totalAmount: stringify(-discountTotal),
    totalDiscountAmount: '0',
    type: 'discount',
    price: {
      amount: stringify(-discountTotal),
      currency: currencyCode,
    },
    product_url: 'https://www.express.com/',
    image_url: 'https://www.express.com/',
  };

  const coloradoFeesItem = {
    reference: '19-406',
    name: 'Colorado Retail Delivery Fee',
    quantity: 1,
    totalAmount: stringify(coloradoDeliveryFee),
    totalDiscountAmount: '0',
    totalTaxAmount: '0',
    type: 'shipping_fee',
    taxRate: '0',
    price: {
      amount: stringify(coloradoDeliveryFee),
      currency_code: currencyCode,
    },
    // Need to add for PayPal. Discuss with Aurus
    description: {
      item_description: '',
      item_category: 'FEES',
    },
    product_url: 'https://www.express.com/',
    image_url: 'https://www.express.com/',
  };

  const donationItem = {
    reference: '19-407',
    name: 'Charity Donation',
    quantity: 1,
    totalAmount: stringify(charityAmount),
    totalDiscountAmount: '0',
    totalTaxAmount: '0',
    type: 'surcharge',
    taxRate: '0',
    price: {
      amount: stringify(charityAmount),
      currency_code: currencyCode,
    },
    // Need to add for PayPal. Discuss with Aurus
    description: {
      item_description: '',
      item_category: 'PHYSICAL_GOODS',
    },
    product_url: 'https://www.express.com/',
    image_url: 'https://www.express.com/',
  };

  const giftCardItem = {
    reference: '19-405',
    name: 'Gift Card',
    quantity: '1',
    totalAmount: stringify(-giftCardPaidAmount),
    type: 'gift_card',
    price: {
      amount: stringify(-giftCardPaidAmount),
      currency_code: 'USD',
    },
    product_url: 'https://www.express.com/',
    image_url: 'https://www.express.com/',
  };

  if (lineItems && Array.isArray(lineItems)) {
    const aurusLineItems = lineItems.map((lineItem) => {
      const product = lineItem?.product || {};
      const name = product?.name || '';
      const sku = product?.sku || {};
      const skuId = sku?.skuId || '';
      const currentPrice = sku?.currentPrice
        ? sku?.currentPrice.substring(1)
        : '0.00';
      const itemPrice = lineItem.price?.displayAmount.substring(1) || '0.00'; // already accounts for item quantity
      const quantity = lineItem?.quantity || 0;
      const productImage = product?.productImage || '';
      const productURL = product?.productURL
        ? `https://${window.location.host}${product?.productURL}`
        : '';
      const totalDiscount = currentPrice * quantity - itemPrice;
      const totalDiscountAmount =
        totalDiscount > 0 ? totalDiscount.toFixed(2) : '0.00';

      return {
        reference: skuId,
        name,
        quantity: `${quantity}`,
        totalAmount: itemPrice,
        totalDiscountAmount,
        totalTaxAmount: '0',
        type: 'physical',
        taxRate: '0',
        price: {
          amount: currentPrice,
          currency_code: currencyCode,
        },
        // Need to add for PayPal. Discuss with Aurus
        description: {
          item_description: '',
          item_category: 'PHYSICAL_GOODS',
        },
        product_url: productURL,
        image_url: productImage,
      };
    });

    aurusLineItems.push(shippingItem);
    aurusLineItems.push(taxItem);
    aurusLineItems.push(discountItem);
    aurusLineItems.push(giftCardItem);
    if (coloradoDeliveryFee) {
      aurusLineItems.push(coloradoFeesItem);
    }
    if (charityAmount) {
      aurusLineItems.push(donationItem);
    }
    if (marketplaceShippingAmount) {
      aurusLineItems.push(marketplaceShippingItem);
    }
    return aurusLineItems;
  }
  return [];
}

export function checkIsOrderBopisOnly(orderSummary) {
  const lineItems = orderSummary?.lineItems || [];
  if (lineItems && lineItems.length > 0) {
    return lineItems.every((item) => item?.isStorePickup === true);
  }
  return false;
}

export function getShippingOptionsForAurus({
  shippingMethods,
  selectedShippingMethod,
  isOrderBopisOnly,
}) {
  const defaultShippingMethod = 'U.S. Standard';
  const defaultShippingMethodCost = '8.00';

  if (shippingMethods && Array.isArray(shippingMethods)) {
    let applePayMethods;
    if (isOrderBopisOnly) {
      applePayMethods = shippingMethods.filter(
        (method) => method.name === 'CustomerPickup'
      );
    } else {
      applePayMethods = shippingMethods.filter(
        (method) => method.name !== 'CustomerPickup'
      );
    }

    return applePayMethods.map((shippingMethod) => ({
      label: shippingMethod.name,
      amount: shippingMethod.value,
      detail: shippingMethod.estimatedDeliveryMessage || '',
      identifier: shippingMethod.id,
      selected: selectedShippingMethod
        ? selectedShippingMethod.includes(shippingMethod.name)
        : false,
    }));
  }

  return [
    {
      label: defaultShippingMethod,
      amount: defaultShippingMethodCost,
      detail: 'Arrives in 5-7 days',
      identifier: 'standardShipping',
      selected: true,
    },
  ];
}

export function getAurusEnv() {
  const aurusEnv = isHeaderEnvProduction() ? 'live' : 'sandbox';
  return aurusEnv;
}
export function getAurusClientId() {
  const aurusClientId = isHeaderEnvProduction()
    ? '4119240189690023'
    : '1044024059320028';
  return aurusClientId;
}

export function getStorePickupBlock(orderSummary, isOrderBopisOnly) {
  const { orderStore } = orderSummary?.customerStoreInfo || {};
  const splitAddress = orderStore?.addressLine1?.match(/[a-zA-Z]+|[0-9]+/g);
  const [streetNumber, ...streetName] = splitAddress || [];

  if (streetNumber && isOrderBopisOnly) {
    return {
      shipping_method: 'store pick-up',
      shipping_type: 'express',
      first_name: orderSummary.pickupOrderInformation?.firstName,
      last_name: orderSummary.pickupOrderInformation?.lastName,
      street_address: streetName.join(' '),
      street_number: streetNumber,
      postal_code: orderStore?.postalCode,
      city: orderStore?.city,
      country: orderStore?.country,
    };
  }
  return {};
}

function getAurusConsumerObj({
  apmMatrix,
  isExpressCheckout,
  isBillingAllowed,
  isContactAllowed,
  isShippingAllowed,
  hideShipping,
  orderSummary,
  sessionId,
  skipBilling,
  skipShipping,
}) {
  const priceDetails = orderSummary?.priceDetails || {};
  const billingAddress = orderSummary?.billingAddress || {};
  const contactInfo = orderSummary?.contactInfo || {};
  const giftWrap = priceDetails?.giftWrapAmount || 0;
  const lineItems = orderSummary?.lineItems;
  const orderNumber = orderSummary?.orderNumber || '';
  const appliedPayments = priceDetails?.appliedPaymentsTotal?.amount;
  const orderTransactionalSavings =
    priceDetails?.orderTransactionalSavings?.amount || 0;
  const orderPromotionTotal = priceDetails?.orderPromotionTotal?.amount || 0;
  const processingFee = priceDetails?.processingFee?.amount || 0;
  const coloradoFee = priceDetails.coloradoDeliveryFee;
  const coloradoDeliveryFee = coloradoFee ? coloradoFee.amount : 0;
  const roundUpForCharity = priceDetails?.roundUpForCharityAmount;
  const charityAmount = roundUpForCharity ? roundUpForCharity.amount : 0;
  const marketplaceShipping = priceDetails?.marketplaceShippingPrice;
  const marketplaceShippingAmount = marketplaceShipping
    ? marketplaceShipping.amount
    : 0;
  const shippingDestinations = orderSummary?.shippingDestinations;
  const shippingInfo = shippingDestinations ? shippingDestinations[0] : {};
  const shippingAddress = shippingInfo ? shippingInfo.shippingAddress : {};
  const shippingAmount = priceDetails?.shippingPrice?.amount || 0;
  const shippingMethods = orderSummary?.shippingMethods;
  const selectedShippingMethod =
    shippingDestinations?.[0]?.shippingMethod?.name;
  const subtotal = priceDetails.subTotalAmount?.amount || 0;
  const giftCardPaidAmount = priceDetails.giftCardTotal?.amount || 0;
  const rewardsTotal = priceDetails.rewardsTotal?.amount || 0;
  const taxAmount = priceDetails.salesTaxes?.amount || 0;
  const totalAmount = priceDetails?.paymentDueAmount?.amount || 0;
  const isOrderBopisOnly = checkIsOrderBopisOnly(orderSummary);
  const discountTotal = orderTransactionalSavings + giftCardPaidAmount;
  const aurusBilling = formatExpAdresstoAurusAddress(
    billingAddress,
    contactInfo
  );
  const aurusShipping = formatExpAdresstoAurusAddress(
    shippingAddress,
    contactInfo
  );
  const aurusItems = formatLineItemsToAurusItems({
    lineItems,
    taxAmount,
    shippingAmount,
    discountTotal: orderPromotionTotal + rewardsTotal,
    coloradoDeliveryFee,
    charityAmount,
    marketplaceShippingAmount,
    giftCardPaidAmount,
  });
  const itemsTotal = () => {
    let total = 0;
    lineItems?.forEach((item) => {
      const quantity = item?.quantity;
      const price = item?.product?.sku?.currentPrice
        ? Number(item?.product?.sku?.currentPrice.substring(1))
        : 0;
      total += price * quantity;
    });
    return (total + charityAmount).toFixed(2);
  };
  const isEgiftCardOnly = checkIsEGiftCardOnly(orderSummary);
  const pickupAddress = getStorePickupBlock(orderSummary, isOrderBopisOnly);
  const handLingFees =
    processingFee + coloradoDeliveryFee + marketplaceShippingAmount;
  const env = getAurusEnv();
  const aurusClientId = getAurusClientId();
  const aurusShippingMethods = getShippingOptionsForAurus({
    shippingMethods,
    selectedShippingMethod,
    isOrderBopisOnly,
  });

  const consumerObj = {
    sessionId,
    apmMatrix,
    env,
    aurusClientId,
    customer: {
      skipShippingAddress:
        skipShipping || isEgiftCardOnly || isOrderBopisOnly ? '1' : '0',
      skipBillingAddress: skipBilling || isEgiftCardOnly ? '1' : '0',
      isExpressCheckout: isExpressCheckout ? '1' : '0',
      skipRequiredShippingContact: hideShipping ? '1' : '0',
      billing_address: aurusBilling,
      shipping_address: aurusShipping,
      displayName: 'Express',
    },
    store_pickup: pickupAddress,
    order: {
      intent,
      purchaseCountry: countryCode,
      purchaseCurrency: currencyCode,
      locale,
      softDescriptor,
      invoiceNumber: orderNumber,
      items: aurusItems,
      tax_amount: {
        amount: stringify(taxAmount),
        currency_code: currencyCode,
      },
      order_amount: {
        amount: stringify(totalAmount),
        currency: currencyCode,
      },
      item_total: {
        amount: itemsTotal(),
        currency: currencyCode,
      },
      shipping_amount: {
        amount: stringify(shippingAmount),
        currency: currencyCode,
      },
      Subtotal: {
        amount: stringify(subtotal),
        currency: currencyCode,
      },
      Applied_promo_discount: {
        amount: stringify(orderPromotionTotal),
        currency: currencyCode,
      },
      total_payment_applied: {
        amount: stringify(appliedPayments),
        currency: currencyCode,
      },
      international_processing_fee: {
        amount: stringify(processingFee),
        currency: currencyCode,
      },
      gift_wrap: {
        amount: stringify(giftWrap),
        currency: currencyCode,
      },
      handling: {
        amount: stringify(handLingFees),
        currency: currencyCode,
      },
      discounts: {
        amount: stringify(discountTotal),
        currency: currencyCode,
      },
      // Need to add for PayPal. Discuss with Aurus
      coupon: {
        amount: '0.00',
        currency: currencyCode,
      },
      insurance: {
        amount: '0.00',
        currency: currencyCode,
      },
      shipping_discount: {
        value: '0.00',
        currency: currencyCode,
      },
      shipping: {
        value: '0.00',
        currency: 'USD',
      },
    },
    payment_method: 'pay_over_time',
    shippingMethods: aurusShippingMethods,
    BillingContactError: [
      {
        errorField: 'name',
        errorMessage: 'Please enter valid billing address.',
      },
      {
        errorField: 'phoneticName',
        errorMessage: 'Please enter valid billing address.',
      },
      {
        errorField: 'postalAddress',
        errorMessage: 'Please enter valid billing address.',
      },
      {
        errorField: 'addressLines',
        errorMessage: 'Please enter valid billing address.',
      },
      {
        errorField: 'locality',
        errorMessage: 'Please enter valid billing address.',
      },
      {
        errorField: 'subLocality',
        errorMessage: 'Please enter valid billing address.',
      },
      {
        errorField: 'postalCode',
        errorMessage: 'Please enter valid billing address.',
      },
      {
        errorField: 'administrativeArea',
        errorMessage: 'Please enter valid billing address.',
      },
      {
        errorField: 'subAdministrativeArea',
        errorMessage: 'Please enter valid billing address.',
      },
      {
        errorField: 'country',
        errorMessage: 'Please enter valid billing address.',
      },
      {
        errorField: 'countryCode',
        errorMessage: 'Please enter valid billing address.',
      },
    ],
    ContactError: [
      {
        errorField: 'emailAddress',
        errorMessage: 'Please enter valid contact details.',
      },
      {
        errorField: 'phoneNumber',
        errorMessage: 'Please enter valid contact details.',
      },
    ],
    ShippingContactError: [
      {
        errorField: 'postalCode',
        errorMessage: 'Please enter valid shipping address.',
      },
      {
        errorField: 'name',
        errorMessage: 'Please enter valid shipping address.',
      },
      {
        errorField: 'addressLines',
        errorMessage: 'Please enter valid shipping address.',
      },
      {
        errorField: 'locality',
        errorMessage: 'Please enter valid shipping address.',
      },
      {
        errorField: 'country',
        errorMessage: 'Please enter valid shipping address.',
      },
      {
        errorField: 'countryCode',
        errorMessage: 'Please enter valid shipping address.',
      },
      {
        errorField: 'administrativeArea',
        errorMessage: 'Please enter valid shipping address.',
      },
    ],

    shippingAllowed: isShippingAllowed ? '1' : '0',
    ContactAllowed: isContactAllowed ? '1' : '0',
    billingAllowed: isBillingAllowed ? '1' : '0',
  };
  return consumerObj;
}

export default getAurusConsumerObj;
