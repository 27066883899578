import React from 'react';
import PropType from 'prop-types';

export class Geolocation extends React.Component {
  static propTypes = {
    userReducerLatitude: PropType.oneOfType([PropType.number, PropType.string]),
    userReducerLongitude: PropType.oneOfType([
      PropType.number,
      PropType.string,
    ]),
    component: PropType.node.isRequired,
  };

  static defaultProps = {
    userReducerLongitude: null,
    userReducerLatitude: null,
  };

  constructor() {
    super();
    const isGeolocationSupported = 'geolocation' in navigator;

    this.state = {
      busy: true,
      isGeolocationSupported,
      latitude: null,
      longitude: null,
      source: null,
      success: false,
    };
  }

  // TODO: refactor to not use UNSAFE_componentWillMount
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const askBrowserGeolocationApi = () =>
      new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          // success
          (position) => {
            resolve(position);
          },

          // error
          () => {
            reject();
          },

          // options
          {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 5000,
          }
        );
      });

    const askUserReducer = () =>
      new Promise((resolve, reject) => {
        const {
          userReducerLatitude: latitude,
          userReducerLongitude: longitude,
        } = this.props;

        if (typeof latitude === 'number' && typeof longitude === 'number') {
          resolve({ latitude, longitude });
        } else {
          reject();
        }
      });

    const askUserReducerSuceeded = ({ latitude, longitude }) => {
      this.setState({
        busy: false,
        latitude,
        longitude,
        source: 'userReducer',
        success: true,
      });
    };

    const askUserReducerFailed = () => {
      this.setState({
        busy: false,
        success: false,
      });
    };

    if (this.state.isGeolocationSupported) {
      askBrowserGeolocationApi()
        .then((position) => {
          this.setState({
            busy: false,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            source: 'browser',
            success: true,
          });
        })
        .catch(() => {
          askUserReducer()
            .then(askUserReducerSuceeded)
            .catch(askUserReducerFailed);
        });
    } else {
      askUserReducer().then(askUserReducerSuceeded).catch(askUserReducerFailed);
    }
  }

  render() {
    const { component: Component, ...filteredProps } = this.props;
    return <Component {...filteredProps} geolocation={{ ...this.state }} />;
  }
}

export default (WrappedComponent) =>
  function WithGeolocation(props) {
    return <Geolocation {...props} component={WrappedComponent} />;
  };
