import gql from 'graphql-tag';

export const schema = `
getTenderTypePromoBanners {
  bag {
    enable
    fontColor
    linkText
    linkURL
    promomessage {
      text
      html
      json {
        nodeType
        content {
          nodeType
          value
          format {
            variants
          }
        }
      }
    }
  }
  checkout {
    enable
    fontColor
    linkText
    linkURL
    promomessage {
      text
      html
      json {
        nodeType
        content {
          nodeType
          value
          format {
            variants
          }
        }
      }
    }
  }
  creditCardInput{
    enable
      fontColor
      linkText
      linkURL
      promomessage {
        text
        html
        json {
          nodeType
          content {
            value
          }
        }
      }
  }
  paymentMethodSuccess {
    enable
    fontColor
    linkText
    linkURL
    promomessage {
      text
      html
      json {
        nodeType
        content {
          value
        }
      }
    }
  }
  savedPaymentSuccessMsg {
    enable
    fontColor
    linkText
    linkURL
    promomessage {
      text
      html
      json {
        nodeType
        content {
          value
        }
      }
    }
  }
}
`;

const query = gql`
  query getTenderTypePromoBanners {
    ${schema}
  }
`;

export default query;
