import { createNewState } from '../ravenReduxHelpers';

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.
const CLOSE_SIDEBAR = 'category/CLOSE_SIDEBAR';
const OPEN_SIDEBAR = 'category/OPEN_SIDEBAR';
const SET_SIDEBAR_MODE = 'category/SET_SIDEBAR_MODE';
const TOGGLE_SIDEBAR = 'category/TOGGLE_SIDEBAR';
const SET_ACTIVE_PRODUCT = 'category/SET_ACTIVE_PRODUCT';

// Initial State
// -------------
const initialState = {
  isSidebarOpen: false,
  mode: 'filter',
  activeProduct: null,
};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  const { type, mode, productData } = action;
  switch (type) {
    case OPEN_SIDEBAR:
      return createNewState(state, { isSidebarOpen: true, mode });
    case CLOSE_SIDEBAR:
      return createNewState(state, { isSidebarOpen: false });
    case TOGGLE_SIDEBAR:
      return createNewState(state, { isSidebarOpen: !state.isSidebarOpen });
    case SET_SIDEBAR_MODE:
      return createNewState(state, { mode });
    case SET_ACTIVE_PRODUCT:
      return createNewState(state, { activeProduct: productData });
    default:
      return state;
  }
}

// Action Creators
// ---------------
export const closeSidebarAction = () => ({ type: CLOSE_SIDEBAR });
export const openSidebarAction = (mode) => ({ type: OPEN_SIDEBAR, mode });
export const setSidebarModeAction = (mode) => ({
  type: SET_SIDEBAR_MODE,
  mode,
});
export const toggleSidebarAction = (mode) => ({ type: TOGGLE_SIDEBAR, mode });
export const setActiveProduct = (productData) => ({
  type: SET_ACTIVE_PRODUCT,
  productData,
});
