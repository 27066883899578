import gql from 'graphql-tag';

export const schema = `
  sellerId
  sellerName
  firstName
  lastName
  sellerImage
  sellerUrl
  instagramProfile
  `;

const query = gql`
query getSellerList(
  $searchTerm: String!
) {
  getSellerList(
    searchTerm: $searchTerm
  ) {
   ${schema}
  }
}
`;

export default query;
