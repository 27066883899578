// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.

export const ACTIONS = {
  OPEN_MODAL: 'MARKETPLACE_TAG/OPEN_MODAL',
  CLOSE_MODAL: 'MARKETPLACE_TAG/CLOSE_MODAL',
};

// Action creator
export const openMarketPlaceModal = () => ({
  type: ACTIONS.OPEN_MODAL,
});

export const closeMarketPlaceModal = () => ({
  type: ACTIONS.CLOSE_MODAL,
});

// Initial state

const initialState = {
  modalOpen: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.OPEN_MODAL:
      return { ...state, modalOpen: true };
    case ACTIONS.CLOSE_MODAL:
      return { ...state, modalOpen: false };
    default:
      return state;
  }
};
