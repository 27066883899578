import gql from 'graphql-tag';

export default gql`
  query getDeliveryMethods {
    shippingMethods {
      shippingMethods {
        category
        cost
        description
        estimatedDelivery
        estimatedDeliveryMessage
        estimatedBusinessDeliveryMessage
        id
        isDefault
        miraklSellerShipment
        name
        value
      }
      shippingDestinations {
        shippingAddress {
          addressId
          firstName
          lastName
          title
          line1
          line2
          line3
          city
          state
          country
          postalCode
          preferredAddress
        }
        shippingMethod {
          name
          estimatedDelivery
          estimatedDeliveryMessage
        }
      }
      bopisEligibleStatus
      skuInfo {
        skuId
        name
        bopisEligible
        giftWrapSelected
      }
    }
  }
`;
