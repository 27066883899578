import { createNewState } from '../ravenReduxHelpers';

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.
const SET_GIGYA = 'login/SET_GIGYA';
const SET_LOGIN_ERROR = 'login/SET_LOGIN_ERROR';

// Initial State
// -------------
const initialState = {
  Gigya: null,
  loginError: null,
};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  const { type, ...filteredAction } = action;

  switch (action.type) {
    case SET_GIGYA:
      return createNewState(state, { ...filteredAction });
    case SET_LOGIN_ERROR:
      return createNewState(state, { ...filteredAction });
    default:
      return state;
  }
}

// Action Creators
// ---------------
export const setGigya = (providerData) => ({
  type: SET_GIGYA,
  Gigya: providerData,
});
export const setLoginError = (error) => ({
  type: SET_LOGIN_ERROR,
  loginError: error,
});
