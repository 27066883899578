/**
 * @todo Find a way to split the review query out
 * and then we need to build a way to "combine"
 * queries from the separate queries folder;
 */
import gql from 'graphql-tag';

export const schema = `
content (name: $name) {
  contentId
  name
  data
}
`;

const query = gql`
  query ContentQuery ($name: String!) {
    ${schema}
  }
`;

export default query;
