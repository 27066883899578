import gql from 'graphql-tag';

const ctaConfiguration = `
  language
  channel
  display
  value
`;

const schema = `
  id
  memberId
  awardedCount
  latestAwardedDate
  nextAwardProgress
  title
  shortDescription
  longDescription
  incentive
  startDate
  endDate
  hasNoEndDate
  category
  actionType
  earnedImageUrl
  notEarnedImageUrl
  allowMultipleInstances
  actionCriteria
  displaySequence
  paramCount
  ctaConfigurations {
   ${ctaConfiguration}
  }
`;

export default gql`
  query getCustomerBadges($memberId: String) {
    getCustomerBadges(memberId: $memberId) {
      availableToEarn {
        ${schema}
      }
      earned {
        ${schema}
      }
      inProgress {
        ${schema}
      }
    }
  }
`;
