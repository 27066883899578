if (!window.Promise.prototype.always) {
  window.Promise.prototype.always = window.alwaysPolyfill;
}

import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Brander } from '@express-labs/raven-ui';
// TODO: remove this once we fully migrate to the hosted bazaarvoice
import { writeReview } from '@express-labs/express-bazaarvoice';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import throttle from './utils/throttle';

// Import CSS from Raven UI libraries
// Note: this is GLOBAL css, not a css module.
import '@express-labs/raven-ui-atomic/dist/es/index.css';
import '@express-labs/raven-ui/dist/es/index.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

// Application state modules and helpers
import client from './appState/ravenApolloClient';
import ravenConfigureStore from './appState/ravenConfigureStore';
import rootReducer from './appState/ravenRootReducer';
import { initCampaigns, updateCampaigns } from './appState/ducks/campaign';
import {
  updateDimensions,
  updateScrollTop,
} from './appState/ducks/deviceWidth';
import {
  updateHeartbeatData,
  resetHeartbeatData,
} from './appState/ducks/customer';

// Tweaks that only are applied in development mode. The following if block is completely
// excluded from the production bundle via tree shaking.
if (PRODUCTION === false) {
  // Silence TrackJS in development environment
  window.trackJs = {
    console: {
      info: () => {},
      error: () => {},
      warn: () => {},
    },
    addMetadata: () => {},
  };

  // Add dev-only javascript to close the annoying newsletter footer signup.
  // In production, AEM provides the code to close this dialog. We must mimic the behavior locally.
  document.addEventListener('DOMContentLoaded', () => {
    const handleTemplateClick = () => {
      const div = document.getElementsByClassName(
        'footernewslettersignupcomponent'
      )?.[0];
      if (div) {
        div.remove();
      }
    };
    const button = document.getElementsByClassName('drawer-close')?.[0];
    if (button) {
      button.addEventListener('click', handleTemplateClick, false);
    }
  });
}

// load all the routes and route related listeners and mutators for our app
// if it does routes... you'll find it here.
import Router from './routes';

// create a redux store to store application state
const store = ravenConfigureStore(rootReducer);

// Track window dimensions and add them to our redux store
const trackWindowDimensions = throttle(
  () => window.requestAnimationFrame(() => store.dispatch(updateDimensions())),
  400
);
window.addEventListener('resize', throttle(trackWindowDimensions));

// Track scrollTop in redux store
const trackScrollTop = throttle(
  () => window.requestAnimationFrame(() => store.dispatch(updateScrollTop())),
  400
);
window.addEventListener('scroll', throttle(trackScrollTop));

// TODO: remove this once we fully migrate to the hosted bazaarvoice
window.writeReview = writeReview;

const render = () => {
  function detectIE11() {
    if (
      navigator.userAgent.match(/Trident.*rv:11\./) &&
      !document.body.classList.contains('ie11')
    ) {
      document.body.classList.add('ie11');
    }
  }

  detectIE11();

  ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Brander>
          <Router />
        </Brander>
      </Provider>
    </ApolloProvider>,
    document.getElementById('app')
  );
};

if (typeof window.Heartbeat !== 'undefined') {
  window.Heartbeat.on('beat', 'ravenBeatListener', (storage) => {
    store.dispatch(updateHeartbeatData(storage));
  });
  window.Heartbeat.on('die', 'ravenDieListener', () => {
    store.dispatch(resetHeartbeatData());
  });
  window.Heartbeat.beating().then(render, render);
} else {
  render();
}

if (typeof window.Eva !== 'undefined') {
  window.Eva.subscribe('audience', (data) => {
    store.dispatch(updateCampaigns(data));
  });

  store.dispatch(
    initCampaigns({
      campaigns: { ...window.Eva.campaigns() },
      loading: false,
    })
  );
}

import './hot';
