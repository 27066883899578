import gql from 'graphql-tag';

const query = gql`
  query goToCheckout($googleReCaptchaToken: String, $action: String) {
    goToCheckout(googleReCaptchaToken: $googleReCaptchaToken, action: $action) {
      success
    }
  }
`;

export default query;
