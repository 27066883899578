import React from 'react';
import PropType from 'prop-types';
import { component } from '../custom-prop-types';

export class WithComponentLoading extends React.Component {
  static propTypes = {
    component,
    withComponentLoadingProps: PropType.shape({
      isLoading: PropType.bool.isRequired,
      isError: PropType.bool.isRequired,
      renderLoading: PropType.node,
      renderError: PropType.node,
    }).isRequired,
  };

  static defaultProps = {
    component: null,
  };

  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { isLoading, isError, renderLoading, renderError } =
      this.props.withComponentLoadingProps;

    const {
      component: WrappedComponent,
      withComponentLoadingProps,
      ...filteredProps
    } = this.props;

    if (isError && typeof renderError !== 'undefined') {
      return renderError;
    }

    if (isLoading && typeof renderLoading !== 'undefined') {
      return renderLoading;
    }

    return (
      <WrappedComponent
        {...filteredProps}
        withComponentLoading={{
          isLoading,
          isError,
        }}
      />
    );
  }
}

function withComponentLoading(WrappedComponent, options) {
  const hoc = (props) => {
    const loadingProps = {};

    // evaluate ownProps for isLoading, isError
    ['isLoading', 'isError'].forEach((key) => {
      const type = typeof options[key];
      if (type === 'undefined') {
        throw new Error(
          `withComponentLoading() is missing the required option '${key}' which must be a function that returns true or false.`
        );
      }
      if (type !== 'function') {
        throw new Error(
          `The '${key}' option for withComponentLoading() must be a function that returns true or false. The function is automatically supplied the current props.`
        );
      }
      loadingProps[key] = options[key](props);
    });

    ['renderLoading', 'renderError'].forEach((key) => {
      const type = typeof options[key];

      if (type !== 'undefined') {
        if (type === 'function') {
          loadingProps[key] = options[key](props);
        } else {
          throw new Error(
            `The options ${key} option for withComponentLoading() must be a function that returns PropType.node. The function is automatically supplied the current props.`
          );
        }
      }
    });

    return (
      <WithComponentLoading
        {...props}
        component={WrappedComponent}
        withComponentLoadingProps={loadingProps}
      />
    );
  };

  hoc.displayName = 'withComponentLoading';

  return hoc;
}

function withComponentLoadingFactory() {
  return (options) => (WrappedComponent) =>
    withComponentLoading(WrappedComponent, options);
}

export default withComponentLoadingFactory();
