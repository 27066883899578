import gql from 'graphql-tag';

export default gql`
  query getAddresses {
    getAddresses {
      billingAddresses {
        id
        firstName
        lastName
        addressLineOne
        addressLineTwo
        city
        state
        zipCode
        country
        phone
        refId
        default
      }
      shippingAddresses {
        id
        firstName
        lastName
        addressLineOne
        addressLineTwo
        city
        state
        zipCode
        country
        phone
        refId
        default
      }
    }
  }
`;
