import deepFreeze from 'deep-freeze';

/**
 * makeNewState
 * Object.assign() only does a shallow merge, and we need to merge two levels deep.  Deepmerge seems
 * like it is too much overhead for such a simple task.  Rolling our own.
 */
export const mediumMerge = (oldState, productId, productIdState) => {
  const newState = { ...oldState };
  if (!newState[productId]) {
    newState[productId] = productIdState;
  } else {
    newState[productId] = { ...newState[productId], ...productIdState };
  }
  return deepFreeze(newState);
};

// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.
const SET_REQUEST_STARTED = 'addToCart/SET_REQUEST_STARTED';
const SET_FINISHED_WITH_ERROR = 'addToCart/SET_FINISHED_WITH_ERROR';
const SET_FINISHED_SOLD_OUT = 'addToCart/SET_FINISHED_SOLD_OUT';
const SET_FINISHED_SUCCESS = 'addToCart/SET_FINISHED_SUCCESS';
const SET_SHOW_CONFIRMATION = 'addToCart/SET_SHOW_CONFIRMATION';
const SET_HIDE_CONFIRMATION = 'addToCart/SET_HIDE_CONFIRMATION';
const SET_DELETE = 'addToCart/SET_DELETE';

// Initial State
// -------------
const initialState = {};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_REQUEST_STARTED:
      return mediumMerge(state, action.productId, {
        errorMessage: null,
        isBusy: true,
        isError: null,
        isSoldOut: null,
        isSuccess: null,
        method: action.method,
        skuId: action.skuId,
      });
    case SET_FINISHED_WITH_ERROR:
      return mediumMerge(state, action.productId, {
        errorMessage: action.errorMessage,
        isBusy: false,
        isError: true,
        isSuccess: false,
      });
    case SET_FINISHED_SOLD_OUT:
      return mediumMerge(state, action.productId, {
        isBusy: false,
        isSoldOut: true,
      });
    case SET_FINISHED_SUCCESS:
      return mediumMerge(state, action.productId, {
        isBusy: false,
        isError: false,
        isSuccess: true,
        errorMessage: null,
      });
    case SET_SHOW_CONFIRMATION:
      return mediumMerge(state, action.productId, {
        showConfirmation: true,
      });
    case SET_HIDE_CONFIRMATION:
      return mediumMerge(state, action.productId, {
        showConfirmation: false,
      });
    case SET_DELETE: {
      const newState = { ...state };
      delete newState[action.productId];
      return deepFreeze(newState);
    }
    default:
      return state;
  }
}

// Action Creators
// ---------------
export const setRequestStarted = (productId, skuId, method) => ({
  method,
  productId,
  skuId,
  type: SET_REQUEST_STARTED,
});

export const setError = (productId, errorMessage) => ({
  errorMessage,
  productId,
  type: SET_FINISHED_WITH_ERROR,
});

export const setSoldOut = (productId) => ({
  productId,
  type: SET_FINISHED_SOLD_OUT,
});

export const setSuccess = (productId) => ({
  productId,
  type: SET_FINISHED_SUCCESS,
});

export const setShowBagUpdateConfirmation = (productId) => ({
  productId,
  type: SET_SHOW_CONFIRMATION,
});

export const setHideBagUpdateConfirmation = (productId) => ({
  productId,
  type: SET_HIDE_CONFIRMATION,
});

export const setDelete = (productId) => ({
  productId,
  type: SET_DELETE,
});
