import React from 'react';
import {
  getPath,
  parseUrlProductId,
  typeSafeDecode,
} from '@express-labs/raven-tools';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { component } from '../custom-prop-types';

export class WithRouterPDP extends React.Component {
  static parseFacets(facet, pathname) {
    // for a detailed explanation of this regex, visit the link below.
    // https://regex101.com/r/4S9pjM/2
    const regex = new RegExp(`(/${facet}/([^/]+)(/|$))`, 'g');
    return typeSafeDecode(getPath(regex.exec(pathname), '2'));
  }

  static parseUrl(pathname) {
    const productId = parseUrlProductId(pathname);

    // https://regex101.com/r/yoltc8/1
    const categoryId = typeSafeDecode(
      getPath(/\/(cat([^/]+)(\/|$))/.exec(pathname), '2')
    );

    // the rest are very similar
    const color = WithRouterPDP.parseFacets('color', pathname);
    const ext = WithRouterPDP.parseFacets('e', pathname);
    const inseam = WithRouterPDP.parseFacets('i', pathname);
    const model = WithRouterPDP.parseFacets('m', pathname);
    const size = WithRouterPDP.parseFacets('size', pathname);

    // get the url "root" from withRouter. aka the stuff before "pro" in a pdp url.
    // Example:
    // https://www.express.com/clothing/men/exp-weekend-vintage-fleece-popover-camo-hoodie/pro/05327218/color/GREEN/e/regular/
    // the "beforePro" value is "clothing/men/exp-weekend-vintage-fleece-popover-camo-hoodie/"
    // no starting slash.  yes trailing slash.
    const beforePro = `${pathname.split('/pro/')[0]}/`.substring(1);

    return {
      beforePro,
      categoryId,
      color,
      ext,
      inseam,
      model,
      productId,
      size,
    };
  }

  static propTypes = {
    // The withPDPURL HOC must wrap a component
    component,

    // passed in by redux-router withRouter() HOC
    history: PropTypes.shape({
      listen: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    component: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      params: WithRouterPDP.parseUrl(window.location.pathname),
    };
    this.unlisten = null;
    this.pathname = window.location.pathname;

    this.handleOnListen = this.handleOnListen.bind(this);
  }

  static getParams() {
    const params = WithRouterPDP.parseUrl(window.location.pathname);
    return params;
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.handleOnListen);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleOnListen(location) {
    if (unescape(this.pathname) !== unescape(location.pathname)) {
      const newParams = WithRouterPDP.parseUrl(location.pathname);
      this.pathname = location.pathname;
      this.setState({
        params: newParams,
      });
    }
  }

  render() {
    const { component: WrappedComponent, ...filteredProps } = this.props;

    const { params } = this.state;

    return (
      <WrappedComponent
        {...filteredProps}
        withRouterPDP={{ params, getParams: WithRouterPDP.getParams }}
      />
    );
  }
}

export const WithRouterPDPVM = withRouter(WithRouterPDP);

export default (Component) =>
  function WithRouterPDP(props) {
    return <WithRouterPDPVM {...props} component={Component} />;
  };
