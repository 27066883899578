/**
 * @function getStoreOpen
 * @desc checks if a store is open given its operation hours.
 *
 * @param {String} hoursOfOperation - opening and closing time. Example "10:00AM-8:00PM"
 *
 * @returns {Boolean} true if the store is open at the current time, false otherwise.
 */

const getStoreOpen = (hoursOfOperation) => {
  if (!hoursOfOperation) return false;

  const splitHours = hoursOfOperation
    .split('-')
    .map((hour) => hour.match(/(\d+)/)?.[0]);

  const openingTime = Number(splitHours[0]);
  const closingTime = Number(splitHours[1]) + 12;

  const currentDate = new Date();
  const currentTime = currentDate.getHours();

  return openingTime <= currentTime && currentTime <= closingTime;
};

export default getStoreOpen;
