/* eslint-disable no-console */
// This is used for debugging while dev'ing. Do not keep this HOC in production code.

import React from 'react';
import PropTypes from 'prop-types';
import diff from 'shallow-diff';
import circular from 'circular';
import { isValidElementType, isMemo } from 'react-is';

export const initDiffLog = (label) => {
  if (!window.diffLog) window.diffLog = {};
  if (!window.diffLog[label]) window.diffLog[label] = [];
};

export const clonePropstream = (WrappedComponent, filteredProps) => {
  let clone;
  if (isValidElementType(WrappedComponent)) {
    clone = JSON.parse(JSON.stringify(filteredProps, circular()));
  } else {
    clone = {
      ERROR: `debugPropStream was expecting a valid React Element type. Instead, type was ${typeof WrappedComponent}`,
    };
  }
  return clone;
};

export const logDiffReport = (log, copy) => {
  log.push(copy);
  const isThereEnoughLogToDiff = log.length > 1;
  if (isThereEnoughLogToDiff) {
    log.splice(0, log.length - 2);
    const leftHandSide = log[log.length - 2];
    const rightHandSide = log[log.length - 1];
    console.log('previous props:', leftHandSide);
    console.log('current props:', rightHandSide);
    console.log('shallow diff:', diff(leftHandSide, rightHandSide));
  } else {
    console.log(copy);
  }
};

export const DebugPropStream = ({
  component: WrappedComponent,
  force = false,
  isDiff = false,
  label = 'debugPropStream',
  ...filteredProps
}) => {
  if (window.isDebugMode || force) {
    if (isDiff) initDiffLog(label);

    const copy = clonePropstream(WrappedComponent, filteredProps);

    console.group(
      `${label}${
        isMemo(<WrappedComponent {...filteredProps} />) ? ' - MEMOIZED' : ''
      }`
    );
    if (isDiff) {
      const log = window.diffLog[label];
      logDiffReport(log, copy);
    } else {
      Object.keys(copy).forEach((key) => {
        console.log(key, copy[key]);
      });
    }
    console.groupEnd();
  }

  return <WrappedComponent {...filteredProps} />;
};

DebugPropStream.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})])
    .isRequired,
  force: PropTypes.bool,
  isDiff: PropTypes.bool,
  label: PropTypes.string,
};

function debugPropStream(WrappedComponent, label, isDiff, force) {
  const hoc = (props) => (
    <DebugPropStream
      {...props}
      component={WrappedComponent}
      label={label}
      isDiff={isDiff}
      force={force}
    />
  );

  hoc.displayName = 'debugPropStream';

  return hoc;
}

function debugPropStreamFactory() {
  return (label, isDiff, force) => (Component) =>
    debugPropStream(Component, label, isDiff, force);
}

export default debugPropStreamFactory();
