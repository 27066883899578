import gql from 'graphql-tag';

export default gql`
  query getSizeChart($gender: String!) {
    getSizeChart(gender: $gender) {
      displayTitle
      categories {
        title
        displayTitle
        icon {
          image {
            title
            url
          }
        }
        inches {
          title
          size_chart {
            title
            chart_display_title
            chart {
              rows
              columns
            }
          }
        }
        centimeters {
          title
          size_chart {
            title
            chart_display_title
            chart {
              rows
              columns
            }
          }
        }
        uid
        measuring_tips {
          title
          sections {
            title
            display_title
            image {
              title
              url
            }
            description_block {
              description_text {
                description
              }
              description_steps {
                steps {
                  title
                  description
                }
              }
            }
            order
          }
        }
        order
      }
    }
  }
`;
