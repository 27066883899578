import gql from 'graphql-tag';

export const schema = `
  id
  firstName
  lastName
  companyName
  addressLineOne
  addressLineTwo
  city
  state
  zipCode
  country
  phone
  creditCardNumber
  creditCardExpirationMonth
  creditCardExpirationYear
  creditCardType
  defaultCreditCard
  tokenizedCreditCardNumber
`;

export default gql`
  query getPaymentMethodsInProfile {
    getPaymentMethodsInProfile{
      ${schema}
    }
  }
`;
