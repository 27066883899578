import gql from 'graphql-tag';

export default gql`
  query getAuthorableCopy($contentId: String, $page: String) {
    getAuthorableCopy(contentId: $contentId, page: $page) {
      contentId
      cta {
        title
        href
      }
      display
      message
      messageType
      page
      title
      uid
      updatedAt
    }
  }
`;
