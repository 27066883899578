// DUCKS module (Redux Reducer Bundles)
// see: https://github.com/erikras/ducks-modular-redux

// Action types
// ------------
//
// Declare action types as constants.  The action types need to be unique strings. Since there will
// most likely be more than one ocassion to clear a form, the best practice is to namespace the
// constant values.  Programatically, the "/" means nothing. It's just a convention we're using to
// namespace the actions.

// Initial State
// -------------
const vendorConfigs = (() => {
  if (window && window.FLAGS) {
    if (typeof window.FLAGS === 'string') {
      try {
        return JSON.parse(window.FLAGS);
      } catch (e) {
        return {};
      }
    }
    return window.FLAGS;
  }

  return {};
})();

const initialState = {
  vendors: vendorConfigs,
};

// Reducer
// -------
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

// Action Creators
// ---------------
